import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import ReactDrawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

function Drawer(props) {
	const { open, width, placement, onClose, children, ...other } = props;

	return (
		<Portal node={document && document.getElementById('drawer_root')}>
			<ReactDrawer
				width={width}
				open={open}
				placement={placement}
				showMask={true}
				handler={false}
				onMaskClick={onClose}
				level={null}
				{...other}
			>
				{children}
			</ReactDrawer>
		</Portal>
	);
}

Drawer.propTypes = {
	open: PropTypes.bool,
	width: PropTypes.string,
	placement: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};

Drawer.defaultProps = {
	open: false,
	width: '60%',
	placement: 'right'
};

export default Drawer;
