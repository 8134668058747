import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ChevronDown, Error as ErrorIcon } from 'modules/blink/icons';
import Menu from '../Menu';
import Text from '../Text';
import Popover from '../Popover';
import StyledSelect from './StyledSelect.jsx';

function Select(props) {
	const {
		label,
		size,
		error,
		showError: forceShowError,
		help,
		tabIndex,
		required,
		readOnly,
		selected,
		options,
		onChange,
		validator,
		placeholder
	} = props;

	const [showError, toggleError] = useState(false);
	const [showPopover, togglePopover] = useState(false);

	useEffect(() => {
		if (selected && showError) {
			toggleError(false);
		}
	}, [selected, showError]);

	const handleBlur = () => {
		if (!validator || showPopover) return;

		toggleError(!validator(selected && selected.value));
	};

	const renderLabel = () => {
		if (readOnly) {
			return <span className="label">{label}</span>;
		} else {
			return (
				<span className="label">
					{label}
					<b>{required ? ' *' : ''}</b>
				</span>
			);
		}
	};

	const renderError = () => (
		<div className="input-error">
			<ErrorIcon />
			<span>{error}</span>
		</div>
	);

	const renderHelp = () => (
		<div className="input-help">
			<span>{help}</span>
		</div>
	);

	const renderPopover = () => {
		return (
			<Menu
				items={options}
				onSelectItem={item => {
					togglePopover(false);
					onChange(item);
				}}
				size={size}
				selected={selected && selected.value}
			/>
		);
	};

	const renderReadOnlyHandler = () => {
		return (
			<div className="select-handler">
				{label && renderLabel()}
				<div className="select-current-value" tabIndex={tabIndex}>
					<Text noWrap={true} title={selected ? selected.label : ''}>
						{selected ? selected.label : placeholder}
					</Text>
				</div>
				{help && renderHelp()}
			</div>
		);
	};

	const renderHandler = () => {
		return (
			<div className="select-handler">
				{label && renderLabel()}
				<div className="select-current-value" tabIndex={tabIndex}>
					<Text noWrap={true} title={selected ? selected.label : ''}>
						{selected ? selected.label : placeholder}
					</Text>
					<ChevronDown />
				</div>
				{(forceShowError || showError) && renderError()}
				{help && renderHelp()}
			</div>
		);
	};

	const renderContent = () => {
		if (readOnly) {
			return renderReadOnlyHandler();
		} else {
			return (
				<Popover
					placement="bottom-end"
					popoverClassName="select-menu"
					showPopover={showPopover}
					togglePopover={togglePopover}
					renderHandler={renderHandler}
					renderPopover={renderPopover}
				/>
			);
		}
	};

	return (
		<StyledSelect
			size={size}
			error={forceShowError || showError}
			readOnly={readOnly}
			isOpen={showPopover}
			onBlur={handleBlur}
		>
			{renderContent()}
		</StyledSelect>
	);
}

Select.propTypes = {
	label: PropTypes.string,
	size: PropTypes.string,
	error: PropTypes.string,
	help: PropTypes.string,
	tabIndex: PropTypes.string,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	selected: PropTypes.object,
	options: PropTypes.array,
	showError: PropTypes.bool,
	placeholder: PropTypes.string,
	validator: PropTypes.func,
	onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
	label: '',
	size: 'md',
	error: '',
	help: '',
	tabIndex: '0',
	required: false,
	readOnly: false,
	selected: null,
	showError: false,
	options: [],
	placeholder: '',
	validator: null
};

export default Select;
