// 8px scaling factor, includes 4px
function createSpacing(spacingInput = 8, unit = 'px') {
	const spacing = scale => {
		return `${scale * spacingInput}${unit}`;
	};

	return spacing;
}

export default createSpacing;
