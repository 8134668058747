import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledProgress = styled.div`
	display: flex;

	> progress[value] {
		flex-grow: 1;
		appearance: none;
		border: none;
		height: ${props => props.height};

		::-webkit-progress-bar {
			background-color: ${props => props.bgColor};
			border-radius: ${props => props.borderRadius};
		}

		::-webkit-progress-value {
			background-color: ${props => props.valueColor};
			border-radius: ${props => props.borderRadius};
		}

		::-moz-progress-bar {
			background-color: ${props => props.bgColor};
			border-radius: ${props => props.borderRadius};
		}
	}
`;

const Progress = ({
	valueColor,
	bgColor,
	height,
	borderRadius,
	max,
	value
}) => {
	return (
		<StyledProgress
			valueColor={valueColor}
			bgColor={bgColor}
			height={height}
			borderRadius={borderRadius}
		>
			<progress max={max} value={value} />
		</StyledProgress>
	);
};

Progress.propTypes = {
	valueColor: PropTypes.string,
	bgColor: PropTypes.string,
	height: PropTypes.string,
	borderRadius: PropTypes.string,
	max: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

Progress.defaultProps = {
	valueColor: '#1ABC9C',
	bgColor: '#fff',
	height: '6px',
	borderRadius: '9px'
};

export default Progress;
