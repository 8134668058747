import React from 'react';

import SimpleSvg from './SimpleSvg';

const Help = props => (
	<SimpleSvg
		d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12.5,19h-1c-0.276,0-0.5-0.224-0.5-0.5v-1 c0-0.276,0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5v1C13,18.776,12.776,19,12.5,19z M15.07,11.25l-0.9,0.92 C13.45,12.9,13,13.5,13,15h-2v-0.5c0-1.1,0.45-2.1,1.17-2.83l1.24-1.26c0.457-0.444,0.685-1.102,0.553-1.803 c-0.135-0.717-0.688-1.327-1.389-1.526c-1.106-0.314-2.137,0.319-2.466,1.272C9.98,8.724,9.668,9,9.275,9H8.984 C8.403,9,7.997,8.436,8.159,7.878c0.429-1.472,1.681-2.593,3.225-2.831c1.524-0.235,2.969,0.549,3.873,1.799 C16.437,8.478,16.089,10.231,15.07,11.25z"
		{...props}
	/>
);

export default Help;
