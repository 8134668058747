import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const NavHeader = ({ history }) => (
	<div onClick={() => history.push('/')} className="nav-header">
		<div className="logo-wrapper">
			<img
				alt="logo"
				className="logo-sm"
				src="img/structurely-new-logo-icon.png"
			/>
		</div>
	</div>
);

NavHeader.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(NavHeader);
