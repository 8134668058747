import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { showPopover } from 'modules/core/helpers';

import validator from 'validator';

import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { withRouter } from 'react-router-dom';
import { withUser, withFeatureFlags } from 'modules/core/providers';
import { Modal } from 'modules/blink/components';
import { useTextInput } from 'modules/common/utils';

import { MUTATE_START_NEW_CONVO } from '../mutations';

function nameValidator(value) {
	if (!value) {
		return 'Name is required';
	}

	return undefined;
}

function emailValidator(value) {
	if (!value) {
		return 'Email or Phone must be provided';
	}

	if (!validator.isEmail(value)) {
		return 'Invalid email address';
	}

	return undefined;
}

function phoneValidator(value) {
	if (!value) {
		return 'Email or Phone must be provided';
	}

	if (!validator.isMobilePhone(value)) {
		return 'Invalid phone number';
	}

	return undefined;
}

export const CreateLeadsModal = withFeatureFlags(
	withRouter(
		withUser(({ isOpen, onClose, user, allUseCases }) => {
			const [conversation, setConversation] = useState(1);

			const [
				name,
				setName,
				hasNameError,
				nameError,
				showNameError
			] = useTextInput(nameValidator);

			const [
				email,
				setEmail,
				hasEmailError,
				emailError,
				showEmailError
			] = useTextInput(emailValidator);

			const [
				phone,
				setPhone,
				hasPhoneError,
				phoneError,
				showPhoneError
			] = useTextInput(phoneValidator);

			const handleChangeConversation = useCallback(
				event => {
					setConversation(event.target.value);
				},
				[setConversation]
			);

			const [createConversation] = useMutation(MUTATE_START_NEW_CONVO);

			const tagOptions = allUseCases?.filter(t => {
				// Filter out any tags that end with "Specific" those are for new enrollment only
				return t.customScript || !t.label.endsWith('Specific');
			});

			const onCreateLead = useCallback(() => {
				let canCreateLead = true;
				if (hasNameError) {
					canCreateLead = false;
					showNameError();
				}

				if (!email && !phone) {
					canCreateLead = false;
					showEmailError();
					showPhoneError();
				} else {
					if (email && hasEmailError) {
						canCreateLead = false;
						showEmailError();
					}

					if (phone && hasPhoneError) {
						canCreateLead = false;
						showPhoneError();
					}
				}

				if (!canCreateLead) {
					return;
				}

				if (!user?.brokerageId && !user?.teamId && !user?.agentId) {
					return;
				}

				createConversation({
					variables: {
						metadata: {
							name,
							phone: phone || '',
							email: email || '',
							source: 'UI',
							type: 'Unknown',
							tags: conversation === 1 ? [] : [conversation],
							brokerageId: user.brokerageId,
							teamId: user.teamId,
							agentId: user.agentId
						}
					}
				})
					.then(result => {
						if (result?.data?.startNewConvo?.customerId) {
							window.location.replace(
								`/#/leads/${
									result.data.startNewConvo.customerId
								}`
							);
							setConversation(1);
							setName(null);
							setEmail(null);
							setPhone(null);
							onClose();
						}
					})
					.catch(err => {
						if (err?.graphQLErrors) {
							err.graphQLErrors.forEach(error => {
								showPopover(error.message, 'error');
							});
						} else {
							showPopover('Failed to validate account', 'error');
						}
					});
			}, [
				conversation,
				name,
				hasNameError,
				showNameError,
				setName,
				email,
				hasEmailError,
				showEmailError,
				setEmail,
				phone,
				hasPhoneError,
				showPhoneError,
				setPhone,
				user?.brokerageId,
				user?.teamId,
				user?.agentId,
				createConversation,
				onClose
			]);

			return (
				<Modal title="Create Lead" isOpen={isOpen} onClose={onClose}>
					<StyledModalContent>
						<div className="create-leads">
							<TextField
								required
								label="Name"
								value={name}
								onChange={setName}
								error={!!nameError}
								helperText={!!nameError ? nameError : undefined}
							/>
							<TextField
								label="Email"
								value={email}
								onChange={setEmail}
								error={!!emailError}
								helperText={
									!!emailError ? emailError : undefined
								}
							/>
							<TextField
								label="Phone"
								value={phone}
								onChange={setPhone}
								error={!!phoneError}
								helperText={
									!!phoneError ? phoneError : undefined
								}
							/>
							<FormControl>
								<InputLabel id="converation-label">
									Conversation
								</InputLabel>
								<Select
									labelId="converation-label"
									id="select-converation"
									value={conversation}
									onChange={handleChangeConversation}
								>
									<MenuItem value={1}>
										<em>Select a conversation</em>
									</MenuItem>
									{tagOptions?.map(t => (
										<MenuItem value={t.value} key={t.value}>
											{t.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{conversation === 1 && (
								<Alert variant="outlined" severity="info">
									When a lead is created without a
									conversation, they will be configured for
									direct communication. However the
									Structurely AI will not automatically engage
									them. You will need to manually start a
									conversation for this lead.
								</Alert>
							)}
							<Alert variant="outlined" severity="info">
								This lead is expecting to hear from my
								organization, and has opted-in to receive
								communication from my organization at least once
								in the past year. I can confirm that this lead
								wasn't purchased, rented, or provided by a third
								party.
							</Alert>
						</div>
						<div className="create-footer">
							<Button
								color="primary"
								variant="contained"
								onClick={onCreateLead}
							>
								Create Lead
							</Button>
							<Button
								color="default"
								variant="contained"
								onClick={onClose}
							>
								Cancel
							</Button>
						</div>
					</StyledModalContent>
				</Modal>
			);
		})
	)
);

CreateLeadsModal.propTypes = {
	type: PropTypes.string,
	filter: PropTypes.string,
	search: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

CreateLeadsModal.defaultProps = {
	type: '',
	filter: undefined,
	search: undefined
};

const StyledModalContent = styled.div`
	max-width: 650px;

	.create-leads {
		margin-top: 1rem;
		margin-left: 1rem;
		margin-right: 1rem;

		max-height: 600px;
		min-width: 600px;
		overflow-y: auto;

		display: flex;
		flex-direction: column;

		> div {
			margin-bottom: 1rem;
		}
	}

	.create-footer {
		margin-top: 0.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 1rem;

		display: flex;
		justify-content: right;

		button {
			margin-right: 0.5rem;
			text-transform: none;
			max-height: 36px;
		}
	}
`;
