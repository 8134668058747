import React from 'react';
import PropTypes from 'prop-types';

const Spinner = props => {
	let size = '';
	if (props.xs) {
		size = 'spinner-xs';
	} else if (props.sm) {
		size = 'spinner-sm';
	} else if (props.md) {
		size = 'spinner-md';
	} else if (props.lg) {
		size = 'spinner-lg';
	}

	return (
		<div className="loader">
			<div className={`loader-spinner ${size}`} style={props.style} />
		</div>
	);
};

Spinner.defaultProps = {
	style: {},
	xs: false,
	sm: false,
	md: false,
	lg: false
};

Spinner.propTypes = {
	style: PropTypes.object,
	xs: PropTypes.bool,
	sm: PropTypes.bool,
	md: PropTypes.bool,
	lg: PropTypes.bool
};

export default Spinner;
