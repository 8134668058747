const red = {
	50: '#ffebee',
	100: '#ffcdd2',
	200: '#ef9a9a',
	300: '#e57373',
	400: '#ef5350',
	500: '#f44336',
	600: '#e53935',
	700: '#d32f2f',
	800: '#c62828',
	900: '#b71c1c',
	l1: '#e53935',
	l2: '#f44336',
	l3: '#ffebee',
	default: '#d32f2f',
	d1: '#c62828',
	d2: '#b71c1c'
};

export default red;
