import React from 'react';

import Channel from './Channel.jsx';

const DirectChannel = props => {
	return (
		<Channel {...props}>
			<title>Live chat channel</title>
			<path
				fill="#1abcfe"
				d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"
			/>
			<path
				fill="#505252"
				d="M282,399.8c-7.8-22.8-12.3-28.8-27.2-28.8c-14.7,0-19.8,7.5-27.2,28.8c-25.1,2.4-42.7,8.1-42.7,14.8c0,8.9,31.3,16,69.9,16c38.6,0,69.9-7.2,69.9-16C324.7,408,307.1,402.3,282,399.8z"
			/>
			<path
				fill="#1A1919"
				d="M249.1,379.2c0-2.5,2-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6s-2.1,4.6-4.6,4.6C251.2,383.8,249.1,381.7,249.1,379.2z"
			/>
			<path
				fill="#3E4141"
				d="M434.4,220.4c0,2.4-1.9,4.3-4.3,4.3l0,0c-2.4,0-4.3-1.9-4.3-4.3v-45.5c0-2.4,1.9-4.3,4.3-4.3l0,0c2.4,0,4.3,1.9,4.3,4.3V220.4z"
			/>
			<defs>
				<path
					id="14JI0jKqCgr7WRoBZqfXPa"
					d="M431.7,358.3c0,11.7-9.5,21.1-21.1,21.1c0,0-87,12.3-160.3,12.3c-56.1,0-151.5-12.3-151.5-12.3c-11.7,0-21.1-9.5-21.1-21.1V149.5c0-11.7,9.5-21.1,21.1-21.1h311.9c11.7,0,21.1,9.5,21.1,21.1V358.3z"
				/>
			</defs>
			<clipPath id="14JI0jKqCgr7WRoBZqfXPb">
				<use overflow="visible" xlinkHref="#14JI0jKqCgr7WRoBZqfXPa" />
			</clipPath>
			<g clip-path="url(#14JI0jKqCgr7WRoBZqfXPb)">
				<path
					fill="#3E4141"
					d="M91.8,363.3V143.6h280l10.7-15.3H98.7c-11.7,0-21.1,9.5-21.1,21.1v208.8c0,11.7,9.5,21.1,21.1,21.1c0,0,51.6,6.6,101.3,10.1l18.3-26.2H91.8z"
				/>
				<path
					fill="#676768"
					d="M91.8 363.3L218.3 363.3 371.9 143.6 91.8 143.6z"
				/>
				<path
					fill="#3E4141"
					d="M410.6,379.4c11.7,0,21.1-9.5,21.1-21.1V149.5c0-11.7-9.5-21.1-21.1-21.1h-28l-10.7,15.3h44.8v219.7H218.3L200,389.6c17.8,1.3,35.4,2.1,50.3,2.1C323.6,391.7,410.6,379.4,410.6,379.4z"
				/>
				<g opacity=".3">
					<defs>
						<path
							id="14JI0jKqCgr7WRoBZqfXPc"
							d="M410.6,379.4c11.7,0,21.1-9.5,21.1-21.1V149.5c0-11.7-9.5-21.1-21.1-21.1h-28l-10.7,15.3h44.8v219.7H218.3L200,389.6c17.8,1.3,35.4,2.1,50.3,2.1C323.6,391.7,410.6,379.4,410.6,379.4z"
						/>
					</defs>
					<clipPath id="14JI0jKqCgr7WRoBZqfXPd">
						<use
							overflow="visible"
							xlinkHref="#14JI0jKqCgr7WRoBZqfXPc"
						/>
					</clipPath>
					<path
						fill="#949295"
						d="M382.5 128.4L200 389.5 200 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#949295"
						d="M386.5 128.4L202.5 391.7 200 391.7 200 389.5 382.5 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#959498"
						d="M390.5 128.4L206.4 391.7 202.5 391.7 386.5 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#969499"
						d="M394.4 128.4L210.4 391.7 206.4 391.7 390.5 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#97959A"
						d="M398.4 128.4L214.4 391.7 210.4 391.7 394.4 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#98969A"
						d="M402.4 128.4L218.3 391.7 214.4 391.7 398.4 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#99979C"
						d="M406.3 128.4L222.3 391.7 218.3 391.7 402.4 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9A989D"
						d="M410.3 128.4L226.3 391.7 222.3 391.7 406.3 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9B999E"
						d="M414.3 128.4L230.3 391.7 226.3 391.7 410.3 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9B9A9E"
						d="M418.3 128.4L234.2 391.7 230.3 391.7 414.3 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9D9B9F"
						d="M422.2 128.4L238.2 391.7 234.2 391.7 418.3 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9E9CA1"
						d="M426.2 128.4L242.2 391.7 238.2 391.7 422.2 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9F9CA2"
						d="M430.2 128.4L246.1 391.7 242.2 391.7 426.2 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#9F9DA3"
						d="M431.7 131.8L250.1 391.7 246.1 391.7 430.2 128.4 431.7 128.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A09EA4"
						d="M431.7 137.5L254.1 391.7 250.1 391.7 431.7 131.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A29FA4"
						d="M431.7 143.2L258 391.7 254.1 391.7 431.7 137.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A3A1A6"
						d="M431.7 148.9L262 391.7 258 391.7 431.7 143.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A4A2A8"
						d="M431.7 154.6L266 391.7 262 391.7 431.7 148.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A5A3A9"
						d="M431.7 160.2L270 391.7 266 391.7 431.7 154.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A6A4A9"
						d="M431.7 165.9L273.9 391.7 270 391.7 431.7 160.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A6A4AA"
						d="M431.7 171.6L277.9 391.7 273.9 391.7 431.7 165.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A8A5AB"
						d="M431.7 177.3L281.9 391.7 277.9 391.7 431.7 171.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#A9A6AC"
						d="M431.7 183L285.8 391.7 281.9 391.7 431.7 177.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#AAA7AD"
						d="M431.7 188.6L289.8 391.7 285.8 391.7 431.7 183z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#AAA8AE"
						d="M431.7 194.3L293.8 391.7 289.8 391.7 431.7 188.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#ABA9AF"
						d="M431.7 200L297.7 391.7 293.8 391.7 431.7 194.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#ACA9B0"
						d="M431.7 205.7L301.7 391.7 297.7 391.7 431.7 200z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#ADAAB1"
						d="M431.7 211.4L305.7 391.7 301.7 391.7 431.7 205.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#AEACB2"
						d="M431.7 217L309.7 391.7 305.7 391.7 431.7 211.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#AFACB3"
						d="M431.7 222.7L313.6 391.7 309.7 391.7 431.7 217z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B0ADB4"
						d="M431.7 228.4L317.6 391.7 313.6 391.7 431.7 222.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B2AFB6"
						d="M431.7 234.1L321.6 391.7 317.6 391.7 431.7 228.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B3B0B7"
						d="M431.7 239.8L325.5 391.7 321.6 391.7 431.7 234.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B3B1B8"
						d="M431.7 245.5L329.5 391.7 325.5 391.7 431.7 239.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B4B1B9"
						d="M431.7 251.1L333.5 391.7 329.5 391.7 431.7 245.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B5B2BA"
						d="M431.7 256.8L337.5 391.7 333.5 391.7 431.7 251.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B6B3BB"
						d="M431.7 262.5L341.4 391.7 337.5 391.7 431.7 256.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B7B4BC"
						d="M431.7 268.2L345.4 391.7 341.4 391.7 431.7 262.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B8B5BD"
						d="M431.7 273.9L349.4 391.7 345.4 391.7 431.7 268.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B8B6BE"
						d="M431.7 279.5L353.3 391.7 349.4 391.7 431.7 273.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#B9B7BF"
						d="M431.7 285.2L357.3 391.7 353.3 391.7 431.7 279.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BAB8C0"
						d="M431.7 290.9L361.3 391.7 357.3 391.7 431.7 285.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BBB8C1"
						d="M431.7 296.6L365.2 391.7 361.3 391.7 431.7 290.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BCB9C2"
						d="M431.7 302.3L369.2 391.7 365.2 391.7 431.7 296.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BDBAC3"
						d="M431.7 307.9L373.2 391.7 369.2 391.7 431.7 302.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BDBBC4"
						d="M431.7 313.6L377.2 391.7 373.2 391.7 431.7 307.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BEBCC5"
						d="M431.7 319.3L381.1 391.7 377.2 391.7 431.7 313.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#BFBDC6"
						d="M431.7 325L385.1 391.7 381.1 391.7 431.7 319.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C1BFC8"
						d="M431.7 330.7L389.1 391.7 385.1 391.7 431.7 325z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C2C0C9"
						d="M431.7 336.3L393 391.7 389.1 391.7 431.7 330.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C3C1C9"
						d="M431.7 342L397 391.7 393 391.7 431.7 336.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C4C2CA"
						d="M431.7 347.7L401 391.7 397 391.7 431.7 342z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C5C2CB"
						d="M431.7 353.4L404.9 391.7 401 391.7 431.7 347.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C5C3CC"
						d="M431.7 359.1L408.9 391.7 404.9 391.7 431.7 353.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C6C4CD"
						d="M431.7 364.8L412.9 391.7 408.9 391.7 431.7 359.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C7C5CE"
						d="M431.7 370.4L416.9 391.7 412.9 391.7 431.7 364.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C8C5CF"
						d="M431.7 376.1L420.8 391.7 416.9 391.7 431.7 370.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C9C6D0"
						d="M431.7 381.8L424.8 391.7 420.8 391.7 431.7 376.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#C9C7D1"
						d="M431.7 387.5L428.8 391.7 424.8 391.7 431.7 381.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
					<path
						fill="#CAC7D1"
						d="M428.8 391.7L431.7 387.5 431.7 391.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPd)"
					/>
				</g>
				<path
					fill="#676768"
					d="M416.7 363.3L416.7 143.6 371.9 143.6 218.3 363.3z"
				/>
				<g opacity=".3">
					<defs>
						<path
							id="14JI0jKqCgr7WRoBZqfXPe"
							d="M416.7 363.3L416.7 143.6 371.9 143.6 218.3 363.3z"
						/>
					</defs>
					<clipPath id="14JI0jKqCgr7WRoBZqfXPf">
						<use
							overflow="visible"
							xlinkHref="#14JI0jKqCgr7WRoBZqfXPe"
						/>
					</clipPath>
					<path
						fill="#949295"
						d="M371.9 143.6L218.3 363.3 218.3 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#949295"
						d="M375.8 143.6L222.3 363.3 218.3 363.3 218.3 363.3 371.9 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#959498"
						d="M379.8 143.6L226.3 363.3 222.3 363.3 375.8 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#969499"
						d="M383.8 143.6L230.2 363.3 226.3 363.3 379.8 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#97959A"
						d="M387.7 143.6L234.2 363.3 230.2 363.3 383.8 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#98969A"
						d="M391.7 143.6L238.2 363.3 234.2 363.3 387.7 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#99979C"
						d="M395.7 143.6L242.1 363.3 238.2 363.3 391.7 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9A989D"
						d="M399.6 143.6L246.1 363.3 242.1 363.3 395.7 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9B999E"
						d="M403.6 143.6L250.1 363.3 246.1 363.3 399.6 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9B9A9E"
						d="M407.6 143.6L254 363.3 250.1 363.3 403.6 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9D9B9F"
						d="M411.6 143.6L258 363.3 254 363.3 407.6 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9E9CA1"
						d="M415.5 143.6L262 363.3 258 363.3 411.6 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9F9CA2"
						d="M416.7 147.7L266 363.3 262 363.3 415.5 143.6 416.7 143.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#9F9DA3"
						d="M416.7 153.3L269.9 363.3 266 363.3 416.7 147.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A09EA4"
						d="M416.7 159L273.9 363.3 269.9 363.3 416.7 153.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A29FA4"
						d="M416.7 164.7L277.9 363.3 273.9 363.3 416.7 159z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A3A1A6"
						d="M416.7 170.4L281.8 363.3 277.9 363.3 416.7 164.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A4A2A8"
						d="M416.7 176.1L285.8 363.3 281.8 363.3 416.7 170.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A5A3A9"
						d="M416.7 181.7L289.8 363.3 285.8 363.3 416.7 176.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A6A4A9"
						d="M416.7 187.4L293.8 363.3 289.8 363.3 416.7 181.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A6A4AA"
						d="M416.7 193.1L297.7 363.3 293.8 363.3 416.7 187.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A8A5AB"
						d="M416.7 198.8L301.7 363.3 297.7 363.3 416.7 193.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#A9A6AC"
						d="M416.7 204.5L305.7 363.3 301.7 363.3 416.7 198.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#AAA7AD"
						d="M416.7 210.2L309.6 363.3 305.7 363.3 416.7 204.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#AAA8AE"
						d="M416.7 215.8L313.6 363.3 309.6 363.3 416.7 210.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#ABA9AF"
						d="M416.7 221.5L317.6 363.3 313.6 363.3 416.7 215.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#ACA9B0"
						d="M416.7 227.2L321.5 363.3 317.6 363.3 416.7 221.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#ADAAB1"
						d="M416.7 232.9L325.5 363.3 321.5 363.3 416.7 227.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#AEACB2"
						d="M416.7 238.6L329.5 363.3 325.5 363.3 416.7 232.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#AFACB3"
						d="M416.7 244.2L333.4 363.3 329.5 363.3 416.7 238.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B0ADB4"
						d="M416.7 249.9L337.4 363.3 333.4 363.3 416.7 244.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B2AFB6"
						d="M416.7 255.6L341.4 363.3 337.4 363.3 416.7 249.9z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B3B0B7"
						d="M416.7 261.3L345.4 363.3 341.4 363.3 416.7 255.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B3B1B8"
						d="M416.7 267L349.3 363.3 345.4 363.3 416.7 261.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B4B1B9"
						d="M416.7 272.6L353.3 363.3 349.3 363.3 416.7 267z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B5B2BA"
						d="M416.7 278.3L357.3 363.3 353.3 363.3 416.7 272.6z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B6B3BB"
						d="M416.7 284L361.2 363.3 357.3 363.3 416.7 278.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B7B4BC"
						d="M416.7 289.7L365.2 363.3 361.2 363.3 416.7 284z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B8B5BD"
						d="M416.7 295.4L369.2 363.3 365.2 363.3 416.7 289.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B8B6BE"
						d="M416.7 301L373.2 363.3 369.2 363.3 416.7 295.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#B9B7BF"
						d="M416.7 306.7L377.1 363.3 373.2 363.3 416.7 301z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BAB8C0"
						d="M416.7 312.4L381.1 363.3 377.1 363.3 416.7 306.7z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BBB8C1"
						d="M416.7 318.1L385.1 363.3 381.1 363.3 416.7 312.4z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BCB9C2"
						d="M416.7 323.8L389 363.3 385.1 363.3 416.7 318.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BDBAC3"
						d="M416.7 329.5L393 363.3 389 363.3 416.7 323.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BDBBC4"
						d="M416.7 335.1L397 363.3 393 363.3 416.7 329.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BEBCC5"
						d="M416.7 340.8L400.9 363.3 397 363.3 416.7 335.1z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#BFBDC6"
						d="M416.7 346.5L404.9 363.3 400.9 363.3 416.7 340.8z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#C1BFC8"
						d="M416.7 352.2L408.9 363.3 404.9 363.3 416.7 346.5z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#C2C0C9"
						d="M416.7 357.9L412.9 363.3 408.9 363.3 416.7 352.2z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
					<path
						fill="#C3C1C9"
						d="M412.9 363.3L416.7 357.9 416.7 363.3z"
						clip-path="url(#14JI0jKqCgr7WRoBZqfXPf)"
					/>
				</g>
			</g>
			<g>
				<path
					fill="#DFF1ED"
					d="M219,251.5c0,5.6-4.6,10.2-10.2,10.2h-77.2c-5.6,0-10.2-4.6-10.2-10.2v-44.9c0-5.6,4.6-10.2,10.2-10.2h77.2c5.6,0,10.2,4.6,10.2,10.2V251.5z"
				/>
				<path
					fill="#DFF1ED"
					d="M195.5,275.4c-0.8,0.8-2.1,0.8-2.9,0l-9.7-13.8c-0.8-0.8-0.8-2.1,0-2.9h22.4c0.8,0.8,0.8,2.1,0,2.9L195.5,275.4z"
				/>
				<g>
					<path
						fill="#B7CAC4"
						d="M204.2 213.7c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C202.5 210 204.2 211.7 204.2 213.7L204.2 213.7zM204.2 228.5c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C202.5 224.8 204.2 226.5 204.2 228.5L204.2 228.5zM204.2 243.3c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C202.5 239.6 204.2 241.2 204.2 243.3L204.2 243.3z"
					/>
				</g>
			</g>
			<g>
				<path
					fill="#DFF1ED"
					d="M391.1,232.2c0,5.6-4.6,10.2-10.2,10.2h-77.2c-5.6,0-10.2-4.6-10.2-10.2v-44.9c0-5.6,4.6-10.2,10.2-10.2h77.2c5.6,0,10.2,4.6,10.2,10.2V232.2z"
				/>
				<path
					fill="#DFF1ED"
					d="M367.5,256.1c-0.8,0.8-2.1,0.8-2.9,0l-9.7-13.8c-0.8-0.8-0.8-2.1,0-2.9h22.4c0.8,0.8,0.8,2.1,0,2.9L367.5,256.1z"
				/>
				<g>
					<path
						fill="#B7CAC4"
						d="M376.2 194.4c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C374.6 190.7 376.2 192.3 376.2 194.4L376.2 194.4zM376.2 209.2c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C374.6 205.5 376.2 207.1 376.2 209.2L376.2 209.2zM376.2 224c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C374.6 220.3 376.2 221.9 376.2 224L376.2 224z"
					/>
				</g>
			</g>
			<g>
				<path
					fill="#DFF1ED"
					d="M330.6,322.7c0,5.6-4.6,10.2-10.2,10.2h-77.2c-5.6,0-10.2-4.6-10.2-10.2v-44.9c0-5.6,4.6-10.2,10.2-10.2h77.2c5.6,0,10.2,4.6,10.2,10.2V322.7z"
				/>
				<path
					fill="#DFF1ED"
					d="M307.1,346.5c-0.8,0.8-2.1,0.8-2.9,0l-9.8-13.8c-0.8-0.8-0.8-2.1,0-2.9h22.4c0.8,0.8,0.8,2.1,0,2.9L307.1,346.5z"
				/>
				<g>
					<path
						fill="#B7CAC4"
						d="M315.8 284.8c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C314.1 281.1 315.8 282.8 315.8 284.8L315.8 284.8zM315.8 299.6c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C314.1 295.9 315.8 297.6 315.8 299.6L315.8 299.6zM315.8 314.4c0 2-1.7 3.7-3.7 3.7h-60.6c-2 0-3.7-1.7-3.7-3.7l0 0c0-2 1.7-3.7 3.7-3.7h60.6C314.1 310.7 315.8 312.4 315.8 314.4L315.8 314.4z"
					/>
				</g>
			</g>
		</Channel>
	);
};

export default DirectChannel;
