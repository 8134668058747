import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const getNestedPadding = ({ nested }) => {
	if (!nested) return;

	return css`
		padding-left: 32px;
	`;
};

const getBackgroundColor = ({ selected }) => {
	if (!selected) return;

	return css`
		background-color: #ecf5fd;
	`;
};

const StyledListItem = styled.li`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eee;
	cursor: pointer;
	padding: 12px 16px;
	text-decoration: none;

	${props => getNestedPadding(props)}
	${props => getBackgroundColor(props)}

	&:hover {
		background-color: #ecf5fd;
	}
`;

class ListItem extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired
	};

	render() {
		const { children: childrenProp } = this.props;

		return (
			<StyledListItem {...this.props}>
				{React.Children.toArray(childrenProp)}
			</StyledListItem>
		);
	}
}

export default ListItem;
