import React from 'react';

import SimpleSvg from './SimpleSvg';

const Clipboard = props => (
	<SimpleSvg
		d="M19,3h-4.184C14.403,1.837,13.304,1,12,1S9.597,1.837,9.184,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14 c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M7,8.5v-1C7,7.224,7.224,7,7.5,7h9C16.776,7,17,7.224,17,7.5v1 C17,8.776,16.776,9,16.5,9h-9C7.224,9,7,8.776,7,8.5z M15,11.5v1c0,0.276-0.224,0.5-0.5,0.5h-7C7.224,13,7,12.776,7,12.5v-1 C7,11.224,7.224,11,7.5,11h7C14.776,11,15,11.224,15,11.5z M7.5,15h9c0.276,0,0.5,0.224,0.5,0.5v1c0,0.276-0.224,0.5-0.5,0.5h-9 C7.224,17,7,16.776,7,16.5v-1C7,15.224,7.224,15,7.5,15z M12,3c0.552,0,1,0.448,1,1s-0.448,1-1,1s-1-0.448-1-1S11.448,3,12,3z"
		{...props}
	/>
);

export default Clipboard;
