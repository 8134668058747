import styled, { css } from 'styled-components';

const error = ({ error, theme: { palette } }) => {
	if (error) {
		return css`
			input,
			input:active,
			input:focus {
				border-color: ${palette.red[500]};
				box-shadow: 0 0 6px -3px ${palette.red[500]};
			}
		`;
	}
};

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			& > span {
				font-size: 14px;
			}

			.input-error {
				font-size: 12px;

				& > svg {
					font-size: 12px;
				}
			}

			input {
				height: 30px;
				font-size: 14px;
			}
		`;
	} else {
		return css`
			& > span {
				font-size: 16px;
			}

			input {
				height: 40px;
				font-size: 16px;
			}
		`;
	}
};

const updated = ({ updated, theme: { palette } }) => {
	if (updated) {
		return css`
			input,
			input:active,
			input:focus {
				border-color: ${palette.primary.default};
				box-shadow: 0 0 6px -3px ${palette.primary.default};
			}
		`;
	}
};

const StyledInput = styled.label`
	display: flex;
	flex-direction: column;
	max-width: 280px;

	& > span {
		margin-bottom: 4px;

		b {
			color: ${({ theme: { palette } }) => palette.red[800]};
		}
	}

	input {
		border-radius: 4px;
		border: 1px solid ${({ theme: { palette } }) => palette.grey[400]};
		box-sizing: border-box;
		font-family: inherit;
		padding: 0 10px;
		box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);

		&:active,
		&:focus {
			border-color: ${({ theme: { palette } }) =>
				palette.primary.default};
			box-shadow: 0 0 6px -3px ${({ theme: { palette } }) => palette.primary.default};
		}

		&:disabled {
			color: ${({ theme: { palette } }) => palette.grey[800]};
		}

		& + div {
			margin-top: 4px;
		}
	}

	.input-error {
		display: flex;
		align-items: center;
		color: ${({ theme: { palette } }) => palette.red[800]};
		font-size: 14px;
		font-weight: 500;

		& > svg {
			font-size: 16px;
			margin-bottom: 1px;
			fill: ${({ theme: { palette } }) => palette.red[800]};
		}

		& > span {
			margin-left: 4px;
		}
	}

	.input-help {
		font-size: 14px;
		color: ${({ theme: { palette } }) => palette.text.secondary};
	}

	${error};
	${size};
	${updated};
`;

export default StyledInput;
