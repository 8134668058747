import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { graphql } from '@apollo/client/react/hoc';
import { Switch, Route } from 'react-router-dom';

import { Button } from 'modules/blink/components';
import SearchBar from './SearchBar.jsx';
import { User, ArrowDown } from 'modules/blink/icons';
import { Select } from 'modules/blink/components';
import { withUser, withVoiceChannels } from '../providers';
import { MUTATE_LOGOUT_CLIENT } from '../mutations.js';
import { CreateLeadButton } from './CreateLeadButton.jsx';
import { UploadLeadsButton } from './UploadLeadsButton.jsx';

const DialStatus = ({ dialerStatus, setDialerStatus }) => {
	if (!dialerStatus) {
		return null;
	}

	switch (dialerStatus) {
		case 'Active':
		case 'Paused':
			const statusOptions = ['Active', 'Paused'].map(s => {
				return { value: s, label: s };
			});

			return (
				<Select
					className="channel-select"
					selected={statusOptions?.find(
						option => option.value === dialerStatus
					)}
					size="sm"
					options={statusOptions}
					onChange={setDialerStatus}
				/>
			);
		case 'Starting':
		case 'Off':
		default:
			return <span className="dialer-status-label">{dialerStatus}</span>;
	}
};

DialStatus.propTypes = {
	dialerStatus: PropTypes.string,
	setDialerStatus: PropTypes.func.isRequired
};

DialStatus.defaultProps = {
	dialerStatus: undefined
};

const DialStatusBlock = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;

	.select-current-value {
		border: unset;
		color: #fff;
		justify-content: left;

		p {
			padding-left: 0.25rem;
			padding-right: unset;
		}

		svg {
			fill: #fff;
			padding: unset;
		}
	}

	label {
		min-width: unset;
		max-width: 120px;
	}

	.select-menu {
		color: black;
	}

	.dialer-inbound {
		padding-right: 0.25rem;
	}

	.dialer-status-label {
		padding-left: 0.25rem;
		font-size: 14px;
	}
`;

function Header(props) {
	const {
		isLeader,
		isIndependentAgent,
		history,
		agentAvatar,
		agentName,
		agencyName,
		isActingUser,
		updateActingUser,
		logout,
		dialerMode,
		dialerStatus,
		setDialerStatus
	} = props;

	const [isSettingsDropdownOpen, showSettingsDropdown] = useState(false);

	const signOut = () => {
		logout();
		document.cookie =
			'structurely=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=' +
			process.env.REACT_APP_COOKIE_DOMAIN +
			';';
		window.location = process.env.REACT_APP_ACCOUNTS_URL;
	};

	const linkTo = (path, cb = () => null) => {
		history.push(path);
		cb();
	};

	const switchToAdmin = () => {
		updateActingUser(null);
	};

	const renderTitle = () => {
		return (
			<Switch>
				<Route exact path="/" render={() => <span>Dashboard</span>} />
				<Route path="/inbox" render={() => <span>Messages</span>} />
				<Route path="/leads" render={() => <span>Leads</span>} />
				<Route
					path="/sources"
					render={() => <span>Lead Sources</span>}
				/>
				<Route path="/ai" render={() => <span>AI Assistant</span>} />{' '}
				<Route path="/scripts" render={() => <span>Scripts</span>} />
				<Route
					path="/defaultAnswers"
					render={() => <span>Default answers</span>}
				/>
				<Route path="/answers" render={() => <span>Answers</span>} />
				<Route
					path="/defaultScripts"
					render={() => <span>Default scripts</span>}
				/>
				<Route path="/routing" render={() => <span>Rules</span>} />
				<Route
					path="/reporting"
					render={() => <span>Reporting</span>}
				/>
				<Route
					path="/enterprise"
					render={() => <span>Organization</span>}
				/>
				<Route path="/settings" render={() => <span>Settings</span>} />
			</Switch>
		);
	};

	const renderSettingsInfo = () => {
		const renderAvatar = () => {
			if (agentAvatar) {
				return (
					<img
						alt="Agent"
						className="avatar-img"
						height="34px"
						width="34px"
						src={agentAvatar}
					/>
				);
			} else {
				return (
					<div className="avatar-icon">
						<User />
					</div>
				);
			}
		};

		const renderUser = () => {
			let userName = '';
			if (isActingUser) {
				userName = `Logged in as: ${agentName}`;
			} else {
				userName = agentName;
			}

			return (
				<div
					className="user-info col"
					style={{ marginRight: !!dialerMode ? 'unset' : undefined }}
				>
					<span className="row justify-between align-center">
						<p className="user-name" title={agentName}>
							{userName}
						</p>
						<ArrowDown className="icon" fill="#fff" />
					</span>
					<p className="user-agency" title={agencyName}>
						{agencyName}
					</p>
				</div>
			);
		};

		return (
			<div
				className="dropdown profile-dropdown row align-center"
				tabIndex="0"
				style={{ marginRight: !!dialerMode ? '0.5rem' : undefined }}
				onBlur={() => showSettingsDropdown(false)}
			>
				<div
					className="row align-center"
					onClick={() => showSettingsDropdown(true)}
				>
					{renderUser()}
					{!dialerMode && renderAvatar()}
				</div>
				{isSettingsDropdownOpen ? renderSettingsDropdown() : null}
			</div>
		);
	};

	const renderSettingsDropdown = () => {
		const renderOption = (title, path, authorized = true) => {
			if (!authorized) {
				return null;
			}

			return (
				<li
					className="option"
					onClick={() =>
						linkTo(path, () => showSettingsDropdown(false))
					}
				>
					<span>{title}</span>
				</li>
			);
		};

		const renderSignOut = () => {
			if (isActingUser) {
				return (
					<Button
						kind="primary"
						onMouseDown={e => e.preventDefault()}
						onClick={switchToAdmin}
					>
						Switch to admin
					</Button>
				);
			} else if (!!dialerMode) {
				return (
					<Button
						kind="primary"
						onMouseDown={e => e.preventDefault()}
						onClick={() => {
							window.close();
						}}
					>
						Close
					</Button>
				);
			} else {
				return (
					<Button
						kind="primary"
						onMouseDown={e => e.preventDefault()}
						onClick={signOut}
					>
						Sign out
					</Button>
				);
			}
		};

		return (
			<div
				className="dropdown-content"
				style={{ right: !!dialerMode ? '0.5rem' : undefined }}
			>
				<ul className="options-list">
					{!dialerMode && (
						<li className="options-group">
							<span className="group-title">Settings</span>
							<ul>
								{renderOption('Account', '/settings/profile')}
								{renderOption(
									'Agency',
									'/settings/agency',
									isLeader
								)}
								{renderOption(
									'Billing',
									'/settings/billing',
									isLeader || isIndependentAgent
								)}
							</ul>
						</li>
					)}
					<li className="option sign-out-option">
						{renderSignOut()}
					</li>
				</ul>
			</div>
		);
	};

	return (
		<div className="header">
			{!dialerMode && (
				<>
					<div className="page-title">{renderTitle()}</div>
					<SearchBar />
					<UploadLeadsButton />
					<CreateLeadButton largeButton={false} />
				</>
			)}
			{!!dialerMode && (
				<>
					<div className="logo-wrapper">
						<img
							alt="logo"
							className="logo-sm"
							src="img/structurely-new-logo-icon.png"
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexGrow: 1,
							marginLeft: '1rem'
						}}
					>
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<span>Dialer</span>
							{!!dialerStatus && (
								<DialStatusBlock>
									{(dialerStatus === 'Active' ||
										dialerStatus === 'Paused') && (
										<span className="dialer-inbound">
											Inbound
										</span>
									)}
									<span
										style={{
											color:
												dialerStatus === 'Active'
													? 'green'
													: dialerStatus ===
															'Paused' ||
													  dialerStatus ===
															'Starting'
													? 'yellow'
													: 'red'
										}}
									>
										●
									</span>
									<DialStatus
										dialerStatus={dialerStatus}
										setDialerStatus={setDialerStatus}
									/>
								</DialStatusBlock>
							)}
						</div>
					</div>
				</>
			)}
			{renderSettingsInfo()}
		</div>
	);
}

Header.propTypes = {
	agentName: PropTypes.string,
	agentAvatar: PropTypes.string,
	agencyName: PropTypes.string,
	isLeader: PropTypes.bool,
	isIndependentAgent: PropTypes.bool,
	isActingUser: PropTypes.bool,
	dialerMode: PropTypes.bool,
	dialerStatus: PropTypes.string,
	setDialerStatus: PropTypes.func.isRequired,
	updateActingUser: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired
};

Header.defaultProps = {
	agentName: '',
	agentAvatar: '',
	agencyName: '',
	isLeader: false,
	isIndependentAgent: false,
	isActingUser: false,
	dialerMode: false,
	dialerStatus: undefined
};

const withLogout = graphql(MUTATE_LOGOUT_CLIENT, {
	props: ({ mutate }) => ({
		logout() {
			return mutate();
		}
	})
});

export default compose(
	withRouter,
	withUser,
	withLogout,
	withVoiceChannels
)(Header);
