import React from 'react';

import SimpleSvg from './SimpleSvg';

const UncheckedCheckbox = props => (
	<SimpleSvg
		d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z"
		{...props}
	/>
);

export default UncheckedCheckbox;
