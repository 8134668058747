import React from 'react';

import SimpleSvg from './SimpleSvg';

const Reply = props => (
	<SimpleSvg
		d="M 4 4 C 2.976 4 2.1404375 4.772625 2.0234375 5.765625 L 12 12 L 21.976562 5.765625 C 21.859563 4.772625 21.024 4 20 4 L 4 4 z M 2 7.7519531 L 2 13.759766 C 2.43 13.438766 2.9423281 13.257812 3.4863281 13.257812 C 4.8713281 13.257812 6 14.383578 6 15.767578 L 6 16.072266 C 7.909 16.347266 10.103375 17.418141 11.234375 19.619141 C 11.240375 19.631141 11.245953 19.64425 11.251953 19.65625 C 11.308953 19.76925 11.366969 19.881 11.417969 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 7.7519531 L 12.529297 13.669922 C 12.205297 13.872922 11.794703 13.872922 11.470703 13.669922 L 2 7.7519531 z M 3.4023438 15.263672 C 3.3058281 15.280359 3.2111094 15.326 3.1308594 15.40625 L 0.32617188 18.210938 C 0.10817187 18.428937 3.6304293e-17 18.715 0 19 C 0 19.285 0.10817188 19.571063 0.32617188 19.789062 L 3.1308594 22.59375 C 3.4518594 22.91475 4 22.685422 4 22.232422 L 4 20 L 5 20 C 5.512 20.007 8 20.185 8 23 C 8 23.553 8.448 24 9 24 C 9.552 24 10 23.553 10 23 C 10 19.045 6.729 18 5 18 L 4 18 L 4 15.767578 C 4 15.427828 3.6918906 15.213609 3.4023438 15.263672 z"
		{...props}
	/>
);

export default Reply;
