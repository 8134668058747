import React from 'react';
import PropTypes from 'prop-types';

import Svg from './Svg.jsx';

const SimpleSvg = ({ d, children, ...other }) => (
	<Svg {...other}>
		<path d={d} />
		{children}
	</Svg>
);

SimpleSvg.propTypes = {
	d: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

SimpleSvg.defaultProps = {
	children: <></>
};

export default SimpleSvg;
