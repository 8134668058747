import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { Close, Add, ChevronDown } from 'modules/blink/icons';

const chipMixin = props => {
	const { color } = props;

	if (color) {
		return css`
			background-color: #fff;
			border: 1px solid ${color};
			color: ${color};

			svg {
				fill: ${color};
			}
		`;
	}

	let baseColor = '#ecf5fc';
	let bgColor = baseColor;
	let fgColor = '#5a6c7d';
	let borderColor = 'transparent';
	let hoverColor = darken(0.05, baseColor);

	return css`
		background-color: ${bgColor};
		border: 1px solid ${borderColor};
		color: ${fgColor};

		> .icon-btn:hover {
			background-color: ${hoverColor};
		}

		svg {
			fill: ${fgColor};
		}
	`;
};

const StyledChip = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	height: 30px;

	${props => chipMixin(props)}

	> .label {
		font-size: 14px;
		padding: 0 3px 0 10px;

		&:only-child {
			padding: 0 10px;
		}
	}

	> .icon-btn {
		height: 30px;
		width: 30px;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		> svg {
			height: 14px;
			width: 14px;
		}
	}
`;

const Chip = ({ label, onClick, actionType, fill, color }) => {
	const renderIcon = () => {
		if (!actionType || !onClick) return null;

		let iconEl = null;
		switch (actionType) {
			case 'delete':
				iconEl = <Close />;
				break;
			case 'add':
				iconEl = <Add />;
				break;
			case 'dropdown':
				iconEl = <ChevronDown />;
				break;
			default:
				break;
		}

		return (
			<span onClick={onClick} className="icon-btn">
				{iconEl}
			</span>
		);
	};

	return (
		<StyledChip filled={fill} color={color}>
			<span className="label">{label}</span>
			{renderIcon()}
		</StyledChip>
	);
};

Chip.propTypes = {
	actionType: PropTypes.string,
	fill: PropTypes.bool,
	color: PropTypes.string,
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired
};

Chip.defaultProps = {
	actionType: '',
	fill: false,
	color: '',
	onClick: null
};

export default Chip;
