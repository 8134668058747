import React from 'react';

import SimpleSvg from './SimpleSvg';

const SelectedUser = props => (
	<SimpleSvg
		d="M 12 3 C 9.794 3 8 4.794 8 7 C 8 9.206 9.794 11 12 11 C 14.206 11 16 9.206 16 7 C 16 4.794 14.206 3 12 3 z M 18 12 C 16.186251 12 14.557338 12.816522 13.455078 14.097656 C 12.947202 14.039827 12.45461 14 12 14 C 8.859 14 3 15.546 3 18.5 L 3 20 C 3 20.552 3.448 21 4 21 L 12.814453 21 C 13.855633 22.789045 15.790313 24 18 24 C 21.301864 24 24 21.301864 24 18 C 24 14.698136 21.301864 12 18 12 z M 18 14 C 19.003867 14 19.905651 14.377038 20.605469 14.980469 L 18 17.585938 L 17.414062 17 C 17.024062 16.61 16.39 16.61 16 17 C 15.61 17.39 15.61 18.024062 16 18.414062 L 17.292969 19.707031 C 17.682969 20.098031 18.317031 20.098031 18.707031 19.707031 L 21.755859 16.658203 C 21.904886 17.0789 22 17.52606 22 18 C 22 20.220984 20.220984 22 18 22 C 15.779016 22 14 20.220984 14 18 C 14 15.779016 15.779016 14 18 14 z"
		{...props}
	/>
);

export default SelectedUser;
