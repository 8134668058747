import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Error as ErrorIcon } from 'modules/blink/icons';
import StyledInput from './StyledInput.jsx';

function Input(props) {
	const {
		id,
		name,
		inputMode,
		autoFocus,
		autocomplete,
		label,
		error,
		showError: forceShowError,
		help,
		value,
		onChange,
		onSubmit,
		required,
		validator,
		validatorWithMessage,
		size,
		updated,
		...other
	} = props;

	const [showError, toggleError] = useState(false);
	const [errorMessage, setError] = useState(error);

	useEffect(() => {
		if (showError) {
			if (validatorWithMessage) {
				const message = validatorWithMessage(value);
				setError(message);
				toggleError(!!message);
			} else if (validator && validator(value)) {
				toggleError(false);
			} else if (!validator && !!value) {
				toggleError(false);
			}
		}
	}, [value, showError, validator, validatorWithMessage]);

	if (errorMessage !== error && error !== undefined) {
		setError(error);
	}

	const handleBlur = () => {
		if (!validator && !validatorWithMessage) {
			return;
		}

		if (validatorWithMessage) {
			const message = validatorWithMessage(value);
			setError(message);
			toggleError(!!message);
		} else {
			toggleError(!validator(value));
		}
	};

	const renderError = () => (
		<div className="input-error">
			<ErrorIcon />
			<span>{errorMessage}</span>
		</div>
	);

	const renderHelp = () => (
		<div className="input-help">
			<span>{help}</span>
		</div>
	);

	return (
		<StyledInput
			size={size}
			error={forceShowError || showError}
			updated={updated}
		>
			{label && (
				<span>
					{label}
					<b>{required ? ' *' : ''}</b>
				</span>
			)}
			<input
				id={id ? id : undefined}
				name={name ? name : undefined}
				autoFocus={autoFocus ? autoFocus : undefined}
				autoComplete={autocomplete ? autocomplete : undefined}
				inputMode={inputMode ? inputMode : undefined}
				value={value}
				onBlur={handleBlur}
				onChange={onChange}
				onKeyDown={e =>
					e.keyCode === 13 && onSubmit ? onSubmit(value) : null
				}
				{...other}
			/>
			{(forceShowError || showError) && renderError()}
			{help && renderHelp()}
		</StyledInput>
	);
}

Input.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	size: PropTypes.string,
	error: PropTypes.string,
	updated: PropTypes.bool,
	showError: PropTypes.bool,
	help: PropTypes.string,
	required: PropTypes.bool,
	inputMode: PropTypes.string,
	autoFocus: PropTypes.string,
	autocomplete: PropTypes.string,
	validator: PropTypes.func,
	validatorWithMessage: PropTypes.func,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func.isRequired
};

Input.defaultProps = {
	id: undefined,
	name: undefined,
	value: '',
	label: '',
	error: undefined,
	size: '',
	showError: false,
	updated: false,
	help: '',
	required: false,
	inputMode: undefined,
	autoFocus: undefined,
	autocomplete: undefined,
	onSubmit: null,
	validator: null,
	validatorWithMessage: undefined
};

export default Input;
