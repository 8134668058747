const grey = {
	d2: '#616161',
	d1: '#757575',
	default: '#9e9e9e',
	l1: '#bdbdbd',
	l2: '#e0e0e0',
	l3: '#eeeeee',
	text: '#1A202C',
	secondaryText: '#718096',
	50: '#fafafa',
	100: '#F7FAFC',
	200: '#EDF2F7',
	300: '#E2E8F0',
	400: '#CBD5E0',
	500: '#A0AEC0',
	600: '#718096',
	700: '#4A5568',
	800: '#2D3748',
	900: '#1A202C'
};

export default grey;
