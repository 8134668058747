import styled, { css } from 'styled-components';

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			& > span {
				font-size: 14px;
			}
		`;
	} else {
		return css`
			& > span {
				font-size: 16px;
			}
		`;
	}
};

const StyledRadioGroup = styled.div`
	display: flex;
	flex-direction: column;

	& > span {
		margin-bottom: 8px;

		b {
			color: ${({ theme: { palette } }) => palette.red[800]};
		}
	}

	${size};
`;

export default StyledRadioGroup;
