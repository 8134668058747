import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';

import Spinner from './Spinner.jsx';

const Modal = ({
	onClose,
	onSubmit,
	title,
	headerIcon,
	submitBtnText,
	cancelBtnText,
	validated,
	className,
	submitting,
	visible,
	children
}) => (
	<Dialog
		closable={false}
		visible={visible}
		onClose={onClose}
		wrapClassName="modal-overlay"
		className={`modal ${className}`}
		bodyStyle={{ padding: 0 }}
	>
		<div className="modal-header">
			<div className="modal-title">
				{headerIcon}
				<h4 className="title">{title}</h4>
			</div>
			<div className="modal-actions">
				<button className="btn close-btn" onClick={onClose}>
					<svg
						preserveAspectRatio="xMidYMid"
						width="16"
						height="16"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							fill="currentColor"
							d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z"
						/>
					</svg>
				</button>
			</div>
		</div>
		{children}
		<div className="modal-footer">
			<button onClick={onClose} className="cancel-btn">
				{cancelBtnText}
			</button>
			<button
				onClick={onSubmit}
				className={
					'btn submit-btn' +
					(validated ? ' success-btn' : ' disabled-btn')
				}
			>
				{submitting ? <Spinner xs /> : submitBtnText}
			</button>
		</div>
	</Dialog>
);

Modal.propTypes = {
	submitBtnText: PropTypes.string,
	cancelBtnText: PropTypes.string,
	className: PropTypes.string,
	submitting: PropTypes.bool,
	validated: PropTypes.bool,
	visible: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	headerIcon: PropTypes.element.isRequired,
	children: PropTypes.element.isRequired
};

Modal.defaultProps = {
	submitBtnText: 'Submit',
	cancelBtnText: 'Cancel',
	className: '',
	visible: false,
	submitting: false,
	validated: true
};

export default Modal;
