import React from 'react';

import SimpleSvg from './SimpleSvg';

const Response = props => (
	<SimpleSvg
		d="M 14 3 C 12.9 3 12 3.9 12 5 L 12 12.960938 C 12 13.343938 12.463375 13.536625 12.734375 13.265625 L 15 11 L 20 11 C 21.1 11 22 10.1 22 9 L 22 5 C 22 3.9 21.1 3 20 3 L 14 3 z M 7 9 C 5.318 9 4 10.317 4 12 C 4 13.683 5.318 15 7 15 C 8.682 15 10 13.683 10 12 C 10 10.317 8.682 9 7 9 z M 7 16 C 4.149 16 2 17.455766 2 19.384766 L 2 20 C 2 20.552 2.448 21 3 21 L 11 21 C 11.552 21 12 20.552 12 20 L 12 19.384766 C 12 17.455766 9.851 16 7 16 z"
		{...props}
	/>
);

export default Response;
