import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function Tag(props) {
	const { children } = props;

	return <StyledTag {...props}>{children}</StyledTag>;
}

Tag.propTypes = {
	type: PropTypes.oneOf(['info', 'success', 'danger', 'warning', 'default']),
	children: PropTypes.node.isRequired
};

Tag.defaultProps = {
	type: 'default'
};

const StyledTag = styled.span`
	background-color: inherit;
	border: 1px solid;
	border-radius: 12px;
	padding: 2px 10px;
	font-weight: 500;
	font-size: 10px;
	text-align: center;
	margin-right: 4px;
	letter-spacing: 0.03em;

	&:last-of-type {
		margin-right: 0;
	}

	${({ theme: { palette }, type }) => {
		switch (type) {
			case 'info':
				return css`
					color: ${palette.blue[900] || '#002159'};
					border-color: ${palette.blue[900] || '#002159'};
					background-color: ${palette.blue[50] || '#E6F6FF'};
				`;
			case 'success':
				return css`
					color: ${palette.green[900] || '#014D40'};
					border-color: ${palette.green[900] || '#014D40'};
					background-color: ${palette.green[50] || '#EFFCF6'};
				`;
			case 'danger':
				return css`
					color: ${palette.red[900]};
					border-color: ${palette.red[900]};
					background-color: ${palette.red[50]};
				`;
			case 'warning':
				return css`
					color: ${palette.yellow[900]};
					border-color: ${palette.yellow[900]};
					background-color: ${palette.yellow[50]};
				`;
			default:
				return css`
					color: ${palette.grey[900]};
					border-color: ${palette.grey[900]};
					background-color: ${palette.grey[50]};
				`;
		}
	}}
`;

export default Tag;
