import styled, { css } from 'styled-components';

const value = ({ checked, theme: { palette } }) => {
	if (checked) {
		return css`
			& > span.checkbox-wrapper > span.checkbox-checkmark {
				border-color: transparent;
				background-color: ${palette.primary.default};
				box-shadow: 0 0 6px -3px ${palette.primary.default};
			}

			& > span.checkbox-label {
				font-weight: 500;
			}
		`;
	}
};

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			height: 16px;

			& > span.checkbox-wrapper > span.checkbox-checkmark {
				height: 16px;
				width: 16px;

				&:after {
					top: 6.25px;
					left: 3px;
				}
			}
		`;
	} else {
		return css`
			height: 18px;

			& > span.checkbox-wrapper > span.checkbox-checkmark {
				height: 18px;
				width: 18px;

				&:after {
					top: 7.25px;
					left: 4px;
				}
			}

			& > span.checkbox-label {
				font-size: 16px;
			}
		`;
	}
};

const StyledCheckbox = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	position: relative;
	padding-left: 8px;
	cursor: pointer;
	align-self: flex-start;

	&:last-child {
		margin-bottom: 0;
	}

	& > span.checkbox-wrapper {
		& > input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			box-sizing: border-box;
			padding: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		& > span.checkbox-checkmark {
			position: relative;
			top: 0;
			left: 0;
			display: block;
			border-radius: 4px;
			border: 1px solid
				${({ theme: { palette } }) => palette.grey.default};
			background-color: ${({ theme: { palette } }) =>
				palette.common.white};
			box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);
			box-sizing: border-box;

			&:after {
				content: '';
				position: absolute;
				top: 7.25px;
				left: 4px;
				display: table;
				width: 5px;
				height: 10px;
				border: 2px solid #fff;
				border-top: 0;
				border-left: 0;
				transform: rotate(45deg) scale(1) translate(-50%, -50%);
				box-sizing: border-box;
			}
		}
	}

	& > span.checkbox-label {
		margin-left: 8px;
		font-size: 14px;
	}

	&:hover {
		& > span.checkbox-wrapper > span.checkbox-checkmark {
			border-color: ${({ theme: { palette } }) =>
				palette.primary.default};
		}
	}

	${value};
	${size};
`;

export default StyledCheckbox;
