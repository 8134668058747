class CacheManager {
	constructor({ client, query, entity }) {
		if (entity) {
			const { type, id } = entity;

			let entityRef;

			switch (type) {
				case 'brokerage':
					entityRef = `BrokerageType:${id}`;
					break;
				case 'team':
					entityRef = `TeamType:${id}`;
					break;
				case 'agent':
					entityRef = `AgentType:${id}`;
					break;
				default:
					break;
			}

			this.entity = type;
			this.entityRef = entityRef;
			this.entityId = id;
		}

		this.client = client;
		this.query = query;
	}

	_getCachedData() {
		if (this.client && this.client.cache && this.client.cache.extract) {
			let cache = this.client.cache.extract();

			return cache && this.entityRef && cache[this.entityRef];
		}
	}

	_getCachedCustomersData(variables) {
		let cachedQueryData = this._readQuery({
			id: this.entityId,
			...variables
		});

		if (
			cachedQueryData &&
			cachedQueryData[this.entity] &&
			Array.isArray(cachedQueryData[this.entity].customers)
		) {
			return cachedQueryData[this.entity].customers;
		} else {
			return [];
		}
	}

	_parseCacheKey(key) {
		let fieldNameMatch = key.match(/([^:(]+)/g);
		let fieldName = fieldNameMatch ? fieldNameMatch[0] : key;
		let keyVariables = key.match(/\{.*\}/g);
		let id = keyVariables ? keyVariables[0] : '';
		let variables = id ? JSON.parse(id) : null;
		if (fieldName === 'customers' || fieldName === 'totalCustomersCount') {
			return {
				id,
				variables,
				fieldName
			};
		} else {
			return {
				id: null,
				variables: {},
				type: null
			};
		}
	}

	_getCachedCustomerQueries() {
		let cachedQueries = [];
		let cachedData = this._getCachedData();

		if (cachedData) {
			Object.keys(cachedData).forEach(key => {
				let { id, variables, fieldName } = this._parseCacheKey(key);
				if (fieldName === 'customers') {
					let customerIds = [];
					if (Array.isArray(cachedData[key])) {
						customerIds = cachedData[key].map(customer => {
							const { __ref } = customer;
							if (
								typeof __ref === 'string' &&
								__ref.startsWith('CustomerType:')
							) {
								// Return only the customerId
								return __ref.slice(13);
							} else {
								return '';
							}
						});
					}

					cachedQueries.push({
						id,
						variables,
						customerIds
					});
				} else if (fieldName === 'totalCustomersCount') {
					cachedQueries.push({
						id,
						totalCustomersCount: cachedData[key]
					});
				}
			});
		}

		let formattedQueries = cachedQueries.reduce((accumulator, query) => {
			const { id, customerIds } = query;

			if (customerIds !== undefined) {
				let complementary = cachedQueries.find(
					query =>
						query.id === id &&
						query.totalCustomersCount !== undefined
				);

				if (complementary) {
					const { totalCustomersCount } = complementary;

					return [
						...accumulator,
						{
							...query,
							totalCustomersCount
						}
					];
				} else {
					return accumulator;
				}
			} else {
				return accumulator;
			}
		}, []);

		return formattedQueries;
	}

	_getCustomerVariables(customer) {
		if (customer) {
			const {
				chatbotMuted,
				chatbotVoiceMuted,
				assignedAgent,
				assignedTeam,
				archived,
				sources,
				stages,
				agentHasEngaged
			} = customer;

			return {
				muted: chatbotMuted,
				voiceMuted: chatbotVoiceMuted,
				assigned: !!assignedAgent || !!assignedTeam,
				archived,
				sources,
				stages,
				agentEngaged: agentHasEngaged
			};
		}
	}

	_updateCustomer(customers, customer) {
		let newCustomers = customers.map(existingCustomer => {
			if (existingCustomer.id === customer.id) {
				return customer;
			} else {
				return existingCustomer;
			}
		});

		return newCustomers;
	}

	_updateUnreadCount(customers, customerId, unreadCount) {
		let newCustomers = customers.map(customer => {
			if (customer.id === customerId && customer.conversation) {
				return {
					...customer,
					conversation: {
						...customer.conversation,
						unreadCount
					}
				};
			} else {
				return customer;
			}
		});

		return newCustomers;
	}

	_addCustomer(customers, customer) {
		let newCustomers = [customer, ...customers];

		return newCustomers;
	}

	_removeCustomer(customers, customerId) {
		let newCustomers = customers.filter(
			customer => customer.id !== customerId
		);

		return newCustomers;
	}

	_readQuery(variables) {
		return this.client.readQuery({
			query: this.query,
			variables
		});
	}

	_writeQuery(variables, data) {
		try {
			this.client.writeQuery({
				query: this.query,
				variables,
				data
			});
		} catch (e) {
			console.error('Failed to update cached query\n', e);
		}
	}

	_shouldRemoveCustomer(cachedVariables, customerVariables) {
		let shouldRemove = false;

		if (customerVariables) {
			for (let key of Object.keys(cachedVariables)) {
				let cachedVar = cachedVariables[key];
				let customerVar = customerVariables[key];

				if (Array.isArray(cachedVar) && Array.isArray(customerVar)) {
					let matchedEl = cachedVar.find(el =>
						customerVar.includes(el)
					);
					if (!matchedEl) {
						shouldRemove = true;
						break;
					}
				} else if (cachedVar !== customerVar) {
					shouldRemove = true;
					break;
				}
			}
		}

		return shouldRemove;
	}

	_shouldAddCustomer(cachedVariables, customerVariables) {
		let shouldAdd = true;

		for (let key of Object.keys(cachedVariables)) {
			let cachedVar = cachedVariables[key];
			let customerVar = customerVariables[key];

			if (Array.isArray(cachedVar) && Array.isArray(customerVar)) {
				let matchedEl = cachedVar.find(el => customerVar.includes(el));
				if (!matchedEl) {
					shouldAdd = false;
					break;
				}
			} else if (cachedVar !== customerVar) {
				shouldAdd = false;
				break;
			}
		}

		return shouldAdd;
	}

	_getUpdatedCachedQueries(updates) {
		const { customer: incomingCustomer, customerId, unreadCount } = updates;

		let cachedCustomerQueries = this._getCachedCustomerQueries();
		if (Array.isArray(cachedCustomerQueries)) {
			let newCachedQueries = cachedCustomerQueries.map(query => {
				const { variables, customerIds, totalCustomersCount } = query;
				let newCustomers = customerIds.slice(0);
				let hasUpdates = false;
				let newTotalCustomersCount = totalCustomersCount || 0;
				let cachedCustomerId = customerIds.find(
					customerRefId => customerRefId === customerId
				);
				let incomingCustomerVariables = this._getCustomerVariables(
					incomingCustomer
				);

				if (variables && incomingCustomerVariables) {
					if (cachedCustomerId) {
						if (
							this._shouldRemoveCustomer(
								variables,
								incomingCustomerVariables
							)
						) {
							// Get cached customers data
							let cachedCustomers = this._getCachedCustomersData(
								variables
							);

							// remove customer
							newCustomers = this._removeCustomer(
								cachedCustomers,
								customerId
							);
							newTotalCustomersCount--;
							hasUpdates = true;
						} else if (incomingCustomer) {
							// Get cached customers data
							let cachedCustomers = this._getCachedCustomersData(
								variables
							);

							newCustomers = this._updateCustomer(
								cachedCustomers,
								incomingCustomer
							);
							hasUpdates = true;
						}
					} else if (incomingCustomer) {
						if (
							this._shouldAddCustomer(
								variables,
								incomingCustomerVariables
							)
						) {
							// Get cached customers data
							let cachedCustomers = this._getCachedCustomersData(
								variables
							);

							// add customer
							newCustomers = this._addCustomer(
								cachedCustomers,
								incomingCustomer
							);
							newTotalCustomersCount++;
							hasUpdates = true;
						}
					}
				} else if (variables && unreadCount !== undefined) {
					// Get cached customers data
					let cachedCustomers = this._getCachedCustomersData(
						variables
					);

					newCustomers = this._updateUnreadCount(
						cachedCustomers,
						customerId,
						unreadCount
					);
					hasUpdates = true;
				}

				return {
					variables,
					totalCustomersCount:
						newTotalCustomersCount >= 0
							? newTotalCustomersCount
							: 0,
					customers: newCustomers,
					hasUpdates
				};
			});

			return newCachedQueries.filter(query => query.hasUpdates);
		} else {
			return [];
		}
	}

	_getUpdatedData(customers, totalCustomersCount, variables) {
		let newCustomers = customers.slice(0);

		let prev = this._readQuery({
			id: this.entityId,
			...variables
		});

		return Object.assign({}, prev, {
			...prev,
			[this.entity]: {
				...prev[this.entity],
				totalCustomersCount: totalCustomersCount,
				customers: newCustomers
			}
		});
	}

	update(updates) {
		if (!updates) return;

		// Get cached queries that need to add/remove customer and update totalCustomersCount
		let updatedCachedQueries = this._getUpdatedCachedQueries(updates);

		// Get updated data for each cached query and write back to cache with new data
		updatedCachedQueries.forEach(query => {
			const { variables, customers, totalCustomersCount } = query;
			let data = this._getUpdatedData(
				customers,
				totalCustomersCount,
				variables
			);
			if (data) {
				this._writeQuery(
					{
						id: this.entityId,
						...variables
					},
					data
				);
			}
		});
	}
}

export default CacheManager;
