import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import '../styles.scss';
import NavFooter from './NavFooter.jsx';
import NavHeader from './NavHeader.jsx';
import NavLink from './NavLink.jsx';

import { withUser } from 'modules/core/providers';
import {
	Analytics,
	Script,
	Dashboard,
	Messages,
	Team as Leads,
	Share,
	Clipboard,
	Organization
} from 'modules/blink/icons';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AssessmentIcon from '@material-ui/icons/Assessment';

const NavLayout = ({
	location,
	isLeader,
	isIndependentAgent,
	hasDialer,
	hasReports,
	isStructurelyAdmin
}) => {
	const { pathname } = location;
	let expanded = false;

	const renderScriptsTab = () => {
		return (
			<NavLink
				title="Scripts"
				icon={<Script />}
				path="/ai"
				selected={
					pathname.includes('/ai') ||
					pathname.includes('/scripts') ||
					pathname.includes('/answers')
				}
				expanded={expanded}
			/>
		);
	};

	return (
		<section className={'navbar' + (expanded ? ' expand' : '')}>
			<NavHeader />
			<nav className="nav-body">
				<NavLink
					title="Dashboard"
					icon={<Dashboard />}
					path="/"
					selected={pathname === '/'}
					expanded={expanded}
				/>
				{hasDialer && (
					<NavLink
						title="Dialer"
						icon={<PhoneInTalkIcon />}
						selected={pathname.includes('/dialer')}
						expanded={expanded}
						onClick={e => {
							const newWindow = window.open(
								'/#/dialer',
								'structurely-dialer',
								'width=425,height=775,location=no,toolbar=no,popup'
							);

							// Resize the window to 425,775
							newWindow.resizeTo(425, 775);

							e.preventDefault();
						}}
					/>
				)}
				<NavLink
					title="Messages"
					icon={<Messages />}
					path="/inbox"
					selected={pathname.includes('/inbox')}
					expanded={expanded}
				/>
				<NavLink
					title="Leads"
					icon={<Leads />}
					path="/leads"
					selected={pathname.includes('/leads')}
					expanded={expanded}
				/>
				<NavLink
					title="Reporting"
					icon={<Analytics />}
					path="/reporting"
					selected={pathname.includes('/reporting')}
					expanded={expanded}
				/>
				<NavLink
					title="Lead Sources"
					icon={<Share />}
					path="/sources"
					selected={pathname.includes('/sources')}
					expanded={expanded}
				/>
				<NavLink
					title="Rules"
					icon={<Clipboard />}
					path="/routing"
					selected={pathname.includes('/routing')}
					expanded={expanded}
					authorized={isLeader || isIndependentAgent}
				/>
				{renderScriptsTab()}
				<NavLink
					title="Organization"
					icon={<Organization />}
					path="/enterprise"
					selected={pathname.includes('/enterprise')}
					expanded={expanded}
					authorized={isLeader}
				/>
				<NavLink
					title="Lead Uploads"
					icon={<MoveToInboxIcon />}
					path="/lead-uploads"
					selected={pathname.includes('/lead-uploads')}
					expanded={expanded}
				/>
				{hasReports && (
					<NavLink
						title="Custom Reports"
						icon={<AssessmentIcon />}
						path="/custom-reports"
						selected={pathname.includes('/custom-reports')}
						expanded={expanded}
					/>
				)}
				{isStructurelyAdmin && (
					<NavLink
						title="Admin Investigations"
						icon={<FindInPageIcon />}
						path="/admin-tools"
						selected={pathname.includes('/admin-tools')}
						expanded={expanded}
					/>
				)}
			</nav>
			<NavFooter expanded={expanded} />
		</section>
	);
};

NavLayout.defaultProps = {
	isLeader: false,
	isIndependentAgent: false,
	hasDialer: false,
	hasReports: false,
	isStructurelyAdmin: false
};

NavLayout.propTypes = {
	isStructurelyAdmin: PropTypes.bool,
	isLeader: PropTypes.bool,
	isIndependentAgent: PropTypes.bool,
	hasDialer: PropTypes.bool,
	hasReports: PropTypes.bool,
	location: PropTypes.object.isRequired
};

export default withRouter(withUser(NavLayout));
