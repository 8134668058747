import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FileUploader } from 'react-drag-drop-files';
import { useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';

import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Stepper,
	Step,
	StepLabel
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { withRouter } from 'react-router-dom';
import { withUser, withFeatureFlags } from 'modules/core/providers';
import { Modal } from 'modules/blink/components';
import { useTextInput } from 'modules/common/utils';
import { showPopover, getFormattedPhoneNumber } from 'modules/core/helpers';

import { MUTATE_UPLOAD_LEADS } from '../mutations';

const steps = ['Upload File', 'Select Conversation', 'Review and Upload'];
const fileTypes = ['CSV', 'XLSX', 'XLS'];

function nameValidator(value) {
	if (!value) {
		return 'Name is required';
	}

	return undefined;
}

function camelToTitleCase(str) {
	const spacedStr = str.replace(/([A-Z])/g, ' $1');

	// Capitalize the first letter of each word and convert the rest to lowercase
	const titleCasedStr = spacedStr.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
	});

	return titleCasedStr.trim();
}

const TableCellValue = ({ value, wordBreak }) => {
	if (Array.isArray(value)) {
		if (!value.length) {
			return <span>&mdash;</span>;
		}

		return (
			<div className="multi-value-cell">
				{value.map(t => (
					<span key={t}>{t}</span>
				))}
			</div>
		);
	}

	if (value) {
		return <span style={{ wordBreak }}>{value}</span>;
	}

	return <span>&mdash;</span>;
};

TableCellValue.propTypes = {
	value: PropTypes.string,
	wordBreak: PropTypes.string
};

TableCellValue.defaultProps = {
	value: undefined,
	wordBreak: undefined
};

const LeadTableRow = ({ lead }) => {
	const hasProps = !!lead.properties && !!Object.keys(lead.properties).length;
	const cellStyle = hasProps ? { borderBottom: 'none' } : undefined;
	return (
		<>
			<TableRow>
				<TableCell style={cellStyle}>
					<TableCellValue value={lead.name} wordBreak="break-all" />
				</TableCell>
				<TableCell style={cellStyle}>
					<TableCellValue
						value={getFormattedPhoneNumber(lead.phone)}
					/>
				</TableCell>
				<TableCell style={cellStyle}>
					<TableCellValue value={lead.email} wordBreak="break-all" />
				</TableCell>
			</TableRow>
			{!!lead.properties && !!Object.keys(lead.properties).length && (
				<TableRow>
					<TableCell colspan="3" style={{ paddingTop: 0 }}>
						<div
							style={{ paddingLeft: '1rem', paddingTop: '-1rem' }}
						>
							Properties
							{Object.entries(lead.properties).map(
								([key, value]) => {
									return (
										<div style={{ paddingLeft: '1rem' }}>
											{camelToTitleCase(key)}: {value}
										</div>
									);
								}
							)}
						</div>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

LeadTableRow.propTypes = {
	lead: PropTypes.object.isRequired
};

const UploadLeadsComponent = ({
	isOpen,
	onClose,
	user,
	allUseCases,
	client
}) => {
	const entityId = user?.brokerageId || user?.teamId || user?.agentId;
	const [activeStep, setActiveStep] = React.useState(0);
	const [conversation, setConversation] = useState(1);
	const [data, setData] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const handleNext = useCallback(() => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	}, [setActiveStep]);

	const [
		name,
		setName,
		hasNameError,
		nameError,
		showNameError
	] = useTextInput(nameValidator);

	const handleStepTwoNext = useCallback(() => {
		if (hasNameError) {
			showNameError();
			return;
		}

		handleNext();
	}, [hasNameError, showNameError, handleNext]);

	const processLeads = useCallback(
		file => {
			const formData = new FormData();
			formData.append('file', file);

			// Set the name to the filename without the extension
			setName(file.name.split('.')[0]);

			fetch(
				`${process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT}process-leads`,
				{
					method: 'POST',
					body: formData
				}
			)
				.then(response => {
					return response.json();
				})
				.then(payload => {
					setData(payload);
					handleNext();
				});
		},
		[setData, setName, handleNext]
	);

	const cleanupAndClose = useCallback(() => {
		setActiveStep(0);
		setConversation(1);
		setData(null);
		setName(null);
		setSubmitting(false);
		onClose();
	}, [onClose, setName, setConversation, setSubmitting, setData]);

	const handleChangeConversation = useCallback(
		event => {
			setConversation(event.target.value);
		},
		[setConversation]
	);

	const [uploadLeads] = useMutation(MUTATE_UPLOAD_LEADS);

	const tagOptions = allUseCases?.filter(t => {
		// Filter out any tags that end with "Specific" those are for new enrollment only
		return t.customScript || !t.label.endsWith('Specific');
	});

	const onUploadLeads = useCallback(() => {
		if (!data?.leads?.length) {
			showPopover('Unable to upload an empty set of leads');
			return;
		}

		if (!entityId) {
			return;
		}

		if (submitting) {
			return;
		}

		setSubmitting(true);

		uploadLeads({
			variables: {
				name,
				entityId,
				leads: data.leads.map(lead => {
					const cleanLead = { ...lead };

					// Remove invalid parameters from payload
					delete cleanLead.rowId;

					// Set tags for the lead
					cleanLead.tags = [...(lead.tags || [])];
					if (conversation !== 1) {
						cleanLead.tags.push(conversation);
					}

					// Set the lead's source
					if (!cleanLead.leadSource) {
						cleanLead.leadSource = name;
					}

					return cleanLead;
				})
			}
		})
			.then(result => {
				if (result?.data?.createLeadList?.result) {
					// Close and redirect after 250 ms, giving time to Datalayer to process the first lead
					setTimeout(() => {
						client.refetchQueries({
							include: ['getLeadEnrollmentLists']
						});
						showPopover(
							`Uploaded ${
								result.data.createLeadList.result.remainingItems
							} leads`,
							'success'
						);
						cleanupAndClose();
						window.location.replace(
							`/#/lead-uploads/${
								result.data.createLeadList.result.id
							}`
						);
					}, 250);
				}
			})
			.catch(err => {
				if (err?.graphQLErrors) {
					err.graphQLErrors.forEach(error => {
						showPopover(error.message, 'error');
					});
				} else {
					showPopover('Failed to upload leads', 'error');
				}
				setSubmitting(false);
			});
	}, [
		cleanupAndClose,
		conversation,
		data?.leads,
		entityId,
		name,
		submitting,
		client,
		uploadLeads
	]);

	return (
		<Modal title="Upload Leads" isOpen={isOpen} onClose={cleanupAndClose}>
			<StyledModalContent>
				<div className="body">
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div className="body-content">
						{activeStep === 0 && (
							<>
								<h2 style={{ marginBottom: '0.5rem' }}>
									Upload a file containing your leads
								</h2>
								<FileUploader
									multiple={false}
									handleChange={processLeads}
									name="file"
									types={fileTypes}
								/>
							</>
						)}
						{activeStep === 1 && (
							<>
								<h2 style={{ marginBottom: '0.5rem' }}>
									Select the conversation start for{' '}
									{data?.leads?.length} leads
								</h2>
								<TextField
									required
									label="Name"
									value={name}
									onChange={setName}
									error={!!nameError}
									helperText={
										!!nameError ? nameError : undefined
									}
									style={{ marginBottom: '0.5rem' }}
								/>
								<FormControl>
									<InputLabel id="converation-label">
										Conversation
									</InputLabel>
									<Select
										labelId="converation-label"
										id="select-converation"
										value={conversation}
										onChange={handleChangeConversation}
									>
										<MenuItem value={1}>
											<em>Select a conversation</em>
										</MenuItem>
										{tagOptions?.map(t => (
											<MenuItem
												value={t.value}
												key={t.value}
											>
												{t.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Button
									color="primary"
									variant="contained"
									onClick={handleStepTwoNext}
									style={{
										marginTop: '0.5rem',
										minWidth: 355,
										marginRight: 0
									}}
								>
									Next
								</Button>
								{conversation === 1 && (
									<Alert
										variant="outlined"
										severity="info"
										style={{
											maxWidth: 355,
											marginTop: '0.5rem'
										}}
									>
										When leads are uploaded without a
										conversation, they will be configured
										for direct communication. However the
										Structurely AI will not automatically
										engage them. You will need to manually
										start a conversation for each lead
										uploaded in this manner.
									</Alert>
								)}
							</>
						)}
						{activeStep === 2 && (
							<>
								<h2 style={{ marginBottom: '0.5rem' }}>
									Review the leads in this list and upload
									them
								</h2>
								<div>
									<TableContainer
										component={Paper}
										style={{
											marginBottom: '0.5rem',
											maxHeight: '50vh'
										}}
									>
										<Table aria-label="webhook items table">
											<TableHead>
												<TableRow>
													<TableCell>Name</TableCell>
													<TableCell>Phone</TableCell>
													<TableCell>Email</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{data?.leads?.map(row => (
													<LeadTableRow
														key={row.rowId}
														lead={row}
													/>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
								<Alert variant="outlined" severity="info">
									The leads in this list are expecting to hear
									from my organization, and have opted-in to
									receive communication from my organization
									at least once in the past year. I can
									confirm that these leads were not purchased,
									rented, or provided by a third party.
								</Alert>
							</>
						)}
					</div>
				</div>
				<div className="footer">
					{activeStep === 2 && (
						<Button
							color="primary"
							variant="contained"
							onClick={submitting ? undefined : onUploadLeads}
							disabled={submitting}
						>
							Create {data?.leads?.length} Leads
						</Button>
					)}
					<Button
						color="default"
						variant="contained"
						onClick={cleanupAndClose}
					>
						Cancel
					</Button>
				</div>
			</StyledModalContent>
		</Modal>
	);
};

UploadLeadsComponent.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	allUseCases: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired
};

export const UploadLeadsModal = withFeatureFlags(
	withRouter(withUser(withApollo(UploadLeadsComponent)))
);

const StyledModalContent = styled.div`
	height: 85vh;
	width: 90vw;

	display: flex;
	flex-direction: column;

	.body {
		margin-top: 1rem;
		margin-left: 1rem;
		margin-right: 1rem;

		min-width: 600px;
		overflow-y: auto;

		display: flex;
		flex-direction: column;

		> div {
			margin-bottom: 1rem;
		}
	}

	.body-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			min-width: 355px;
		}
	}

	.footer {
		margin-top: auto;
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 1rem;

		display: flex;
		justify-content: right;

		border-top-style: solid;
		border-top-width: thin;
		padding-top: 0.5rem;
	}

	button {
		margin-right: 0.5rem;
		text-transform: none;
		max-height: 36px;
	}
`;
