import React from 'react';

const Ok = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="19pt"
		height="19pt"
		viewBox="0 0 19 19"
		version="1.1"
		{...props}
	>
		<g id="surface72744">
			<path
				style={{
					fill: 'none',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeOpacity: 1,
					strokeMiterlimit: 10
				}}
				d="M 19.998355 7.001645 L 9 18 L 4.998355 13.998355 "
				transform="matrix(0.791667,0,0,0.791667,0,0)"
			/>
		</g>
	</svg>
);

export default Ok;
