const teal = {
	d3: '#187875',
	d2: '#308685',
	d1: '#489595',
	default: '#60A4A5',
	l1: '#77B2B4',
	l2: '#8FC1C4',
	l3: '#BFDEE4',
	onCloud: '#D7EDF4',
	onWhite: '#EBF7F6',
	50: '#e0f2f1',
	100: '#D7EDF4',
	200: '#BFDEE4',
	300: '#8FC1C4',
	400: '#77B2B4',
	500: '#60A4A5',
	600: '#489595',
	700: '#308685',
	800: '#187875',
	900: '#006965'
};

export default teal;
