import { Component } from 'react';
import PropTypes from 'prop-types';

class Upscope extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isInstalled: false
		};
	}

	static propTypes = {
		agentId: PropTypes.string,
		email: PropTypes.string
	};

	static defaultProps = {
		agentId: '',
		email: ''
	};

	componentDidMount() {
		const { agentId, email } = this.props;
		const { isInstalled } = this.state;

		if (agentId && email && !isInstalled) {
			this.handleLoadUpscope();
		}
	}

	componentDidUpdate() {
		const { agentId, email } = this.props;
		const { isInstalled } = this.state;

		if (agentId && email && !isInstalled) {
			this.handleLoadUpscope();
		}
	}

	handleLoadUpscope() {
		const { agentId, email } = this.props;
		// eslint-disable-next-line
		(function(w, u, d) {
			if (typeof u !== 'function') {
				var i = function() {
					i.c(arguments);
				};
				i.q = [];
				i.c = function(args) {
					i.q.push(args);
				};
				w.Upscope = i;
				var l = function() {
					var s = d.createElement('script');
					s.type = 'text/javascript';
					s.async = true;
					s.src = 'https://js.upscope.io/upscope.js';
					var x = d.getElementsByTagName('script')[0];
					x.parentNode.insertBefore(s, x);
				};
				l();
			}
		})(window, window.Upscope, document);

		window.Upscope('init', {
			apiKey: '74tmhwoCQDfnpVgBLu3JXqUV',
			identities: [email],
			uniqueId: agentId,
			trackConsole: true,
			allowRemoteConsole: true
		});

		this.setState({
			isInstalled: true
		});
	}

	render() {
		return null;
	}
}

export default Upscope;
