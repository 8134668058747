import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { ChevronRight } from 'modules/blink/icons';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		marginBottom: '0.5rem'
	},
	title: {
		fontSize: 14,
		textTransform: 'uppercase',
		color: '#8DABC4'
	},
	pos: {
		marginBottom: 12
	}
});

export const VoiceCallItem = ({
	label,
	total,
	isLead,
	callDispositions,
	updateCallDispositionFilters
}) => {
	const classes = useStyles();

	const onClick = useCallback(() => {
		if (updateCallDispositionFilters) {
			updateCallDispositionFilters(callDispositions);
		}
	}, [updateCallDispositionFilters, callDispositions]);

	return (
		<Card className={classes.root} variant="outlined">
			<CardContent>
				<Typography
					className={classes.title}
					color="textSecondary"
					gutterBottom
				>
					{label}
				</Typography>
				{isLead && (
					<Link to="/inbox" onClick={onClick} className="link-btn">
						<Typography
							variant="h5"
							component="h2"
							style={{ display: 'flex', alignItems: 'center' }}
						>
							{total} lead{total === 1 ? '' : 's'}
							<ChevronRight
								color="#2a354f"
								width="18px"
								height="18px"
							/>
						</Typography>
					</Link>
				)}
				{!isLead && (
					<Typography variant="h5" component="h2">
						{total} call{total === 1 ? '' : 's'}
					</Typography>
				)}
			</CardContent>
		</Card>
	);
};

VoiceCallItem.propTypes = {
	label: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	isLead: PropTypes.bool,
	callDispositions: PropTypes.array,
	updateCallDispositionFilters: PropTypes.func
};

VoiceCallItem.defaultProps = {
	isLead: undefined,
	callDispositions: undefined,
	updateCallDispositionFilters: undefined
};
