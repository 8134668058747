import styled, { css } from 'styled-components';

const error = ({ error, theme: { palette } }) => {
	if (error) {
		return css`
			textarea,
			textarea:active,
			textarea:focus {
				border-color: ${palette.red[500]};
				box-shadow: 0 0 6px -3px ${palette.red[500]};
			}
		`;
	}
};

const StyledTextarea = styled.label`
	display: flex;
	flex-direction: column;

	& > span {
		font-size: 16px;
		margin-bottom: 4px;

		b {
			color: ${({ theme: { palette } }) => palette.red[800]};
		}
	}

	textarea {
		border-radius: 4px;
		border: 1px solid ${({ theme: { palette } }) => palette.grey[400]};
		box-sizing: border-box;
		font-family: inherit;
		outline: none;
		resize: none;
		height: 80px;
		font-size: 14px;
		padding: 4px 8px;

		&:active,
		&:focus {
			border-color: ${({ theme: { palette } }) =>
				palette.primary.default};
			box-shadow: 0 0 6px -3px ${({ theme: { palette } }) => palette.primary.default};
		}

		&:disabled {
			color: ${({ theme: { palette } }) => palette.grey[800]};
		}

		& + div {
			margin-top: 4px;
		}
	}

	.textarea-error {
		display: flex;
		align-items: center;
		color: ${({ theme: { palette } }) => palette.red[800]};
		font-size: 14px;
		font-weight: 500;

		& > svg {
			font-size: 16px;
			margin-bottom: 1px;
			fill: ${({ theme: { palette } }) => palette.red[800]};
		}

		& > span {
			margin-left: 4px;
		}
	}

	.textarea-help {
		font-size: 14px;
		color: ${({ theme: { palette } }) => palette.text.secondary};
	}

	${error};
`;

export default StyledTextarea;
