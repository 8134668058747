import styled, { css } from 'styled-components';

const active = ({ active, theme: { palette } }) => {
	if (active) {
		return css`
			background-color: ${palette.cloud.default};
			font-weight: 500;
		`;
	}
};

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			height: 32px;
			line-height: 32px;
			padding: 0 10px;

			& > p {
				font-size: 14px;
			}
		`;
	} else {
		return css`
			height: 40px;
			line-height: 40px;
			padding: 0 14px;

			& > p {
				font-size: 16px;
			}
		`;
	}
};

const StyledMenuItem = styled.li`
	display: flex;
	align-items: center;
	outline: none;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme: { palette } }) => palette.cloud.default};
	}

	&:focus {
		background-color: ${({ theme: { palette } }) => palette.cloud.default};
		font-weight: 500;
	}

	${active};
	${size};
`;

export default StyledMenuItem;
