import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Tooltip } from 'modules/blink/components';

const NavLink = ({
	authorized,
	children,
	title,
	icon,
	path,
	selected,
	expanded,
	onClick,
	tooltipPlacement,
	tooltipVerticalBuffer,
	tooltipHorizontalBuffer
}) => {
	if (!authorized) return null;

	const renderIcon = () => {
		if (typeof icon === 'string') {
			return <span className={`icon ${icon}`} />;
		} else {
			return icon;
		}
	};

	const renderLink = () => {
		if (children) {
			return children;
		}

		return (
			<Link
				to={path}
				className={'nav-link' + (selected ? ' selected' : '')}
				onClick={onClick}
			>
				<span className="link-wrapper row align-center">
					{renderIcon()}
					{expanded ? (
						<span className="link-title">{title}</span>
					) : null}
				</span>
			</Link>
		);
	};

	return (
		<li>
			<Tooltip
				placement={tooltipPlacement}
				verticalBuffer={tooltipVerticalBuffer}
				horizontalBuffer={tooltipHorizontalBuffer}
				label={title}
				disable={expanded}
			>
				{renderLink()}
			</Tooltip>
		</li>
	);
};

NavLink.propTypes = {
	authorized: PropTypes.bool,
	children: PropTypes.element,
	title: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	path: PropTypes.string,
	selected: PropTypes.bool,
	expanded: PropTypes.bool,
	onClick: PropTypes.func,
	tooltipPlacement: PropTypes.string,
	tooltipVerticalBuffer: PropTypes.string,
	tooltipHorizontalBuffer: PropTypes.string
};

NavLink.defaultProps = {
	authorized: true,
	children: null,
	title: '',
	icon: '',
	path: '',
	selected: false,
	expanded: false,
	onClick: undefined,
	tooltipPlacement: 'right',
	tooltipVerticalBuffer: '30px',
	tooltipHorizontalBuffer: '60px'
};

export default NavLink;
