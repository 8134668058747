import cookie from 'cookie';

const getUser = () => {
	const userCookie = cookie.parse(document.cookie)['structurely'];
	let user = null;

	if (userCookie) {
		try {
			user = JSON.parse(userCookie);
		} catch (e) {
			console.error('Failed to get user from cookies\n', e);
		}
	}

	if (user) {
		return user;
	} else {
		return {
			agentId: '',
			teamId: '',
			brokerageId: '',
			isTeamLead: false,
			isBroker: false,
			isLeader: false,
			isZendesk: false,
			isIndependentAgent: false,
			authJwt: ''
		};
	}
};

export { getUser };
