import React from 'react';
import PropTypes from 'prop-types';

import withActingUser from './withActingUser.js';
import { UserContext } from '../contexts';

const withUser = Component => {
	const UserComponent = props => (
		<UserContext.Consumer>
			{userContext => {
				if (!userContext) return <Component />;
				const { actingUser } = props;
				const { user, updateUser } = userContext;

				let appUser = user;
				if (actingUser) {
					appUser = {
						...user,
						...actingUser,
						isActingUser: true
					};
				}

				let newProps = {
					...props,
					...appUser,
					isLeader: appUser.isTeamLead || appUser.isBroker,
					user: appUser,
					updateUser
				};

				return <Component {...newProps} />;
			}}
		</UserContext.Consumer>
	);

	UserComponent.propTypes = {
		actingUser: PropTypes.object
	};

	UserComponent.defaultProps = {
		actingUser: null
	};

	return withActingUser(UserComponent);
};

export default withUser;
