import React from 'react';

import SimpleSvg from './SimpleSvg';

const NameTag = props => (
	<SimpleSvg
		d="M 12 1 C 11.448 1 11 1.448 11 2 L 11 3 L 13 3 L 13 2 C 13 1.448 12.552 1 12 1 z M 13 3 L 13 4 C 13 4.552 12.552 5 12 5 C 11.862 5 11.730953 4.9725 11.611328 4.921875 C 11.252453 4.77 11 4.414 11 4 L 11 3 L 6 3 C 4.895 3 4 3.895 4 5 L 4 20 C 4 21.105 4.895 22 6 22 L 18 22 C 19.105 22 20 21.105 20 20 L 20 5 C 20 3.895 19.105 3 18 3 L 13 3 z M 12 8 C 13.417 8 14.5 9.083 14.5 10.5 C 14.5 11.917 13.417 13 12 13 C 10.583 13 9.5 11.917 9.5 10.5 C 9.5 9.083 10.583 8 12 8 z M 12 14 C 14.731 14 17 15.212031 17 16.957031 L 17 17.333984 C 17 17.701984 16.72 18 16.375 18 L 7.625 18 C 7.28 18 7 17.701031 7 17.332031 L 7 16.955078 C 7 15.211078 9.269 14 12 14 z"
		{...props}
	/>
);

export default NameTag;
