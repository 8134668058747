import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { Close as CloseIcon } from '../../icons';
import Button from '../Button';
import StyledModal from './StyledModal.jsx';

ReactModal.setAppElement('#root');

function Modal(props) {
	const {
		title,
		isOpen,
		onAfterOpen,
		onClose,
		contentLabel,
		children
	} = props;

	const renderHeader = () => (
		<div className="modal-header">
			<h3>{title}</h3>
			<Button kind="transparent" ghost={true} size="sm" onClick={onClose}>
				<CloseIcon />
			</Button>
		</div>
	);

	return (
		<StyledModal
			isOpen={isOpen}
			onAfterOpen={onAfterOpen}
			onRequestClose={onClose}
			contentLabel={contentLabel}
			parentSelector={() => document.body}
			shouldFocusAfterRender={false}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}
			shouldReturnFocusAfterClose={true}
			portalClassName="ReactModalPortal"
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: 1000
				}
			}}
		>
			{title && renderHeader()}
			{children}
		</StyledModal>
	);
}

Modal.propTypes = {
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	onAfterOpen: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	contentLabel: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};

Modal.defaultProps = {
	title: '',
	isOpen: false,
	onAfterOpen: () => null,
	contentLabel: 'Modal'
};

export default Modal;
