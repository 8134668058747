import { gql } from '@apollo/client';

const FRAGMENT_AGENT_DATA = gql`
	fragment AgentData on AgentType {
		id
		name
		email
		phone
		isBroker
		isTeamLead
		isLender
		isPrimary
		totalCustomersCount
		teamId
		brokerageId
	}
`;

const FRAGMENT_TEAM_DATA = gql`
	fragment TeamData on TeamType {
		id
		name
		totalCustomersCount
	}
`;

const FRAGMENT_CUSTOMER_DATA = gql`
	fragment CustomerData on CustomerType {
		id
		name
		email
		phone
		phoneStatus {
			isValid
			description
			carrierType
			countryCode
		}
		archived
		firstContact
		lastContact
		leadSource
		leadTypes
		leadTypeSlugs
		financingStatus
		financingStatusPrompted
		agentStatus
		agentStatusPrompted
		agentHasEngaged
		readiness
		contingencyStatus
		contingencyStatusPrompted
		timeframe
		isQualified
		sources
		primarySource
		notes
		chatbotMuted
		chatbotVoiceMuted
		appointment {
			availability
			type
		}
		listing {
			address
		}
		search {
			beds
			locations
			acres {
				min
				max
				approx
			}
			price {
				min
				max
				approx
			}
			squareFeet {
				min
				max
				approx
			}
			addresses {
				address
				mlsId
			}
			baths {
				min
				max
				approx
			}
		}
		tags {
			price
			features
			bedroom
			bathroom
			general
		}
		tasks {
			id
			name
			reminderAt
			isCompleted
			assignedAgent {
				id
				name
			}
		}
		chatbotHistory {
			data
			time
			type
			actor {
				agentId
				name
				source
				integration
			}
			message {
				id
				text
				role
				mediaUrl
				received
				source
				sender
				destination
				actions {
					type
					uri
					text
				}
			}
		}
		conversation {
			unreadCount
			givenName
			initialMessage {
				id
				text
				name
				source
				role
				avatarUrl
				mediaType
				mediaUrl
				received
				actions {
					id
					type
					uri
					text
					payload
				}
				media {
					id
					mediaType
					mediaUrl
				}
				sender
				destination
			}
			latestMessage {
				id
				text
				name
				source
				role
				avatarUrl
				mediaType
				mediaUrl
				received
				actions {
					id
					type
					uri
					text
					payload
				}
				sender
				destination
			}
		}
		scheduledActivity {
			id
			name
			subTitle
			scheduledAt
		}
		assignedAgent {
			id
			name
			email
			phone
			isBroker
			isTeamLead
			isLender
			totalCustomersCount
		}
		assignedLender {
			id
			name
			email
			phone
			isBroker
			isTeamLead
			isLender
			totalCustomersCount
		}
		assignedTeam {
			id
			name
			totalCustomersCount
		}
		owningEntityId {
			type
			id
		}
		owningEntity {
			id
			... on AgentType {
				team {
					id
				}
			}
		}
		stages
	}
`;

export const FRAGMENT_LEAD_LIST = gql`
	fragment LeadList on LeadListType {
		id
		name
		listType
		listPurpose
		sortMode
		remainingItems
		createdAt
		updatedAt
		pauseUntil
	}
`;

export { FRAGMENT_CUSTOMER_DATA, FRAGMENT_AGENT_DATA, FRAGMENT_TEAM_DATA };
