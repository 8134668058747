import React from 'react';

import { FiltersContext } from '../contexts';

const withFilters = Component => {
	const FiltersComponent = props => (
		<FiltersContext.Consumer>
			{filterData => {
				if (!filterData) return <Component />;
				const { filters, filterActions } = filterData;

				let newProps = {
					...props,
					...filters,
					...filterActions,
					filters,
					filterActions
				};

				return <Component {...newProps} />;
			}}
		</FiltersContext.Consumer>
	);

	return FiltersComponent;
};

export default withFilters;
