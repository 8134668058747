import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
	createTheme,
	ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { HashRouter } from 'react-router-dom';
import 'rc-slider/assets/index.css';
import { ThemeProvider } from 'styled-components';

import { createBlinkTheme } from 'modules/blink';
import MainLayout from './MainLayout';

import { UserContext, ActingUserContext, RoleContext } from '../contexts';
import { FiltersProvider, LeadFiltersProvider } from '../providers';

const materialUiTheme = createTheme({
	typography: {
		button: {
			textTransform: 'none'
		}
	},
	palette: {
		secondary: {
			main: '#00897b'
		}
	}
});

class App extends Component {
	constructor(props) {
		super(props);

		let isStructurelyAdmin = false;
		const jwt = props.user?.authJwt?.split('.');
		if (jwt && jwt.length === 3) {
			try {
				isStructurelyAdmin =
					JSON.parse(atob(jwt[1]))?.scope === 'admin';
			} catch {
				// Noop
			}
		}

		this.state = {
			user: props.user,
			actingUser: null,
			role: 'agency',
			isStructurelyAdmin
		};

		this.updateUser = this.updateUser.bind(this);
		this.updateActingUser = this.updateActingUser.bind(this);
		this.updateRole = this.updateRole.bind(this);
	}

	static propTypes = {
		client: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired
	};

	updateUser(user) {
		this.setState({ user });
	}

	updateActingUser(user) {
		this.setState({ actingUser: user });
	}

	updateRole(role) {
		this.setState({ role });
	}

	render() {
		const { client } = this.props;
		const { user, actingUser, role, isStructurelyAdmin } = this.state;

		return (
			<ApolloProvider client={client}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<MuiThemeProvider theme={materialUiTheme}>
						<ThemeProvider theme={createBlinkTheme()}>
							<HashRouter>
								<ActingUserContext.Provider
									value={{
										actingUser,
										updateActingUser: this.updateActingUser
									}}
								>
									<UserContext.Provider
										value={{
											user,
											updateUser: this.updateUser
										}}
									>
										<RoleContext.Provider
											value={{
												role,
												updateRole: this.updateRole
											}}
										>
											<FiltersProvider>
												<LeadFiltersProvider>
													<MainLayout
														isStructurelyAdmin={
															isStructurelyAdmin
														}
													/>
												</LeadFiltersProvider>
											</FiltersProvider>
										</RoleContext.Provider>
									</UserContext.Provider>
								</ActingUserContext.Provider>
							</HashRouter>
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</ApolloProvider>
		);
	}
}

export default App;
