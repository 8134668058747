import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'modules/core/helpers';
import { Progress } from 'modules/core/components';
import SetupItem from './SetupItem.jsx';

const SetupProgress = ({
	hasPhone,
	hasEmail,
	hasLiveChat,
	hasTeamMembers,
	isLeader,
	plan,
	basePlanType
}) => {
	const getMax = () => {
		if (isLeader) {
			return 3;
		} else {
			return 2;
		}
	};

	const getValue = () => {
		let value = 0;

		if (isLeader && hasTeamMembers) value++;

		if (hasPhone) value++;

		if (hasEmail) value++;

		return value;
	};

	const getPercentage = () => {
		let max = getMax();
		let value = getValue();

		return `${(value / max).toFixed(2) * 100}% Complete`;
	};

	const renderPlan = () => {
		const planName = basePlanType
			? `${basePlanType.name} - ${basePlanType.billingInterval}`
			: plan;
		if (!planName) return null;

		return (
			<div className="plan-info">
				<span className="label">Your Plan</span>
				<span className="pill success-pill">
					{capitalize(planName)}
				</span>
			</div>
		);
	};

	const renderTeamMembers = () => {
		if (!isLeader) return null;

		return (
			<SetupItem
				info="Invite team members to hire AI assistant for themselves"
				path="/enterprise/agents"
				isCompleted={hasTeamMembers}
			/>
		);
	};

	return (
		<div className="setup-progress">
			<div className="section-header">
				<h1 className="title">Setup</h1>
			</div>
			<div className="section-content paper">
				<div className="setup-header">
					<div className="progress-stats">
						<h4 className="stats-title">Progress</h4>
						<div className="progress-wrapper">
							<Progress
								height="6px"
								bgColor="#dcefee"
								max={getMax()}
								value={getValue()}
							/>
						</div>
						<span className="stats-value">{getPercentage()}</span>
					</div>
					{renderPlan()}
				</div>
				<ul className="setup-list">
					<SetupItem
						info="Get your local AI assistant Phone Number"
						path="/sources/sms"
						isCompleted={hasPhone}
					/>
					<SetupItem
						info="Connect your Lead Sources to your AI assistant Forwarding Email Address"
						path="/sources/email"
						isCompleted={hasEmail}
					/>
					{renderTeamMembers()}
				</ul>
			</div>
		</div>
	);
};

SetupProgress.propTypes = {
	hasPhone: PropTypes.bool,
	hasEmail: PropTypes.bool,
	hasLiveChat: PropTypes.bool,
	hasTeamMembers: PropTypes.bool,
	isLeader: PropTypes.bool,
	plan: PropTypes.string,
	basePlanType: PropTypes.object
};

SetupProgress.defaultProps = {
	hasPhone: false,
	hasEmail: false,
	hasLiveChat: false,
	hasTeamMembers: false,
	isLeader: false,
	plan: '',
	basePlanType: undefined
};

export default SetupProgress;
