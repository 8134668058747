import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import pako from 'pako';
import base64url from 'base64url';

import { time, logicalOperators, comparisonOperators } from './globals';

const getTimeAgo = messageTime => {
	if (!messageTime) return '';

	const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
	let currTime = new Date().getTime();
	messageTime = moment(messageTime).add(timezoneOffset, 'hours');
	let timeAgo = currTime - messageTime.valueOf();
	let timeAgoLabel;

	switch (true) {
		case timeAgo < time.SEC:
			timeAgo = '';
			timeAgoLabel = 'now';
			break;
		case timeAgo < time.MIN:
			timeAgo = Math.floor(timeAgo / time.SEC);
			timeAgoLabel = 'sec';
			break;
		case timeAgo < time.HOUR:
			timeAgo = Math.floor(timeAgo / time.MIN);
			timeAgoLabel = 'min';
			break;
		case timeAgo < time.DAY:
			timeAgo = Math.floor(timeAgo / time.HOUR);
			timeAgoLabel = timeAgo === 1 ? 'hr' : 'hrs';
			break;
		case timeAgo < time.WEEK:
			timeAgo = Math.floor(timeAgo / time.DAY);
			timeAgoLabel = timeAgo === 1 ? 'day' : 'days';
			break;
		case timeAgo < time.MONTH:
			timeAgo = Math.floor(timeAgo / time.WEEK);
			timeAgoLabel = timeAgo === 1 ? 'week' : 'weeks';
			break;
		case timeAgo < time.YEAR:
			timeAgo = Math.floor(timeAgo / time.MONTH);
			timeAgoLabel = timeAgo === 1 ? 'month' : 'months';
			break;
		default:
			timeAgo = Math.floor(timeAgo / time.YEAR);
			timeAgoLabel = timeAgo === 1 ? 'year' : 'years';
			break;
	}

	return timeAgo + ' ' + timeAgoLabel;
};

const getFormattedPhoneNumber = phone => {
	if (!phone) {
		return '';
	}

	let baseNumber = phone;

	if (baseNumber.startsWith('+1')) {
		baseNumber = baseNumber.slice(2);
	}

	baseNumber = baseNumber.replace(/\D/g, '');
	if (!baseNumber) {
		return '';
	} else if (baseNumber.length > 10) {
		baseNumber = baseNumber.slice(baseNumber.length - 10);
	}

	let areaCode = '';
	let firstDigits = '';
	let lastDigits = '';

	if (baseNumber.length < 3) {
		areaCode = baseNumber.substring(0, baseNumber.length);
		return `(${areaCode}`;
	} else if (baseNumber.length >= 3 && baseNumber.length < 7) {
		areaCode = baseNumber.substring(0, 3);
		firstDigits = baseNumber.substring(3, baseNumber.length);
		return `(${areaCode}) ${firstDigits}`;
	} else if (baseNumber.length >= 7) {
		areaCode = baseNumber.substring(0, 3);
		firstDigits = baseNumber.substring(3, 6);
		lastDigits = baseNumber.substring(6, baseNumber.length);
		return `(${areaCode}) ${firstDigits}-${lastDigits}`;
	} else {
		return baseNumber;
	}
};

const getFormattedCurrency = value =>
	`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const getMomentDate = received => {
	const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

	return moment.utc(received).utcOffset(timezoneOffset);
};

/**
 * Handles display of the lead name. It uses the following
 * order: Name > phone > email > messageName > Anonymous
 * @param {String} name The name of the lead
 * @param {String} messageName The name given by AI for the lead
 * @param {String} phone The phone number for the lead
 * @param {String} email The email for the lead
 */
const getLeadName = (name, messageName, phone, email) => {
	phone = getFormattedPhoneNumber(phone);
	let leadName = name || phone;
	leadName = leadName || email;
	leadName = leadName || messageName;
	leadName = leadName || 'Anonymous';

	return leadName;
};

const compareStrings = (a, b) => {
	if (typeof a === 'string' && typeof b === 'string') {
		let strA = a.toLowerCase().trim();
		let strB = b.toLowerCase().trim();
		let array1 = strA.split(' ');
		let array2 = strB.split(' ');

		if (array1.length === array2.length) {
			return strA.startsWith(strB);
		} else if (array1.length > array2.length) {
			for (let str1 of array1) {
				for (let str2 of array2) {
					if (str1.startsWith(str2)) {
						return true;
					}
				}
			}
		}
	}

	return false;
};

const flattenArray = (arr = []) => {
	let ret = [];
	for (let a of arr) {
		if (Array.isArray(a)) {
			ret = ret.concat(flattenArray(a));
		} else {
			ret.push(a);
		}
	}

	return ret;
};

const getUniqueArray = (arr = []) => {
	return arr.filter(
		(el, index, self) => self.findIndex(t => t.id === el.id) === index
	);
};

const showPopover = (message, type) => {
	if (type === 'success') {
		NotificationManager.success(message, 'Success', 15000);
	} else {
		NotificationManager.error(message, 'Error', 15000);
	}
};

const showError = (error, exception) => {
	if (error && error.graphQLErrors) {
		error.graphQLErrors.forEach(error => {
			if (error.message !== exception) {
				showPopover(error.message, 'error');
			}
		});
	}
};

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const parseToken = accessToken => {
	let isCompressed = false;

	if (accessToken.charAt(0) === '.') {
		isCompressed = true;
		accessToken = accessToken.substr(1);
	}

	accessToken = accessToken.split('.')[0];

	/**
	 * Convert base64url to base64 (Note: base64url.decode is not working,
	 * as is typical of a library who banks on solving the whole problem
	 * of decoding base64url)
	 **/
	let base64data = base64url.toBase64(accessToken);
	let decodedData = window.atob(base64data);

	if (isCompressed) {
		/**
		 * Decode the zlib data by turning the string converted
		 * from base64url to a character code int array,
		 * then uncompress with pako and turn back into as string.
		 **/
		decodedData = decodedData.split('').map(function(e) {
			return e.charCodeAt(0);
		});

		let binData = new Uint8Array(decodedData);
		let data = pako.inflate(binData);
		return JSON.parse(
			String.fromCharCode.apply(null, new Uint16Array(data))
		);
	}

	return JSON.parse(decodedData);
};

const getCustomers = entity => {
	let customers = [];

	if (entity) {
		const addCustomers = givenCustomers => {
			if (givenCustomers) {
				customers.push(...givenCustomers);
			}
		};

		addCustomers(entity.customers);

		customers = getUniqueArray(
			customers.filter(customer => customer.conversation)
		);
	}

	return customers;
};

const useWindowSize = () => {
	const isClient = typeof window === 'object';

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};
	}

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return windowSize;
};

const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};

const usePrevious = value => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const isLogicalOperator = str => {
	return Object.values(logicalOperators).includes(str);
};

const isComparisonOperator = str => {
	return Object.values(comparisonOperators).includes(str);
};

const getFillCondition = (slotName, fillType) => {
	if (!fillType || !slotName) return null;

	if (fillType === 'fill_slot') {
		return JSON.stringify({
			[slotName]: { [comparisonOperators.NE]: null }
		});
	} else if (fillType === 'cancel_slot') {
		return JSON.stringify({
			[slotName]: { [comparisonOperators.EQ]: null }
		});
	} else {
		return null;
	}
};

const getEntity = user => {
	const { agentId, teamId, brokerageId, isBroker, isTeamLead } = user;

	let id;
	let collection;
	if (brokerageId && isBroker) {
		id = brokerageId;
		collection = 'BROKERAGES';
	} else if (teamId && isTeamLead) {
		id = teamId;
		collection = 'TEAMS';
	} else if (agentId) {
		id = agentId;
		collection = 'AGENTS';
	}

	return {
		id,
		collection
	};
};

const useEntityQuery = (
	AGENT_QUERY,
	TEAM_QUERY,
	BROKERAGE_QUERY,
	user,
	options = {}
) => {
	const {
		agentId,
		teamId,
		brokerageId,
		isBroker,
		isTeamLead,
		isIndependentAgent
	} = user;

	const { variables = {} } = options;

	const {
		loading: loadingAgentData,
		data: agentData,
		error: agentError,
		refetch: agentRefetch,
		fetchMore: agentFetchMore
	} = useQuery(AGENT_QUERY, {
		...options,
		variables: { id: agentId, ...variables },
		skip: !agentId || isBroker || isTeamLead
	});
	const {
		loading: loadingTeamData,
		data: teamData,
		error: teamError,
		refetch: teamRefetch,
		fetchMore: teamFetchMore
	} = useQuery(TEAM_QUERY, {
		...options,
		variables: { id: teamId, ...variables },
		skip: !teamId || !isTeamLead || isBroker || isIndependentAgent
	});
	const {
		loading: loadingBrokerageData,
		data: brokerageData,
		error: brokerageError,
		refetch: brokerageRefetch,
		fetchMore: brokerageFetchMore
	} = useQuery(BROKERAGE_QUERY, {
		...options,
		variables: { id: brokerageId, ...variables },
		skip: !brokerageId || !isBroker || isIndependentAgent
	});

	let loading;
	let data;
	let error;
	let refetch;
	let fetchMore;

	if (agentId && !isBroker && !isTeamLead) {
		loading = loadingAgentData;
		error = agentError;
		refetch = agentRefetch;
		fetchMore = agentFetchMore;
		data = agentData && agentData.agent;
	} else if (teamId && isTeamLead && !isBroker && !isIndependentAgent) {
		loading = loadingTeamData;
		error = teamError;
		refetch = teamRefetch;
		fetchMore = teamFetchMore;
		data = teamData && teamData.team;
	} else if (brokerageId && isBroker && !isIndependentAgent) {
		loading = loadingBrokerageData;
		error = brokerageError;
		refetch = brokerageRefetch;
		fetchMore = brokerageFetchMore;
		data = brokerageData && brokerageData.brokerage;
	}

	return {
		loading,
		error,
		data,
		refetch,
		fetchMore
	};
};

const useEntityMutation = (
	AGENT_MUTATION,
	TEAM_MUTATION,
	BROKERAGE_MUTATION,
	user,
	options = {}
) => {
	const { agentId, teamId, brokerageId, isBroker, isTeamLead } = user;
	const {
		agentOptions = {},
		teamOptions = {},
		brokerageOptions = {}
	} = options;
	const { variables = {} } = options;

	const [mutateAgent, agentMutationData] = useMutation(AGENT_MUTATION, {
		...agentOptions,
		variables: {
			id: agentId,
			...variables
		}
	});
	const [mutateTeam, teamMutationData] = useMutation(TEAM_MUTATION, {
		...teamOptions,
		variables: {
			id: teamId,
			...variables
		}
	});
	const [mutateBrokerage, brokerageMutationData] = useMutation(
		BROKERAGE_MUTATION,
		{
			...brokerageOptions,
			variables: {
				id: brokerageId,
				...variables
			}
		}
	);

	let mutationData;
	if (isBroker && brokerageId) {
		mutationData = [mutateBrokerage, brokerageMutationData];
	} else if (isTeamLead && teamId) {
		mutationData = [mutateTeam, teamMutationData];
	} else if (!isBroker && !isTeamLead && agentId) {
		mutationData = [mutateAgent, agentMutationData];
	} else {
		mutationData = [null, null];
	}

	return mutationData;
};

const getDripLabel = dripName => {
	if (!dripName) return '';

	return dripName
		.replace(/_/g, ' ')
		.split(' ')
		.map(x => capitalize(x))
		.join(' ');
};

const getDomains = domainsData => {
	if (
		!domainsData ||
		!domainsData.settings ||
		!domainsData.settings.chatbotDomains
	)
		return [];

	return domainsData.settings.chatbotDomains;
};

const getChannelLabel = channel => {
	switch (channel) {
		case 'TWILIO':
			return 'SMS';
		case 'MAILGUN':
			return 'Email';
		case 'DEFAULT':
			return 'None';
		default:
			return 'None';
	}
};

const getCursorLastContact = customers => {
	if (!Array.isArray(customers) && customers.length === 0) return;

	return customers[customers.length - 1].lastContact;
};

const getLeadTypeLabel = leadTypes => {
	if (leadTypes.length > 0) {
		return leadTypes.join(', ');
	}

	return 'Unknown';
};

export {
	getTimeAgo,
	getFormattedPhoneNumber,
	getFormattedCurrency,
	getMomentDate,
	getLeadName,
	compareStrings,
	flattenArray,
	getUniqueArray,
	showPopover,
	showError,
	capitalize,
	parseToken,
	getCustomers,
	getFillCondition,
	isComparisonOperator,
	isLogicalOperator,
	usePrevious,
	useInterval,
	useWindowSize,
	getEntity,
	useEntityQuery,
	useEntityMutation,
	getDripLabel,
	getDomains,
	getChannelLabel,
	getCursorLastContact,
	getLeadTypeLabel
};
