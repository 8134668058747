import React from 'react';

import SimpleSvg from './SimpleSvg';

const StarUser = props => (
	<SimpleSvg
		d="M 12 3 C 9.8027056 3 8 4.8027056 8 7 C 8 9.1972944 9.8027056 11 12 11 C 14.197294 11 16 9.1972944 16 7 C 16 4.8027056 14.197294 3 12 3 z M 12 14 C 8.859 14 3 15.545 3 18.5 L 3 20 C 3 20.552 3.448 21 4 21 L 14.185547 21 L 12.96875 19.947266 L 11.380859 18.572266 C 10.635859 17.927266 11.033625 16.703141 12.015625 16.619141 L 14.107422 16.441406 L 16.310547 16.253906 L 16.902344 14.855469 C 15.221344 14.289469 13.357 14 12 14 z M 19.011719 15.558594 C 18.877969 15.558594 18.745094 15.631844 18.683594 15.777344 L 17.683594 18.142578 L 15.121094 18.361328 C 14.806094 18.388328 14.678969 18.781281 14.917969 18.988281 L 16.859375 20.669922 L 16.275391 23.175781 C 16.204391 23.483781 16.537594 23.7255 16.808594 23.5625 L 19.011719 22.234375 L 21.216797 23.5625 C 21.486797 23.7265 21.819047 23.483781 21.748047 23.175781 L 21.164062 20.662109 L 23.107422 18.980469 C 23.346422 18.773469 23.217344 18.382469 22.902344 18.355469 L 20.341797 18.136719 L 19.341797 15.777344 C 19.280297 15.631844 19.145469 15.558594 19.011719 15.558594 z"
		{...props}
	/>
);

export default StarUser;
