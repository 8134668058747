import React, { lazy, Suspense, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router';
import { NotificationContainer } from 'react-notifications';
import { useSubscription, useQuery } from '@apollo/client';

import { Spinner } from 'modules/blink/components';
import DashboardLayout from 'modules/dashboard/containers/DashboardLayout.js';
import NavLayout from 'modules/navigation/components/NavLayout.jsx';

import Header from '../components/Header.jsx';
import UrlHandler from '../containers/UrlHandler.js';
import Intercom from '../components//Intercom.jsx';
import Upscope from '../components//Upscope.jsx';
import ProtectedRoute from '../components/ProtectedRoute.jsx';
import { CreateLeadsModal } from '../components/CreateLeadsModal.jsx';
import { UploadLeadsModal } from '../components/UploadLeadsModal.jsx';
import {
	MailgunContext,
	MultiConversationsContext,
	SchedulingContext,
	VoiceChannelsContext,
	CreateLeadContext,
	FeatureFlagContext
} from '../contexts';
import { withUser } from '../providers';
import { CacheManager } from '../classes';
import {
	QUERY_AGENT_MAIN_INFO,
	QUERY_TEAM_MAIN_INFO,
	QUERY_BROKERAGE_MAIN_INFO,
	QUERY_AGENT_CUSTOMERS,
	QUERY_TEAM_CUSTOMERS,
	QUERY_BROKERAGE_CUSTOMERS,
	QUERY_BUILTIN_TAGS
} from '../queries';
import {
	AGENT_CUSTOMER_SUBSCRIPTION,
	TEAM_CUSTOMER_SUBSCRIPTION,
	BROKERAGE_CUSTOMER_SUBSCRIPTION,
	AGENT_UNREAD_COUNT_SUBSCRIPTION
} from '../subscriptions';

const EnterpriseLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/enterprise/components/EnterpriseLayout.jsx'
	)
);
const HolmesLayout = lazy(() =>
	import(/* webpackPrefetch: true */ 'modules/holmes/components/HolmesLayout')
);
const InboxLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/inbox/components/InboxLayout.jsx'
	)
);
const DialerLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/dialer/containers/DialerLayout.js'
	)
);
const IntentLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/holmes/modules/intents/components/IntentLayout'
	)
);
const LeadsLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/leads/components/LeadsLayout.jsx'
	)
);
const LeadProfile = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/leads/components/LeadProfile.jsx'
	)
);
const ReportingLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/reporting/containers/ReportingLayout.js'
	)
);
const CustomReportLayout = lazy(() => {
	return import(
		/* webpackPrefetch: true */ 'modules/customReporting/containers/CustomReportLayout.js'
	);
});
const RoutingLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/routing/components/RoutingLayout.jsx'
	)
);
const ScriptLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/holmes/modules/scripts/components/ScriptLayout'
	)
);
const SettingsLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/settings/components/SettingsLayout.jsx'
	)
);
const SourcesLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/sources/components/SourcesLayout.jsx'
	)
);
const SourceLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/sources/components/SourceLayout.jsx'
	)
);
const LeadListsLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/leadLists/containers/LeadListsLayout.js'
	)
);
const DevSlotsLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/devMode/components/DevSlotsLayout.jsx'
	)
);
const AdminToolsLayout = lazy(() =>
	import(
		/* webpackPrefetch: true */ 'modules/adminTools/containers/AdminToolsLayout.js'
	)
);

function MainLayout(props) {
	const {
		agentId,
		teamId,
		brokerageId,
		isLeader,
		isBroker,
		isTeamLead,
		isIndependentAgent,
		location,
		isStructurelyAdmin
	} = props;

	const [showCreateLeadModal, setCreateLeadModal] = useState(false);
	const [showUploadLeadsModal, setUploadLeadsModal] = useState(false);

	const showCreateLead = useCallback(() => {
		setCreateLeadModal(true);
	}, [setCreateLeadModal]);

	const hideCreateLead = useCallback(() => {
		setCreateLeadModal(false);
	}, [setCreateLeadModal]);

	const showUploadLeads = useCallback(() => {
		setUploadLeadsModal(true);
	}, [setUploadLeadsModal]);

	const hideUploadLeads = useCallback(() => {
		setUploadLeadsModal(false);
	}, [setUploadLeadsModal]);

	const { data: agentData, loading: loadingAgentData } = useQuery(
		QUERY_AGENT_MAIN_INFO,
		{
			skip: !agentId,
			variables: {
				agentId
			}
		}
	);
	const { data: teamData, loading: loadingTeamData } = useQuery(
		QUERY_TEAM_MAIN_INFO,
		{
			skip: !teamId,
			variables: {
				teamId
			}
		}
	);
	const { data: brokerageData, loading: loadingBrokerageData } = useQuery(
		QUERY_BROKERAGE_MAIN_INFO,
		{
			skip: !brokerageId,
			variables: {
				brokerageId
			}
		}
	);

	const [dialerStatus, setDialerStatus] = useState(null);

	let SUBSCRIPTION;
	let QUERY;
	let entity;
	let id;
	if (isBroker) {
		SUBSCRIPTION = BROKERAGE_CUSTOMER_SUBSCRIPTION;
		QUERY = QUERY_BROKERAGE_CUSTOMERS;
		entity = 'brokerage';
		id = brokerageId;
		if (!id) {
			console.error(
				'User is marked as a broker, but does not have a brokerageId'
			);
		}
	} else if (isTeamLead) {
		SUBSCRIPTION = TEAM_CUSTOMER_SUBSCRIPTION;
		QUERY = QUERY_TEAM_CUSTOMERS;
		entity = 'team';
		id = teamId;
		if (!id) {
			console.error(
				'User is marked as a teamLead, but does not have a teamId'
			);
		}
	} else {
		SUBSCRIPTION = AGENT_CUSTOMER_SUBSCRIPTION;
		QUERY = QUERY_AGENT_CUSTOMERS;
		entity = 'agent';
		id = agentId;
		if (!id) {
			console.error(
				'User is marked as an agent, but does not have an agentId'
			);
		}
	}
	// eslint-disable-next-line
	const { data: entityCustomerUpdatesSubscriptionData } = useSubscription(
		SUBSCRIPTION,
		{
			skip: !id,
			variables: {
				id
			},
			onSubscriptionData: options => {
				const { client, subscriptionData } = options;

				if (
					!subscriptionData.data ||
					!subscriptionData.data[`${entity}CustomerUpdates`] ||
					!subscriptionData.data[`${entity}CustomerUpdates`].customer
				) {
					return;
				}

				const { customer } = subscriptionData.data[
					`${entity}CustomerUpdates`
				];
				let cacheManager = new CacheManager({
					client,
					query: QUERY,
					entity: {
						type: entity,
						id
					}
				});

				cacheManager.update({
					customer,
					customerId: customer && customer.id
				});
			}
		}
	);

	// eslint-disable-next-line
	const { data: entityUnreadCountUpdatesSubscriptionData } = useSubscription(
		AGENT_UNREAD_COUNT_SUBSCRIPTION,
		{
			skip: !agentId,
			variables: {
				agentId
			},
			onSubscriptionData: options => {
				const { client, subscriptionData } = options;

				if (
					!subscriptionData.data ||
					!subscriptionData.data.agentUnreadCountUpdates
				) {
					return;
				}

				const {
					customerId,
					unreadCount
				} = subscriptionData.data.agentUnreadCountUpdates;
				let cacheManager = new CacheManager({
					client,
					query: QUERY,
					entity: {
						type: entity,
						id
					}
				});

				cacheManager.update({
					customerId,
					unreadCount
				});
			}
		}
	);

	const getAllowCalendlyScheduling = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowCalendlyScheduling;
		} else if (teamId) {
			return teamData?.team?.allowCalendlyScheduling;
		} else if (agentId) {
			return agentData?.agent?.allowCalendlyScheduling;
		}

		return false;
	};

	const getAllowCustomScripts = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowCustomScripts;
		} else if (teamId) {
			return teamData?.team?.allowCustomScripts;
		} else if (agentId) {
			return agentData?.agent?.allowCustomScripts;
		}

		return false;
	};

	const getAllowHardAppointmentScheduling = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowHardAppointmentScheduling;
		} else if (teamId) {
			return teamData?.team?.allowHardAppointmentScheduling;
		} else if (agentId) {
			return agentData?.agent?.allowHardAppointmentScheduling;
		}
		return false;
	};

	const getAllowIntercom = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowIntercom;
		} else if (teamId) {
			return teamData?.team?.allowIntercom;
		} else if (agentId) {
			return agentData?.agent?.allowIntercom;
		}

		return false;
	};

	const getAllowMailgun = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowMailgun;
		} else if (teamId) {
			return teamData?.team?.allowMailgun;
		} else if (agentId) {
			return agentData?.agent?.allowMailgun;
		}

		return false;
	};

	const getAllowMultiConversations = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowMultiConversations;
		} else if (teamId) {
			return teamData?.team?.allowMultiConversations;
		} else if (agentId) {
			return agentData?.agent?.allowMultiConversations;
		}
		return false;
	};

	const getAllowMultiSmsChannels = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowMultiSmsChannels;
		} else if (teamId) {
			return teamData?.team?.allowMultiSmsChannels;
		} else if (agentId) {
			return agentData?.agent?.allowMultiSmsChannels;
		}
		return false;
	};

	const getHasCustomReports = () => {
		return (
			!!brokerageData?.brokerage?.customReportCsvs?.length ||
			!!teamData?.team?.customReportCsvs?.length ||
			!!agentData?.agent?.customReportCsvs?.length ||
			false
		);
	};

	const getAllowPlatformDialer = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowPlatformDialer;
		} else if (teamId) {
			return teamData?.team?.allowPlatformDialer;
		} else if (agentId) {
			return agentData?.agent?.allowPlatformDialer;
		}
		return false;
	};

	const getAllowPlatformDialerExperimentalSettings = () => {
		if (brokerageId) {
			return brokerageData?.brokerage
				?.allowPlatformDialerExperimentalSettings;
		} else if (teamId) {
			return teamData?.team?.allowPlatformDialerExperimentalSettings;
		} else if (agentId) {
			return agentData?.agent?.allowPlatformDialerExperimentalSettings;
		}
		return false;
	};

	const getAllowMessagingChannels = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowMessagingChannels;
		} else if (teamId) {
			return teamData?.team?.allowMessagingChannels;
		} else if (agentId) {
			return agentData?.agent?.allowMessagingChannels;
		}
		return false;
	};

	const getAllowVoiceChannels = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.allowVoiceChannels;
		} else if (teamId) {
			return teamData?.team?.allowVoiceChannels;
		} else if (agentId) {
			return agentData?.agent?.allowVoiceChannels;
		}
		return false;
	};

	const getAllowAiVoiceAgents = () => {
		return (
			brokerageData?.brokerage?.allowAiVoiceAgents ||
			teamData?.team?.allowAiVoiceAgents ||
			agentData?.agent?.allowAiVoiceAgents ||
			false
		);
	};

	const getDigitalVoiceAssistants = () => {
		if (agentData?.agent?.digitalVoiceAssistants?.length) {
			return agentData.agent.digitalVoiceAssistants;
		}

		if (teamData?.team?.digitalVoiceAssistants?.length) {
			return teamData.team.digitalVoiceAssistants;
		}

		if (brokerageData?.brokerage?.digitalVoiceAssistants?.length) {
			return brokerageData.brokerage.digitalVoiceAssistants;
		}

		return undefined;
	};

	const getVoiceProviders = () => {
		if (agentData?.voiceProviders?.length) {
			return agentData.voiceProviders;
		}

		if (teamData?.voiceProviders?.length) {
			return teamData.voiceProviders;
		}

		if (brokerageData?.voiceProviders?.length) {
			return brokerageData.voiceProviders;
		}

		return undefined;
	};

	const getMultiConversationsCanBeActivated = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.multiConversationsCanBeActivated;
		} else if (teamId) {
			return teamData?.team?.multiConversationsCanBeActivated;
		} else if (agentId) {
			return agentData?.agent?.multiConversationsCanBeActivated;
		}
		return false;
	};

	const getChatbotDomains = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.settings?.chatbotDomains;
		} else if (teamId) {
			return teamData?.team?.settings?.chatbotDomains;
		} else if (agentId) {
			return agentData?.agent?.settings?.chatbotDomains;
		}
		return undefined;
	};

	const getCustomScripts = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.customScripts;
		} else if (teamId) {
			return teamData?.team?.customScripts;
		} else if (agentId) {
			return agentData?.agent?.customScripts;
		}
		return undefined;
	};

	const getCallDispositions = () => {
		if (brokerageId) {
			return brokerageData?.brokerage?.callDispositions;
		} else if (teamId) {
			return teamData?.team?.callDispositions;
		} else if (agentId) {
			return agentData?.agent?.callDispositions;
		}
		return undefined;
	};

	const getAgencyName = () => {
		let agencyName = '';
		if (brokerageId) {
			agencyName = brokerageData?.brokerage?.name;
		} else if (teamId) {
			agencyName = teamData?.team?.name;
		}

		return agencyName;
	};

	const chatbotDomains = getChatbotDomains();
	const { data: tagsData } = useQuery(QUERY_BUILTIN_TAGS, {
		variables: { domains: chatbotDomains },
		skip: !chatbotDomains
	});

	const renderLoading = () => {
		return (
			<div className="loading-page">
				<div>
					<div className="icon-lg-wrapper">
						<img
							alt="logo"
							src="/img/homechat-app-icon-lg-new.png"
							height="100px"
						/>
					</div>
					<h1 className="loading-text">Loading...</h1>
				</div>
			</div>
		);
	};

	const renderUpscope = () => {
		let agentEmail = agentData?.agent?.agentEmail;

		return process.env.NODE_ENV === 'production' ? (
			<Upscope email={agentEmail} agentId={agentId} />
		) : null;
	};

	const renderHeader = dialerMode => {
		if (!agentData || !agentData.agent) return null;
		const { name, clearbit } = agentData.agent;

		return (
			<Header
				agentName={name}
				agentAvatar={clearbit?.avatar || null}
				agencyName={getAgencyName()}
				dialerMode={dialerMode}
			/>
		);
	};

	if (loadingAgentData || loadingTeamData || loadingBrokerageData) {
		return renderLoading();
	}

	const allowMessagingChannels = getAllowMessagingChannels();
	const allowVoiceChannels = getAllowVoiceChannels();
	const allowAiVoiceAgents = getAllowAiVoiceAgents();
	const allowPlatformDialer = getAllowPlatformDialer();
	const allowPlatformDialerExperimentalSettings = getAllowPlatformDialerExperimentalSettings();
	const allowCustomScripts = getAllowCustomScripts();

	const customScripts = allowCustomScripts ? getCustomScripts() || [] : [];
	const builtinTags =
		tagsData?.builtinTags?.filter(t => !t.name.endsWith('Specific')) || [];

	const allUseCases = customScripts.map(c => ({
		...c,
		label: c.name,
		value: `CS:${c.id}`,
		customScript: true,
		domains: [],
		leadTypes: []
	}));

	builtinTags?.forEach(t => {
		allUseCases.push({
			...t,
			label: t.name,
			customScript: false
		});
	});

	const hasDialer =
		!!agentData?.agent?.twilioVoiceGrant &&
		allowVoiceChannels &&
		allowPlatformDialer;

	const viewDialer = hasDialer && location?.pathname?.startsWith('/dialer');

	const voiceProviders = getVoiceProviders();
	const defaultVoiceProvider = voiceProviders?.find(p => p.isDefault);

	return (
		<FeatureFlagContext.Provider
			value={{
				allowCalendlyScheduling: getAllowCalendlyScheduling(),
				allowCustomScripts,
				allowHardAppointmentScheduling: getAllowHardAppointmentScheduling(),
				allowIntercom: getAllowIntercom(),
				allowMailgun: getAllowMailgun(),
				allowMultiConversations: getAllowMultiConversations(),
				allowMultiSmsChannels: getAllowMultiSmsChannels(),
				allowPlatformDialer,
				allowPlatformDialerExperimentalSettings,
				allowMessagingChannels,
				allowVoiceChannels,
				allowAiVoiceAgents,
				multiConversationsCanBeActivated: getMultiConversationsCanBeActivated(),
				chatbotDomains: [...(chatbotDomains || [])].sort(),
				allUseCases,
				builtinTags,
				customScripts,
				isStructurelyAdmin
			}}
		>
			<MailgunContext.Provider
				value={{ allowMailgun: getAllowMailgun() }}
			>
				<CreateLeadContext.Provider
					value={{
						createLead: showCreateLeadModal,
						uploadLeads: showUploadLeadsModal,
						showCreateLead,
						hideCreateLead,
						showUploadLeads,
						hideUploadLeads
					}}
				>
					<SchedulingContext.Provider
						value={{
							allowHardAppointmentScheduling: getAllowHardAppointmentScheduling()
						}}
					>
						<MultiConversationsContext.Provider
							value={{
								allowMultiConversations: getAllowMultiConversations()
							}}
						>
							<VoiceChannelsContext.Provider
								value={{
									allowVoiceChannels,
									allowAiVoiceAgents,
									allowPlatformDialer,
									allowPlatformDialerExperimentalSettings: allowPlatformDialerExperimentalSettings,
									callDispositions: getCallDispositions(),
									digitalVoiceAssistants: getDigitalVoiceAssistants(),
									voiceProviders,
									defaultVoiceProvider,
									twilioVoiceGrant:
										agentData?.agent?.twilioVoiceGrant,
									dialerStatus: dialerStatus,
									setDialerStatus: setDialerStatus
								}}
							>
								{viewDialer && (
									<div className="dialer-layout">
										<div className="dialer-main-layout">
											<div>{renderHeader(true)}</div>
											<Suspense
												fallback={
													<Spinner
														kind="primary"
														tint={true}
													/>
												}
											>
												<Switch>
													<Route
														path="/dialer"
														component={DialerLayout}
													/>
												</Switch>
											</Suspense>
										</div>
										<NotificationContainer />
									</div>
								)}
								{!viewDialer && (
									<div className="page-layout">
										<NavLayout
											hasDialer={hasDialer}
											hasReports={getHasCustomReports()}
											isStructurelyAdmin={
												isStructurelyAdmin
											}
										/>
										<div className="main-layout">
											{renderHeader()}
											<Suspense
												fallback={
													<Spinner
														kind="primary"
														tint={true}
													/>
												}
											>
												<Switch>
													<Route
														exact
														path="/"
														component={
															DashboardLayout
														}
													/>
													{isStructurelyAdmin && (
														<Route
															exact
															path="/admin-tools"
															component={
																AdminToolsLayout
															}
														/>
													)}
													<Route
														exact
														path="/inbox"
														component={InboxLayout}
													/>
													<Route
														path="/inbox/:customerId"
														component={InboxLayout}
													/>
													<Route
														exact
														path="/leads"
														component={LeadsLayout}
													/>
													<Route
														exact
														path="/leads/reload"
														component={LeadsLayout}
													/>
													<Route
														exact
														path="/leads/:customerId"
														component={LeadProfile}
													/>
													<Route
														exact
														path="/sources"
														component={
															SourcesLayout
														}
													/>
													<Route
														exact
														path="/sources/:source"
														component={SourceLayout}
													/>
													<Route
														exact
														path="/sources/emailChannel/domains/:domainName"
														component={SourceLayout}
													/>
													<Route
														path="/ai"
														component={HolmesLayout}
													/>
													<Route
														exact
														path="/defaultAnswers/:intentId"
														component={IntentLayout}
													/>
													<Route
														exact
														path="/answers/:intentId"
														component={IntentLayout}
													/>
													<Route
														exact
														path="/defaultScripts/:scriptId"
														component={ScriptLayout}
													/>
													<Route
														exact
														path="/scripts/:scriptId"
														component={ScriptLayout}
													/>
													<ProtectedRoute
														exact
														path="/enterprise"
														component={
															EnterpriseLayout
														}
														isAuthenticated={
															isLeader
														}
													/>
													<ProtectedRoute
														exact
														path="/enterprise/:entity"
														component={
															EnterpriseLayout
														}
														isAuthenticated={
															isLeader
														}
													/>
													<Route
														exact
														path="/reporting"
														render={props => (
															<ReportingLayout
																{...props}
															/>
														)}
													/>
													<Route
														exact
														path="/custom-reports"
														component={
															CustomReportLayout
														}
													/>
													<ProtectedRoute
														path="/routing"
														render={props => (
															<RoutingLayout
																{...props}
															/>
														)}
														isAuthenticated={
															isLeader ||
															isIndependentAgent
														}
													/>
													<Route
														path="/settings"
														component={
															SettingsLayout
														}
													/>
													<Route
														path="/lead-uploads"
														component={
															LeadListsLayout
														}
													/>
													<Route
														exact
														path="/dev-mode/slots"
														component={
															DevSlotsLayout
														}
													/>
												</Switch>
											</Suspense>
										</div>
										<NotificationContainer />
										{!!getAllowIntercom() && <Intercom />}
										<UrlHandler />
										{renderUpscope()}
										<CreateLeadsModal
											isOpen={showCreateLeadModal}
											onClose={hideCreateLead}
										/>
										<UploadLeadsModal
											isOpen={showUploadLeadsModal}
											onClose={hideUploadLeads}
										/>
									</div>
								)}
							</VoiceChannelsContext.Provider>
						</MultiConversationsContext.Provider>
					</SchedulingContext.Provider>
				</CreateLeadContext.Provider>
			</MailgunContext.Provider>
		</FeatureFlagContext.Provider>
	);
}

MainLayout.propTypes = {
	agentId: PropTypes.string,
	brokerageId: PropTypes.string,
	teamId: PropTypes.string,
	isTeamLead: PropTypes.bool,
	isBroker: PropTypes.bool,
	isLeader: PropTypes.bool,
	isIndependentAgent: PropTypes.bool,
	location: PropTypes.object,
	isStructurelyAdmin: PropTypes.bool
};

MainLayout.defaultProps = {
	agentId: '',
	teamId: '',
	brokerageId: '',
	isTeamLead: false,
	isBroker: false,
	isLeader: false,
	isIndependentAgent: false,
	location: undefined,
	isStructurelyAdmin: false
};

export default withRouter(withUser(MainLayout));
