import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ChevronDown } from 'modules/blink/icons';

class Select extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false
		};
	}

	static propTypes = {
		value: PropTypes.string,
		label: PropTypes.string,
		dropdownLabel: PropTypes.string,
		tabIndex: PropTypes.string,
		placeholder: PropTypes.string,
		options: PropTypes.array,
		onChange: PropTypes.func.isRequired
	};

	static defaultProps = {
		value: '',
		label: '',
		dropdownLabel: '',
		placeholder: 'Select option',
		options: [],
		tabIndex: '0'
	};

	toggleDropdown(status) {
		this.setState({ showDropdown: status });
	}

	selectOption(option) {
		const { onChange } = this.props;

		onChange(option);
		this.toggleDropdown(false);
	}

	renderDropdown() {
		const { options, dropdownLabel } = this.props;

		let dropdownOptions = null;
		if (options.length === 0) {
			dropdownOptions = (
				<li className="option null">
					<span>No options</span>
				</li>
			);
		} else {
			dropdownOptions = options.map((option, index) => (
				<li
					key={index}
					className="option"
					onClick={() => this.selectOption(option)}
				>
					<p
						title={option.label}
						className="option-label"
						style={option.style}
					>
						{option.label}
					</p>
				</li>
			));
		}

		if (dropdownLabel) {
			return (
				<div className="dropdown-content">
					<ul className="options-list">
						<li className="options-group">
							<span className="group-title">{dropdownLabel}</span>
							<ul>{dropdownOptions}</ul>
						</li>
					</ul>
				</div>
			);
		} else {
			return (
				<div className="dropdown-content">
					<ul className="options-list">{dropdownOptions}</ul>
				</div>
			);
		}
	}

	render() {
		const { value, options, label, tabIndex, placeholder } = this.props;
		const { showDropdown } = this.state;

		let currValue = placeholder;
		let currOption = undefined;
		if (value) {
			currOption = options.find(option => {
				return option.value === value;
			});

			currValue = currOption ? currOption.label : placeholder;
		}

		return (
			<div className="input-wrapper">
				{label ? <span className="label">{label}</span> : null}
				<div
					className="dropdown select"
					tabIndex={tabIndex}
					onBlur={() => this.toggleDropdown(false)}
					onFocus={() => this.toggleDropdown(true)}
				>
					<div
						className="current-value"
						onClick={() => this.toggleDropdown(true)}
					>
						<p title={currValue} style={currOption?.style}>
							{currValue}
						</p>
						<ChevronDown className="icon" />
					</div>
					{showDropdown && this.renderDropdown()}
				</div>
			</div>
		);
	}
}

export default Select;
