import React from 'react';

import SimpleSvg from './SimpleSvg';

const Bed = props => (
	<SimpleSvg
		d="M 3 4 C 2.448 4 2 4.448 2 5 L 2 19 C 2 19.552 2.448 20 3 20 C 3.552 20 4 19.552 4 19 L 4 18 L 20 18 L 20 19 C 20 19.552 20.448 20 21 20 C 21.552 20 22 19.552 22 19 L 22 11 C 22 9.895 21.105 9 20 9 L 12 9 C 10.895 9 10 9.895 10 11 L 10 12 L 7 12 L 4 12 L 4 5 C 4 4.448 3.552 4 3 4 z M 7 12 A 2 2 0 0 0 9 10 A 2 2 0 0 0 7 8 A 2 2 0 0 0 5 10 A 2 2 0 0 0 7 12 z"
		{...props}
	/>
);

export default Bed;
