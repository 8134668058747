import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import {
	Help as HelpIcon,
	Settings as SettingsIcon
} from 'modules/blink/icons';
import NavLink from './NavLink.jsx';
import { withUser } from 'modules/core/providers';

const NavFooter = ({ isLeader, isIndependentAgent, expanded, location }) => {
	const renderPlanBtn = () => {
		if (!isLeader && !isIndependentAgent) return null;

		return (
			<li>
				<div className="btn-wrapper row">
					{expanded ? (
						<Link
							to="/settings/billing"
							className="btn success-btn fill"
						>
							Your Plan
						</Link>
					) : null}
				</div>
			</li>
		);
	};

	return (
		<ul className="nav-footer">
			{renderPlanBtn()}
			<NavLink title="Help" expanded={expanded}>
				<a
					href="http://help.structurely.com/"
					target="_blank"
					rel="noopener noreferrer"
					className="nav-link"
				>
					<span className="link-wrapper row align-center">
						<HelpIcon className="icon" />
						{expanded ? (
							<span className="link-title">Help</span>
						) : null}
					</span>
				</a>
			</NavLink>
			<NavLink
				title="Settings"
				icon={<SettingsIcon />}
				path="/settings"
				selected={location.pathname.includes('/settings')}
				expanded={expanded}
			/>
		</ul>
	);
};

NavFooter.propTypes = {
	isLeader: PropTypes.bool,
	isIndependentAgent: PropTypes.bool,
	expanded: PropTypes.bool,
	location: PropTypes.object.isRequired
};

NavFooter.defaultProps = {
	isLeader: false,
	isIndependentAgent: false,
	expanded: false
};

export default withRouter(withUser(NavFooter));
