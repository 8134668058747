import PropTypes from 'prop-types';
import React from 'react';

const Channel = ({ children, viewBox, ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
			{children}
		</svg>
	);
};

Channel.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	height: PropTypes.string,
	width: PropTypes.string,
	viewBox: PropTypes.string
};

Channel.defaultProps = {
	children: <></>,
	height: '18px',
	width: '18px',
	viewBox: '0 0 512 512'
};

export default Channel;
