import React from 'react';

import SimpleSvg from './SimpleSvg';

const Handshake = props => (
	<SimpleSvg
		d="M 14.001953 4.0019531 C 13.710328 4.0045781 13.418937 4.0678594 13.148438 4.1933594 L 9.8125 5.7363281 C 9.0025 6.1103281 8.6690781 7.0849531 9.0800781 7.8769531 C 9.4420781 8.5739531 10.265906 8.8920469 11.003906 8.6230469 L 13.994141 7.5332031 L 19.392578 12.767578 C 19.781578 13.145578 20 13.664078 20 14.205078 L 20 15 L 21 15 C 21.552 15 22 14.552 22 14 L 22 6.3867188 C 22 5.7037188 21.330594 5.2215 20.683594 5.4375 L 19 6 L 14.859375 4.1777344 C 14.586375 4.0577344 14.293578 3.9993281 14.001953 4.0019531 z M 3 5 C 2.448 5 2 5.448 2 6 L 2 15 L 3 15 L 9.0761719 20.316406 C 10.159172 21.263406 11.791594 21.208406 12.808594 20.191406 L 17.046875 15.953125 C 17.572875 15.427125 17.834797 14.739781 17.841797 14.050781 L 13.501953 9.84375 L 11.6875 10.503906 C 11.2945 10.646906 10.88475 10.71875 10.46875 10.71875 C 9.13375 10.71875 7.9206875 9.9838281 7.3046875 8.7988281 C 6.8576875 7.9388281 6.7827031 6.9189531 7.0957031 6.0019531 C 7.2217031 5.6349531 7.4183438 5.304 7.6523438 5 L 3 5 z"
		{...props}
	/>
);

export default Handshake;
