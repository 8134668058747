import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { logicalOperators } from '../globals';
import { isLogicalOperator } from '../helpers';
import ConditionItem from './ConditionItem';

function ConditionGroup(props) {
	const {
		condition: conditionJSON,
		updateCondition,
		readOnly,
		decodeCondition,
		encodeCondition,
		getSlot,
		options
	} = props;

	let conditionGroup = decodeCondition(conditionJSON);

	const updateConditionItem = (
		condition,
		indices,
		updateItems,
		index = 0
	) => {
		if (Array.isArray(condition.items) && condition.items.length > 0) {
			if (index === indices.length - 1) {
				let newItems = updateItems(
					condition.items.slice(0),
					indices[index]
				);

				if (Array.isArray(newItems) && newItems.length > 0) {
					return {
						...condition,
						items: updateItems(
							condition.items.slice(0),
							indices[index]
						)
					};
				} else {
					return null;
				}
			} else {
				let newItems = condition.items.map((item, itemIndex) => {
					if (indices[index] === itemIndex) {
						return updateConditionItem(
							item,
							indices,
							updateItems,
							index + 1
						);
					} else {
						return item;
					}
				});

				return {
					...condition,
					items: newItems
				};
			}
		} else {
			return null;
		}
	};

	const removeCondition = indices => {
		if (!indices && Array.isArray(conditionGroup.items)) return;

		if (!indices && !Array.isArray(conditionGroup.item)) {
			updateCondition(null);
		}

		const removeItem = (items, index) => {
			return items.filter((item, itemIndex) => index !== itemIndex);
		};

		let newCondition = updateConditionItem(
			{ ...conditionGroup },
			indices,
			removeItem
		);

		updateCondition(encodeCondition(newCondition));
	};

	const addCondition = (slot, indices) => {
		let newCondition;

		const addItem = (items, index) => {
			return items.map((item, itemIndex) => {
				if (itemIndex === index) {
					if (Array.isArray(item.items)) {
						return {
							...item,
							items: [...item.items, slot]
						};
					} else {
						return {
							operator: logicalOperators.AND,
							items: [item, slot]
						};
					}
				} else {
					return item;
				}
			});
		};

		if (!indices || !Array.isArray(indices)) {
			if (conditionGroup) {
				const { items } = conditionGroup;

				if (Array.isArray(items)) {
					newCondition = {
						...conditionGroup,
						items: [...conditionGroup.items, slot]
					};
				} else if (conditionGroup.slot) {
					newCondition = {
						operator: logicalOperators.AND,
						items: [conditionGroup, slot]
					};
				}
			} else {
				newCondition = {
					operator: logicalOperators.AND,
					items: [slot]
				};
			}
		} else {
			if (indices.length < 2) {
				newCondition = updateConditionItem(
					{ ...conditionGroup },
					indices,
					addItem
				);
			} else {
				newCondition = updateConditionItem(
					{ ...conditionGroup },
					indices.slice(0, indices.length - 1),
					addItem
				);
			}
		}

		updateCondition(encodeCondition(newCondition));
	};

	const updateSlot = (slot, indices) => {
		if (!slot || !indices) return;

		const updateItem = (items, index) => {
			return items.map((item, itemIndex) => {
				if (itemIndex === index) {
					return {
						...item,
						...slot
					};
				} else {
					return item;
				}
			});
		};

		let newCondition = updateConditionItem(
			{ ...conditionGroup },
			indices,
			updateItem
		);

		updateCondition(encodeCondition(newCondition));
	};

	const updateOperator = (operator, indices) => {
		if (!operator || !indices) return;

		const updateItem = (items, index) => {
			return items.map((item, itemIndex) => {
				if (itemIndex === index) {
					return {
						...item,
						operator
					};
				} else {
					return item;
				}
			});
		};

		let newCondition;
		if (indices.length === 1) {
			newCondition = {
				...conditionGroup,
				operator
			};
		} else {
			newCondition = updateConditionItem(
				{ ...conditionGroup },
				indices.slice(0, indices.length - 1),
				updateItem
			);
		}

		updateCondition(encodeCondition(newCondition));
	};

	const getItems = () => {
		if (!conditionGroup || typeof conditionGroup !== 'object') return [];

		const convertCondition = (
			condition,
			indices,
			itemOperator,
			parentOperator,
			isLastItem
		) => {
			if (!condition) return [];

			let conditionItems = [];
			const { slot, operator, value, items } = condition;

			if (Array.isArray(items)) {
				items.forEach((item, index) => {
					if (!item) return;

					let isLastLocalItem = index === items.length - 1;
					let newOperator = isLogicalOperator(item.operator)
						? item.operator
						: itemOperator;
					let itemIndices;
					if (Array.isArray(indices)) {
						itemIndices = [...indices, index];
					} else {
						itemIndices = [index];
					}

					conditionItems.push(
						...convertCondition(
							item,
							itemIndices,
							newOperator,
							itemOperator,
							isLastLocalItem
						)
					);

					if (
						isLogicalOperator(parentOperator) &&
						isLastLocalItem &&
						isLastItem === false
					) {
						conditionItems.push({
							type: 'operator',
							operator: parentOperator,
							indices
						});
					}
				});
			} else if (slot) {
				conditionItems.push({
					type: 'slot',
					indices,
					slot,
					operator,
					value
				});

				if (
					!readOnly &&
					(isLastItem || (indices && indices.length === 1)) &&
					getSlot(slot)?.type !== 'built-in'
				) {
					conditionItems.push({
						type: 'add',
						indices
					});
				}

				if (isLogicalOperator(itemOperator) && !isLastItem) {
					conditionItems.push({
						type: 'operator',
						operator: itemOperator,
						indices
					});
				}
			}

			return conditionItems;
		};

		let conditionItems = convertCondition(
			conditionGroup,
			null,
			conditionGroup.operator,
			conditionGroup.operator,
			conditionGroup.items === undefined ? true : undefined
		);

		if (!readOnly) {
			conditionItems.push({
				type: 'add-condition'
			});
		}

		return conditionItems;
	};

	// TODO: add readonly empty items edge case
	const renderItems = () => {
		const items = getItems();

		if (!readOnly && (!items || items.length === 0)) {
			return (
				<ConditionItem
					type="add-condition"
					addCondition={addCondition}
					removeCondition={removeCondition}
					updateSlot={updateSlot}
					updateOperator={updateOperator}
					options={options}
					getSlot={getSlot}
					readOnly={readOnly}
				/>
			);
		} else {
			return items.map((item, index) => {
				return (
					<ConditionItem
						key={index}
						addCondition={addCondition}
						removeCondition={removeCondition}
						updateSlot={updateSlot}
						updateOperator={updateOperator}
						readOnly={readOnly}
						options={options}
						getSlot={getSlot}
						{...item}
					/>
				);
			});
		}
	};

	return <StyledConditionGroup>{renderItems()}</StyledConditionGroup>;
}

ConditionGroup.propTypes = {
	readOnly: PropTypes.bool,
	options: PropTypes.array,
	condition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	decodeCondition: PropTypes.func.isRequired,
	encodeCondition: PropTypes.func.isRequired,
	getSlot: PropTypes.func.isRequired,
	updateCondition: PropTypes.func
};

ConditionGroup.defaultProps = {
	readOnly: false,
	condition: null,
	options: [],
	updateCondition: null
};

const StyledConditionGroup = styled.span`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export default ConditionGroup;
