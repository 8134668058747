import React from 'react';

import SimpleSvg from './SimpleSvg';

const View = props => (
	<SimpleSvg
		d="M 6 2 C 4.894531 2 4 2.894531 4 4 L 4 20 C 4 21.105469 4.894531 22 6 22 L 12.261719 22 C 12.039062 21.683594 11.847656 21.351562 11.683594 21 C 11.679688 20.992188 11.675781 20.984375 11.671875 20.972656 C 11.542969 20.703125 11.4375 20.421875 11.34375 20.128906 C 11.125 19.457031 11 18.746094 11 18 C 11 14.132812 14.132812 11 18 11 C 18.339844 11 18.671875 11.03125 19 11.078125 L 19 11.074219 C 19.34375 11.125 19.675781 11.199219 20 11.296875 L 20 8.828125 C 20 8.296875 19.789062 7.789062 19.414062 7.414062 L 14.585938 2.585938 C 14.210938 2.210938 13.703125 2 13.171875 2 Z M 13 3.453125 L 18.5 9 L 14 9 C 13.449219 9 13 8.550781 13 8 Z M 18 13 C 15.292969 13 13 15.292969 13 18 C 13 20.800781 15.199219 23 18 23 C 19 23 20 22.699219 20.800781 22.199219 L 22.3125 23.710938 C 22.699219 24.097656 23.324219 24.097656 23.710938 23.710938 C 24.097656 23.324219 24.097656 22.699219 23.710938 22.3125 L 22.199219 20.800781 C 22.699219 20 23 19 23 18 C 23 15.199219 20.800781 13 18 13 Z M 18 15 C 19.699219 15 21 16.300781 21 18 C 21 19.699219 19.699219 21 18 21 C 16.300781 21 15 19.699219 15 18 C 15 16.300781 16.300781 15 18 15 Z M 18 15 "
		{...props}
	/>
);

export default View;
