import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRadio = styled.input`
	height: 15px;
	width: 15px;
`;

class Radio extends PureComponent {
	static propTypes = {
		checked: PropTypes.bool,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		checked: false,
		disabled: false
	};

	render() {
		const { checked, disabled } = this.props;

		return (
			<StyledRadio
				readOnly
				type="radio"
				disabled={disabled}
				checked={checked}
				{...this.props}
			/>
		);
	}
}

export default Radio;
