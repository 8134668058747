const blue = {
	d3: '#0D47A1',
	d2: '#004AD7',
	d1: '#0053F0',
	default: '#0B5FFF',
	l1: '#80ACFF',
	l2: '#B3CDFF',
	l3: '#D2E1FF',
	onCloud: '#E6F2FF',
	onWhite: '#F0F5FF'
};

export default blue;
