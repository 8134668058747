import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

function Popover(props) {
	const {
		renderHandler,
		renderPopover,
		placement,
		handlerClassName,
		popoverClassName,
		showPopover: controlledShowPopover,
		togglePopover: controlledTogglePopover,
		disabled,
		...other
	} = props;
	const [showPopover, togglePopover] = useState(false);
	const formRef = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClick, false);

		if (controlledShowPopover !== null && !controlledTogglePopover) {
			console.warn(
				'Blink Popover component requires a togglePopover when provided a controlled showPopover value'
			);
		}

		return () => {
			document.removeEventListener('mousedown', handleClick, false);
		};
		// eslint-disable-next-line
	}, []);

	const handleClick = e => {
		if (formRef && formRef.current && formRef.current.contains(e.target))
			return;

		if (controlledTogglePopover) {
			controlledTogglePopover(false);
		} else {
			togglePopover(false);
		}
	};

	const onClickHandler = () => {
		if (controlledTogglePopover) {
			controlledTogglePopover(true);
		} else {
			togglePopover(true);
		}
	};

	if (disabled) return renderHandler();

	return (
		<div ref={formRef} onClick={e => e.stopPropagation()}>
			<Manager>
				<Reference>
					{({ ref }) => (
						<div
							ref={ref}
							className={handlerClassName}
							onClick={onClickHandler}
						>
							{renderHandler()}
						</div>
					)}
				</Reference>
				<Popper placement={placement} {...other}>
					{({ ref, style, placement }) => {
						if (
							(controlledTogglePopover &&
								!controlledShowPopover) ||
							(!controlledTogglePopover && !showPopover)
						)
							return null;

						return (
							<div
								ref={ref}
								style={{
									...style,
									zIndex: 99
								}}
								className={popoverClassName}
								data-placement={placement}
							>
								{renderPopover()}
							</div>
						);
					}}
				</Popper>
			</Manager>
		</div>
	);
}

Popover.propTypes = {
	placement: PropTypes.oneOf([
		'auto',
		'top',
		'bottom',
		'right',
		'left',
		'auto-start',
		'auto-end',
		'top-start',
		'top-end',
		'bottom-start',
		'bottom-end',
		'right-start',
		'right-end',
		'left-start',
		'left-end'
	]),
	handlerClassName: PropTypes.string,
	popoverClassName: PropTypes.string,
	showPopover: PropTypes.bool,
	togglePopover: PropTypes.func,
	disabled: PropTypes.bool,
	renderHandler: PropTypes.func.isRequired,
	renderPopover: PropTypes.func.isRequired
};

Popover.defaultProps = {
	placement: 'auto',
	handlerClassName: '',
	popoverClassName: '',
	showPopover: null,
	togglePopover: null,
	disabled: false
};

export default Popover;
