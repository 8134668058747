import React from 'react';

import SimpleSvg from './SimpleSvg';

const Brokerage = props => (
	<SimpleSvg
		d="M 7 3 C 5.895 3 5 3.895 5 5 L 5 19 C 5 20.105 5.895 21 7 21 L 9 21 L 9 20 L 9.0195312 20 C 9.1165312 18.473 10.201484 17.225781 11.896484 16.550781 C 11.336484 15.866781 11.004 14.987625 11 14.015625 C 10.992 11.955625 12.634453 10.159766 14.689453 10.009766 C 15.542453 9.9477656 16.333 10.143484 17 10.521484 L 17 10.556641 C 17.174 10.659641 17.343 10.771437 17.5 10.898438 C 17.935 10.547437 18.446 10.293531 19 10.144531 L 19 5 C 19 3.895 18.105 3 17 3 L 7 3 z M 9.5 7 L 10.5 7 C 10.776 7 11 7.224 11 7.5 L 11 8.5 C 11 8.776 10.776 9 10.5 9 L 9.5 9 C 9.224 9 9 8.776 9 8.5 L 9 7.5 C 9 7.224 9.224 7 9.5 7 z M 13.5 7 L 14.5 7 C 14.776 7 15 7.224 15 7.5 L 15 8.5 C 15 8.776 14.776 9 14.5 9 L 13.5 9 C 13.224 9 13 8.776 13 8.5 L 13 7.5 C 13 7.224 13.224 7 13.5 7 z M 9.5 11 L 10.5 11 C 10.776 11 11 11.224 11 11.5 L 11 12.5 C 11 12.776 10.776 13 10.5 13 L 9.5 13 C 9.224 13 9 12.776 9 12.5 L 9 11.5 C 9 11.224 9.224 11 9.5 11 z M 15 12 A 2 2 0 0 0 13 14 A 2 2 0 0 0 15 16 A 2 2 0 0 0 17 14 A 2 2 0 0 0 15 12 z M 20 12 A 2 2 0 0 0 18 14 A 2 2 0 0 0 20 16 A 2 2 0 0 0 22 14 A 2 2 0 0 0 20 12 z M 9.5 15 L 10.5 15 C 10.776 15 11 15.224 11 15.5 L 11 16.5 C 11 16.776 10.776 17 10.5 17 L 9.5 17 C 9.224 17 9 16.776 9 16.5 L 9 15.5 C 9 15.224 9.224 15 9.5 15 z M 15 18 C 12.815 18 11 18.908797 11 20.216797 L 11 20.5 C 11 20.776 11.224 21 11.5 21 L 16.5 21 L 18.5 21 L 23.5 21 C 23.776 21 24 20.776 24 20.5 L 24 20.216797 C 24 18.908797 22.185 18 20 18 C 19.054926 18 18.189708 18.177718 17.5 18.476562 C 16.810292 18.177718 15.945074 18 15 18 z"
		{...props}
	/>
);

export default Brokerage;
