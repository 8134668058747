import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactList from 'react-list';
import throttle from 'lodash/throttle';

class InfiniteList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollPosition: ''
		};

		this.renderItem = this.renderItem.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	static propTypes = {
		itemClassName: PropTypes.string,
		className: PropTypes.string,
		totalItemCount: PropTypes.number,
		pageSize: PropTypes.number,
		containerHeight: PropTypes.number,
		type: PropTypes.string,
		onFetchMore: PropTypes.func.isRequired,
		isFetching: PropTypes.bool,
		renderLoadingDelegate: PropTypes.func.isRequired,
		children: PropTypes.arrayOf(PropTypes.node)
	};

	static defaultProps = {
		itemClassName: '',
		className: '',
		totalItemCount: 0,
		pageSize: 0,
		containerHeight: 0,
		type: 'uniform',
		isFetching: false,
		children: []
	};

	handleScroll() {
		const { containerHeight, onFetchMore } = this.props;
		const { scrollPosition } = this.state;

		if (this.list) {
			const { scrollTop, scrollHeight } = this.list;

			if (scrollPosition !== 'top' && scrollTop === 0) {
				this.setState({ scrollPosition: 'top' });
			} else if (
				scrollPosition !== 'bottom' &&
				scrollTop === scrollHeight - containerHeight
			) {
				if (onFetchMore) {
					onFetchMore();
				}

				this.setState({ scrollPosition: 'bottom' });
			} else if (scrollPosition !== 'middle') {
				this.setState({ scrollPosition: 'middle' });
			}
		}
	}

	renderLoadingDelegate() {
		const { isFetching, renderLoadingDelegate } = this.props;

		if (!isFetching) return null;

		return renderLoadingDelegate();
	}

	renderItem(index, key) {
		const { children, itemClassName } = this.props;

		return (
			<div key={key} className={itemClassName}>
				{children[index]}
			</div>
		);
	}

	render() {
		const {
			totalItemCount,
			containerHeight,
			className,
			pageSize,
			type
		} = this.props;

		return (
			<div
				className={`infinite-list ${className} `}
				ref={node => (this.list = node)}
				style={{ height: `${containerHeight}px`, overflow: 'auto' }}
				onScroll={throttle(this.handleScroll, 16)}
			>
				<ReactList
					type={type}
					pageSize={pageSize}
					length={totalItemCount}
					itemRenderer={this.renderItem}
				/>
				{this.renderLoadingDelegate()}
			</div>
		);
	}
}

export default InfiniteList;
