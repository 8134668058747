import { gql } from '@apollo/client';

import { FRAGMENT_LEAD_LIST } from './fragments';

const MUTATE_LOGOUT_CLIENT = gql`
	mutation logout {
		logout {
			success
		}
	}
`;

const MUTATE_ASSIGN_CUSTOMER_TO_AGENT = gql`
	mutation($agentId: ID!, $customerId: ID!) {
		assignCustomerToAgent(agentId: $agentId, customerId: $customerId) {
			status
		}
	}
`;

const MUTATE_ASSIGN_CUSTOMER_TO_TEAM = gql`
	mutation($teamId: ID!, $customerId: ID!) {
		assignCustomerToTeam(teamId: $teamId, customerId: $customerId) {
			status
		}
	}
`;

const MUTATE_UNASSIGN_CUSTOMER_FROM_AGENT = gql`
	mutation($customerId: ID!) {
		unassignCustomerFromAgent(customerId: $customerId) {
			status
		}
	}
`;

const MUTATE_UNASSIGN_CUSTOMER_FROM_TEAM = gql`
	mutation($customerId: ID!) {
		unassignCustomerFromTeam(customerId: $customerId) {
			status
		}
	}
`;

const MUTATE_ADD_AGENT_TO_TEAM = gql`
	mutation($accessToken: String!, $teamId: ID!, $agentId: ID!) {
		addAgentToTeam(
			accessToken: $accessToken
			id: $teamId
			agentId: $agentId
		) {
			status
		}
	}
`;

const MUTATE_ADD_AGENT_TO_BROKERAGE = gql`
	mutation($accessToken: String!, $brokerageId: ID!, $agentId: ID!) {
		addAgentToBrokerage(
			accessToken: $accessToken
			id: $brokerageId
			agentId: $agentId
		) {
			status
		}
	}
`;

const MUTATE_ADD_TEAM_TO_BROKERAGE = gql`
	mutation($accessToken: String!, $brokerageId: ID!, $teamId: ID!) {
		addTeamToBrokerage(
			accessToken: $accessToken
			id: $brokerageId
			teamId: $teamId
		) {
			status
		}
	}
`;

export const MUTATE_START_NEW_CONVO = gql`
	mutation($metadata: StartConversationMetadata!) {
		startNewConvo(metadata: $metadata) {
			result
			customerId
		}
	}
`;

export const MUTATE_UPLOAD_LEADS = gql`
	mutation uploadLeadList(
		$name: String!
		$leads: [LeadImportInputType!]!
		$entityId: ID!
	) {
		createLeadList(
			id: $entityId
			name: $name
			leads: $leads
			purpose: "Enrollment"
		) {
			result {
				...LeadList
			}
		}
	}

	${FRAGMENT_LEAD_LIST}
`;

export {
	MUTATE_LOGOUT_CLIENT,
	MUTATE_ASSIGN_CUSTOMER_TO_AGENT,
	MUTATE_ASSIGN_CUSTOMER_TO_TEAM,
	MUTATE_UNASSIGN_CUSTOMER_FROM_AGENT,
	MUTATE_UNASSIGN_CUSTOMER_FROM_TEAM,
	MUTATE_ADD_AGENT_TO_TEAM,
	MUTATE_ADD_AGENT_TO_BROKERAGE,
	MUTATE_ADD_TEAM_TO_BROKERAGE
};
