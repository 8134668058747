import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * TODO:
 *  - secondary
 *  - danger
 *  - ghost
 *  - link
 *  - icon
 */
const color = ({ kind, outline, ghost, inverse, theme: { palette } }) => {
	switch (kind) {
		case 'primary':
			if (outline) {
				return css`
					background-color: ${palette.common.white};
					color: ${palette.primary.default};
					border-color: ${palette.primary.default};

					& > svg {
						fill: ${palette.primary.default};
					}

					&:hover {
						background-color: ${palette.primary.onWhite};
						border-color: ${palette.primary.default};
					}
				`;
			} else if (ghost) {
				return css`
					background-color: inherit;
					color: ${palette.primary.d2};
					border: none;

					& > svg {
						fill: ${palette.primary.d2};
					}

					&:hover {
						background-color: ${palette.primary.onWhite};
					}
				`;
			} else if (inverse) {
				return css`
					background-color: ${palette.primary.onCloud};
					border: none;
					color: ${palette.primary.d2};
				`;
			} else {
				return css`
					background-color: ${palette.primary.default};
					color: ${palette.common.white};
					border-color: ${palette.primary.default};

					& > svg {
						fill: ${palette.common.white};
					}

					&:hover {
						background-color: ${palette.primary.d1};
						border-color: ${palette.primary.d1};
					}

					&:active,
					&:focus {
						background-color: ${palette.primary.d2};
						border-color: ${palette.primary.d2};
					}

					&:disabled {
						background-color: ${palette.primary.l1};
						border-color: ${palette.primary.l1};
					}
				`;
			}
		case 'secondary':
			if (outline) {
				return css`
					background-color: ${palette.common.white};
					color: ${palette.secondary.default};
					border-color: ${palette.secondary.default};

					& > svg {
						fill: ${palette.secondary.default};
					}

					&:hover {
						background-color: ${palette.secondary.l3};
						border-color: ${palette.secondary.default};
					}
				`;
			} else {
				return css`
					background-color: ${palette.secondary.default};
					color: ${palette.common.white};
					border-color: ${palette.secondary.default};

					& > svg {
						fill: ${palette.common.white};
					}

					&:hover {
						background-color: ${palette.secondary.d1};
						border-color: ${palette.secondary.d1};
					}

					&:active,
					&:focus {
						background-color: ${palette.secondary.d2};
						border-color: ${palette.secondary.d2};
					}

					&:disabled {
						background-color: ${palette.secondary.l2};
						border-color: ${palette.secondary.l2};
					}
				`;
			}
		case 'danger':
			if (outline) {
				return css`
					background-color: ${palette.common.white};
					color: ${palette.red.default};
					border-color: ${palette.red.default};

					& > svg {
						fill: ${palette.red.default};
					}

					&:hover {
						background-color: ${palette.red.l3};
						border-color: ${palette.red.default};
					}
				`;
			} else {
				return css`
					background-color: ${palette.red.default};
					color: ${palette.common.white};
					border-color: ${palette.red.default};

					& > svg {
						fill: ${palette.common.white};
					}

					&:hover {
						background-color: ${palette.red.d1};
						border-color: ${palette.red.d1};
					}

					&:active,
					&:focus {
						background-color: ${palette.red.d2};
						border-color: ${palette.red.d2};
					}

					&:disabled {
						background-color: ${palette.red.l2};
						border-color: ${palette.red.l2};
					}
				`;
			}
		case 'transparent':
			return css`
				background-color: transparent;
				color: ${palette.text.primary};
				border-color: transparent;

				& > svg {
					fill: ${palette.text.primary};
				}

				&:hover {
					background-color: ${palette.grey[100]};
				}

				&:active,
				&:focus {
					background-color: ${palette.grey[200]};
				}

				&:disabled {
					color: ${palette.grey[800]};
				}
			`;
		case 'ghost':
			return css`
				background-color: inherit;
				color: ${palette.text.primary};
				border-color: transparent;

				& > svg {
					fill: ${palette.text.primary};
				}

				&:hover {
					background-color: ${palette.grey[100]};
				}

				&:active,
				&:focus {
					background-color: ${palette.grey[200]};
				}

				&:disabled {
					color: ${palette.grey[800]};
				}
			`;
		default:
			if (outline) {
				return css`
					background-color: ${palette.common.white};
					color: ${palette.text.primary};
					border-color: ${palette.grey[200]};

					&:hover {
						background-color: ${palette.grey[50]};
					}
				`;
			} else {
				return css`
					background-color: ${palette.grey[200]};
					color: ${palette.common.white};
					border-color: ${palette.grey[200]};

					&:hover {
						background-color: ${palette.grey.d1};
						border-color: ${palette.grey.d1};
					}

					&:active,
					&:focus {
						background-color: ${palette.grey.d2};
						border-color: ${palette.grey.d2};
					}

					&:disabled {
						background-color: ${palette.grey.l2};
						border-color: ${palette.grey.l2};
					}
				`;
			}
	}
};

/**
 * TODO
 *
 * const shape = () => null;
 */
const action = ({ action, theme: { palette } }) => {
	if (action === 'add') {
		return css`
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: inherit;
			color: ${palette.secondary.d2};
			border-color: transparent;

			& > svg {
				fill: ${palette.secondary.d2};

				&:not(:only-child) {
					margin-right: 4px;
				}
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.03);
			}

			&:active,
			&:focus {
				background-color: rgba(0, 0, 0, 0.06);
			}
		`;
	}
};

const contentKind = ({ contentKind, theme: { palette } }) => {
	if (contentKind === 'icon') {
		return css`
			padding: 4px;
			height: 34px;

			& > svg {
				fill: ${palette.grey[600]};
			}
		`;
	}
};

const size = ({ size }) => {
	if (size === 'lg') {
		return css`
			height: 40px;
			min-width: 40px;
			padding: 0 8px;
			font-size: 16px;

			& > svg {
				font-size: 22px;
				height: 37px;

				& + span {
					margin-right: 6px;
					margin-top: 2px;
				}
			}
		`;
	} else if (size === 'sm') {
		return css`
			height: 35px;
			min-width: 35px;
			padding: 0 8px;
			font-size: 14px;

			& > svg {
				font-size: 18px;
				height: 32px;

				& + span {
					margin-left: 4px;
					margin-top: 2px;
				}
			}
		`;
	} else {
		return css`
			height: 40px;
			min-width: 40px;
			padding: 0 16px;
			font-size: 16px;

			& > svg {
				font-size: 18px;
				height: 37px;

				& + span {
					margin-right: 6px;
					margin-top: 2px;
				}
			}
		`;
	}
};

const raised = ({ raised, disabled, theme }) =>
	!disabled && raised && `box-shadow: ${theme.shadows[1]}`;

const block = ({ block }) => {
	if (block) {
		return css`
			width: 100%;
		`;
	}
};

/**
 * TODO
 *
 * loading
 */
const Button = styled.button`
	outline: none;
	cursor: pointer;
	border-radius: ${({ theme: { shape } }) => shape.borderRadius};
	border: 1px solid;
	box-sizing: border-box;
	margin-right: ${({ theme: { spacing } }) => spacing(1)};
	font-family: inherit;

	&:last-of-type {
		margin-right: 0;
	}

	&:disabled {
		cursor: initial;
	}

	${color};
	${action};
	${contentKind};
	${raised};
	${size};
	${block};
`;

Button.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired,
	onClick: PropTypes.func,
	href: PropTypes.string,
	disabled: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'default', 'lg']),
	kind: PropTypes.oneOf([
		'primary',
		'secondary',
		'danger',
		'ghost',
		'transparent',
		'default'
	]),
	ghost: PropTypes.bool,
	contentKind: PropTypes.oneOf(['icon', 'link']),
	action: PropTypes.oneOf(['add', 'delete']),
	outline: PropTypes.bool,
	shape: PropTypes.oneOf(['circle', 'default']),
	block: PropTypes.bool,
	htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
	target: PropTypes.string
};

Button.defaultProps = {
	onClick: null,
	href: null,
	disabled: false,
	size: 'default',
	kind: 'default',
	outline: false,
	shape: 'default',
	ghost: false,
	block: false,
	raised: false,
	htmlType: 'button',
	target: null
};

export default Button;
