import styled from 'styled-components';

const StyledMenu = styled.ul`
	z-index: 10;
	width: 100%;
	background-color: #fff;
	box-shadow: ${({ theme: { shadows } }) => shadows[2]};
	border-radius: 4px;
	overflow-y: auto;
	max-height: 180px;
	padding: 8px 0;
`;

export default StyledMenu;
