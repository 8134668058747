import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CheckedCheckbox, UncheckedCheckbox } from 'modules/blink/icons';

class Checkbox extends PureComponent {
	static propTypes = {
		color: PropTypes.string,
		checked: PropTypes.bool
	};

	static defaultProps = {
		color: '',
		checked: false
	};

	render() {
		const { checked, color } = this.props;

		if (checked) {
			return (
				<CheckedCheckbox
					height="20px"
					width="20px"
					fill={color || '#2d9ee0'}
					{...this.props}
				/>
			);
		} else {
			return (
				<UncheckedCheckbox
					height="20px"
					width="20px"
					fill={color || '#444'}
					{...this.props}
				/>
			);
		}
	}
}

export default Checkbox;
