import React from 'react';
import PropTypes from 'prop-types';

import SimpleSvg from './SimpleSvg';

const Error = ({ title, ...props }) => (
	<SimpleSvg
		d="M 22.238281 18.449219 L 13.441406 3.816406 C 13.136719 3.304688 12.597656 3 12 3 C 11.402344 3 10.863281 3.304688 10.558594 3.816406 L 1.761719 18.449219 C 1.449219 18.96875 1.441406 19.617188 1.738281 20.144531 C 2.035156 20.671875 2.597656 21 3.203125 21 L 20.796875 21 C 21.402344 21 21.964844 20.671875 22.261719 20.144531 C 22.558594 19.617188 22.550781 18.96875 22.238281 18.449219 Z M 12.5 18 L 11.5 18 C 11.222656 18 11 17.777344 11 17.5 L 11 16.5 C 11 16.222656 11.222656 16 11.5 16 L 12.5 16 C 12.777344 16 13 16.222656 13 16.5 L 13 17.5 C 13 17.777344 12.777344 18 12.5 18 Z M 12 14 C 11.449219 14 11 13.550781 11 13 L 11 10 C 11 9.449219 11.449219 9 12 9 C 12.550781 9 13 9.449219 13 10 L 13 13 C 13 13.550781 12.550781 14 12 14 Z M 12 14 "
		{...props}
	>
		{!!title && <title>{title}</title>}
	</SimpleSvg>
);

Error.propTypes = {
	title: PropTypes.string
};

Error.defaultProps = {
	title: ''
};

export default Error;
