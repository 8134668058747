import React from 'react';

import SimpleSvg from './SimpleSvg';

const Address = props => (
	<SimpleSvg
		d="M12,2C8.134,2,5,5.134,5,9c0,3.966,4.404,9.82,6.226,12.071c0.4,0.494,1.148,0.494,1.548,0C14.596,18.82,19,12.966,19,9 C19,5.134,15.866,2,12,2z M15.333,10H15v2.333C15,12.702,14.702,13,14.333,13h-0.667C13.298,13,13,12.702,13,12.333V10h-2v2.333 C11,12.702,10.702,13,10.333,13H9.667C9.298,13,9,12.702,9,12.333V10H8.667C8.298,10,8,9.702,8,9.333v0 C8,9.123,8.099,8.926,8.267,8.8L11.4,6.45c0.356-0.267,0.844-0.267,1.2,0l3.133,2.35C15.901,8.926,16,9.123,16,9.333v0 C16,9.702,15.702,10,15.333,10z"
		{...props}
	/>
);

export default Address;
