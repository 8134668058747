import styled, { css } from 'styled-components';

const active = ({ active, theme: { palette } }) => {
	if (active) {
		return css`
			color: #444;
			border-color: ${palette.primary.default};
		`;
	}
};

const Tab = styled.div`
	height: 40px;
	font-weight: 500;
	display: flex;
	border-bottom: 2px solid transparent;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	box-sizing: border-box;
	cursor: pointer;
	color: ${({ theme: { palette } }) => palette.grey.d1};

	&:hover {
		color: #444;
	}

	${active};
`;

export default Tab;
