const colors = {
	primary: '#073D7D',
	success: '#2a354f',
	warning: '#FACA00',
	danger: '#F44336',
	cloud: '#ECF5FD',
	midnight: '#36475E',
	violet: '#673AB7',
	purple: '#9C27B0',
	navy: '#3F51B5',
	teal: '#009688',
	green: '#4CAF50',
	orange: '#FF9800',
	orangeRed: '#FF5722',
	grey: '#8D8D8D',
	lightGrey: '#D8D8D8'
};

const fontWeights = {
	regular: '300',
	bold: '400'
};

const styles = {
	boxShadow: '0 4px 23px 0 rgba(0,0,0,.14)'
};

const media = {
	tablet: 'only screen and (min-width: 730px) and (max-width: 949px)',
	desktop: 'only screen and (min-width: 950px) and (max-width: 1128px)',
	desktopXl: 'only screen and (min-width: 1129px)',
	notMobile: 'only screen and (min-width: 730px)'
};

const operatorPrefix = '_$';

const logicalOperators = {
	AND: `${operatorPrefix}and`,
	OR: `${operatorPrefix}or`
};

const comparisonOperators = {
	EQ: `${operatorPrefix}eq`,
	NE: `${operatorPrefix}ne`,
	IN: `${operatorPrefix}in`,
	NIN: `${operatorPrefix}nin`,
	GT: `${operatorPrefix}gt`,
	GTE: `${operatorPrefix}gte`,
	LT: `${operatorPrefix}lt`,
	LTE: `${operatorPrefix}lte`,
	INC: `${operatorPrefix}includes`,
	CI_INC: `${operatorPrefix}ci_includes`
};

const time = {
	SEC: 1000,
	MIN: 60000,
	HOUR: 3600000,
	DAY: 86400000,
	WEEK: 604800000,
	MONTH: 2628000000,
	YEAR: 31556952000
};

const DOMAINS = {
	REAL_ESTATE: 'REAL_ESTATE',
	MORTGAGE: 'MORTGAGE',
	SOFTWARE: 'SOFTWARE',
	SOLAR: 'SOLAR',
	INSURANCE: 'INSURANCE',
	TRAVEL: 'TRAVEL',
	AUTOMOTIVE: 'AUTOMOTIVE',
	SUPPORT: 'SUPPORT',
	HOME_SERVICES: 'HOME_SERVICES',
	FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
	RECRUITING: 'RECRUITING',
	OTHER: 'OTHER'
};

export {
	colors,
	fontWeights,
	styles,
	media,
	operatorPrefix,
	logicalOperators,
	comparisonOperators,
	time,
	DOMAINS
};
