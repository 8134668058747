import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function Button(props) {
	const { children } = props;

	return <StyledButton {...props}>{children}</StyledButton>;
}

Button.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	action: PropTypes.oneOf(['submit', 'add', 'delete', 'edit', 'default']),
	type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
	size: PropTypes.oneOf(['sm', 'default']),
	active: PropTypes.bool,
	disabled: PropTypes.bool
};

Button.defaultProps = {
	label: '',
	children: null,
	action: 'default',
	size: 'default',
	type: 'primary',
	active: false,
	disabled: false
};

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	outline: none;
	border: none;
	height: 36px;
	min-width: 36px;
	line-height: 36px;
	border-radius: 4px;
	font-weight: 500px;
	font-size: 13px;
	cursor: pointer;
	box-sizing: border-box;

	svg {
		font-size: 20px;
	}

	${({ size }) => {
		if (size === 'sm') {
			return css`
				height: 30px;
				min-width: 30px;
				line-height: 30px;

				svg {
					font-size: 18px;
				}
			`;
		}
	}}

	/** Color **/
	${({ action, type, active, disabled, theme: { palette, shadows } }) => {
		switch (type) {
			case 'secondary':
				switch (action) {
					case 'submit':
						if (active) {
							return css`
								border: 1px solid;
								border-color: ${palette.blue[900]};
								background-color: ${palette.blue[50]};
								color: ${palette.blue[900]};
								padding: 0 12px;
							`;
						} else if (disabled) {
							return css`
								border: 1px solid transparent;
								background-color: ${palette.grey[200]};
								color: '#fff';
								padding: 0 12px;
							`;
						} else {
							return css`
								border: 1px solid transparent;
								background-color: ${palette.blue[50]};
								color: ${palette.blue[900]};
								padding: 0 12px;

								&:hover {
									border-color: ${palette.blue[900]};
								}

								&:active,
								&:focus {
									border-color: ${palette.blue[900]};
									background-color: ${palette.blue[50]};
								}
							`;
						}
					case 'add':
						if (active) {
							return css``;
						} else if (disabled) {
						} else {
						}
						break;
					case 'delete':
						if (active) {
						} else if (disabled) {
						} else {
						}
						break;
					case 'edit':
					default:
						if (active) {
						} else if (disabled) {
						} else {
						}
						break;
				}
				break;
			case 'tertiary':
				switch (action) {
					case 'submit':
						if (active) {
						} else if (disabled) {
						} else {
						}
						break;
					case 'add':
						if (active) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.green[100]};
								color: ${({ theme: { palette } }) =>
									palette.green[900]};
							`;
						} else if (disabled) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.grey[200]};
								color: '#fff';
							`;
						} else {
							return css`
								background-color: transparent;
								color: ${palette.green[900]};

								&:hover {
									background-color: ${palette.green[50]};
								}

								&:active,
								&:focus {
									background-color: ${palette.green[100]};
								}
							`;
						}
					case 'delete':
						if (active) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.red[100]};
								color: ${({ theme: { palette } }) =>
									palette.red[900]};
							`;
						} else if (disabled) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.grey[200]};
								color: '#fff';
							`;
						} else {
							return css`
								background-color: transparent;
								color: ${palette.red[900]};

								&:hover {
									background-color: ${palette.red[50]};
								}

								&:active,
								&:focus {
									background-color: ${palette.red[100]};
								}
							`;
						}
					case 'edit':
					default:
						if (active) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.grey[100]};
								color: ${({ theme: { palette } }) =>
									palette.grey[900]};
							`;
						} else if (disabled) {
							return css`
								background-color: ${({ theme: { palette } }) =>
									palette.grey[200]};
								color: '#fff';
							`;
						} else {
							return css`
								background-color: transparent;
								color: ${palette.grey[900]};

								&:hover {
									background-color: ${palette.grey[50]};
								}

								&:active,
								&:focus {
									background-color: ${palette.grey[100]};
								}
							`;
						}
				}
				break;
			case 'primary':
			default:
				switch (action) {
					case 'submit':
						if (active) {
							return css`
								background-color: ${palette.blue[800]};
								color: #fff;
								box-shadow: ${shadows[1]};
								padding: 0 12px;

								& > svg {
									fill: #fff;
								}
							`;
						} else if (disabled) {
							return css`
								background-color: ${palette.grey[200]};
								color: #fff;
								cursor: not-allowed;
								padding: 0 12px;

								& > svg {
									fill: #fff;
								}
							`;
						} else {
							return css`
								background-color: ${palette.blue[500]};
								color: #fff;
								box-shadow: ${shadows[1]};
								padding: 0 12px;

								& > svg {
									fill: #fff;
								}

								&:hover {
									background-color: ${palette.blue[600]};
								}
							`;
						}
					case 'add':
					case 'delete':
					case 'edit':
					default:
						if (active) {
							return css`
								background-color: ${palette.grey[800]};
								color: #fff;
								box-shadow: ${shadows[1]};

								& > svg {
									fill: #fff;
								}
							`;
						} else if (disabled) {
							return css`
								background-color: ${palette.grey[200]};
								color: #fff;
								cursor: not-allowed;

								& > svg {
									fill: #fff;
								}
							`;
						} else {
							return css`
								background-color: ${palette.grey[600]};
								color: #fff;
								box-shadow: ${shadows[1]};

								& > svg {
									fill: #fff;
								}

								&:hover {
									background-color: ${palette.grey[700]};
								}
							`;
						}
				}
		}
	}}
`;

export default Button;
