import React from 'react';

import SimpleSvg from './SimpleSvg';

const Bot = props => (
	<SimpleSvg
		d="M 12 2 A 2 2 0 0 0 10 4 A 2 2 0 0 0 11 5.7304688 L 11 8 L 8 8 C 5.791 8 4 9.791 4 12 L 4 16 L 3 16 A 1.0001 1.0001 0 1 0 3 18 L 4 18 L 4 20 C 4 21.105 4.895 22 6 22 L 18 22 C 19.105 22 20 21.105 20 20 L 20 18 L 21 18 A 1.0001 1.0001 0 1 0 21 16 L 20 16 L 20 12 C 20 9.791 18.209 8 16 8 L 13 8 L 13 5.7285156 A 2 2 0 0 0 14 4 A 2 2 0 0 0 12 2 z M 8.5 12 C 9.328 12 10 12.672 10 13.5 C 10 14.328 9.328 15 8.5 15 C 7.672 15 7 14.328 7 13.5 C 7 12.672 7.672 12 8.5 12 z M 15.5 12 C 16.328 12 17 12.672 17 13.5 C 17 14.328 16.328 15 15.5 15 C 14.672 15 14 14.328 14 13.5 C 14 12.672 14.672 12 15.5 12 z M 10 18 L 14 18 C 14.552 18 15 18.448 15 19 C 15 19.552 14.552 20 14 20 L 10 20 C 9.448 20 9 19.552 9 19 C 9 18.448 9.448 18 10 18 z"
		{...props}
	/>
);

export default Bot;
