import React from 'react';
import PropTypes from 'prop-types';

import Channel from './Channel.jsx';

const TextChannel = ({ deliveryStatus, ...props }) => {
	let barsColor = '#B7CAC4';
	if (deliveryStatus === 'DeliveryFailed') {
		barsColor = 'red';
	} else if (deliveryStatus === 'DeliveredWithWarnings') {
		barsColor = 'darkorange';
	}

	return (
		<Channel {...props}>
			<title>SMS Text channel</title>
			<path
				fill="#05606e"
				d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"
			/>
			<path
				fill="#fff"
				d="M412.7,313.2c0,18.1-14.6,32.7-32.7,32.7H132c-18.1,0-32.7-14.6-32.7-32.7V168.8c0-18.1,14.6-32.7,32.7-32.7h248c18.1,0,32.7,14.6,32.7,32.7V313.2z"
			/>
			<path
				fill="#fff"
				d="M337,389.9c-2.6,2.6-6.7,2.6-9.3,0l-31.3-44.3c-2.6-2.6-2.6-6.7,0-9.3h72c2.6,2.6,2.6,6.7,0,9.3L337,389.9z"
			/>
			<g>
				<path
					fill={barsColor}
					d="M364.9 191.6c0 6.6-5.3 11.9-11.9 11.9H158.2c-6.6 0-11.9-5.3-11.9-11.9l0 0c0-6.6 5.3-11.9 11.9-11.9h194.9C359.6 179.8 364.9 185.1 364.9 191.6L364.9 191.6zM364.9 239.2c0 6.6-5.3 11.9-11.9 11.9H158.2c-6.6 0-11.9-5.3-11.9-11.9l0 0c0-6.6 5.3-11.9 11.9-11.9h194.9C359.6 227.3 364.9 232.6 364.9 239.2L364.9 239.2zM364.9 286.7c0 6.6-5.3 11.9-11.9 11.9H158.2c-6.6 0-11.9-5.3-11.9-11.9l0 0c0-6.6 5.3-11.9 11.9-11.9h194.9C359.6 274.8 364.9 280.1 364.9 286.7L364.9 286.7z"
				/>
			</g>
		</Channel>
	);
};

TextChannel.propTypes = {
	deliveryStatus: PropTypes.string
};

TextChannel.defaultProps = {
	deliveryStatus: undefined
};

export default TextChannel;
