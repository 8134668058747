import React from 'react';

import SimpleSvg from './SimpleSvg';

const Agent = props => (
	<SimpleSvg
		d="M 12 3 A 4 4 0 0 0 8 7 A 4 4 0 0 0 12 11 A 4 4 0 0 0 16 7 A 4 4 0 0 0 12 3 z M 12 14 C 11.686 14 11.334844 14.019734 10.964844 14.052734 L 9.6230469 16.064453 C 9.3260469 16.509453 8.6739531 16.509453 8.3769531 16.064453 L 7.4921875 14.740234 C 5.1331875 15.463234 3 16.715 3 18.5 L 3 20 C 3 20.552 3.448 21 4 21 L 20 21 C 20.552 21 21 20.552 21 20 L 21 18.5 C 21 16.715 18.866812 15.463234 16.507812 14.740234 L 15.623047 16.064453 C 15.326047 16.509453 14.673953 16.509453 14.376953 16.064453 L 13.035156 14.052734 C 12.665156 14.019734 12.314 14 12 14 z"
		{...props}
	/>
);

export default Agent;
