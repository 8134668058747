import React from 'react';

import { CreateLeadContext } from '../contexts';

const withCreateLeadContext = Component => {
	const CreateLeadComponent = props => (
		<CreateLeadContext.Consumer>
			{context => {
				if (!context) {
					return <Component {...props} />;
				}

				const newProps = {
					...props,
					...context
				};

				return <Component {...newProps} />;
			}}
		</CreateLeadContext.Consumer>
	);

	return CreateLeadComponent;
};

export default withCreateLeadContext;
