import { useState, useCallback, useRef, useEffect } from 'react';

export function titleCase(slot) {
	return slot
		.split(/[ _-]/)
		.map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
		.join(' ');
}

export function mapChannel(channel) {
	if (!channel) {
		return null;
	}

	if (channel === 'twilio') {
		return 'SMS';
	}

	if (channel === 'mailgun') {
		return 'Email';
	}

	return null;
}

export function useTextInput(validatorFn, initialValue, transformFn) {
	const normalizedInitialValue =
		initialValue === 0 ? 0 : initialValue || null;

	const [value, setValue] = useState(normalizedInitialValue);
	const previousValueRef = useRef(normalizedInitialValue);
	const [error, setError] = useState(
		validatorFn ? validatorFn(normalizedInitialValue) : null
	);
	const [showError, setShowError] = useState(false);

	const handleChange = useCallback(
		event => {
			let newValue = event?.target?.value || null;
			if (typeof event === 'string' || event instanceof String) {
				newValue = event;
			} else if (!isNaN(event)) {
				newValue = event;
			}

			const transformedValue = transformFn
				? transformFn(newValue)
				: newValue;

			setValue(transformedValue);
			if (validatorFn) {
				setError(validatorFn(transformedValue));
			}
		},
		[transformFn, validatorFn]
	);

	useEffect(() => {
		previousValueRef.current = value;
	}, [value]);

	useEffect(() => {
		if (value !== previousValueRef.current) {
			setShowError(false);
		}
	}, [value]);

	const shouldShowError = useCallback(() => {
		setShowError(true);
	}, [setShowError]);

	return [
		value,
		handleChange,
		!!error,
		showError ? error : undefined,
		shouldShowError
	];
}

export function requiredFieldValidator(message) {
	return value => {
		if (!value) {
			return message;
		}

		return undefined;
	};
}

export function deepEqual(obj1, obj2) {
	if (obj1 === obj2) {
		return true;
	}

	if ([null, undefined].includes(obj1) || [null, undefined].includes(obj2)) {
		return (
			[null, undefined].includes(obj1) ===
			[null, undefined].includes(obj2)
		);
	}

	if (isPrimitive(obj1) && isPrimitive(obj2)) {
		return obj1 === obj2;
	}

	if (Array.isArray(obj1) || Array.isArray(obj2)) {
		if (!Array.isArray(obj1) || !Array.isArray(obj2)) {
			return false;
		}

		if (obj1.length !== obj2.length) {
			return false;
		}

		for (let i = 0; i < obj1.length; i++) {
			if (!deepEqual(obj1[i], obj2[i])) {
				return false;
			}
		}

		return true;
	}

	if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

	// compare objects with same number of keys
	for (let key in obj1) {
		if (!(key in obj2)) return false; //other object doesn't have this prop
		if (!deepEqual(obj1[key], obj2[key])) return false;
	}

	return true;
}

//check if value is primitive
function isPrimitive(obj) {
	return obj !== Object(obj);
}

export function omitTypename(key, value) {
	if (key === '__typename') {
		return undefined;
	}

	return value;
}

export function cleanGraphqlPayload(payload, omitter) {
	return JSON.parse(JSON.stringify(payload), omitter || omitTypename);
}
