import React from 'react';

import Channel from './Channel.jsx';

const VoiceChannel = ({ ...props }) => {
	return (
		<Channel
			stroke="#ffffff"
			fill="#ffffff"
			viewBox="-25.6 -25.6 307.20 307.20"
			{...props}
		>
			<title>Voice channel</title>

			<g strokeWidth="0">
				<rect
					x="-25.6"
					y="-25.6"
					width="307.20"
					height="307.20"
					rx="153.6"
					fill="#05606e"
					strokeWidth="0"
				/>
			</g>
			<g strokeLinecap="round" strokeLinejoin="round" />
			<g>
				<path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.02839,16.02839,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.95139,15.95139,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM157.35156,47.72852a72.12078,72.12078,0,0,1,50.91992,50.91992,7.99975,7.99975,0,0,0,15.457-4.13086,88.16381,88.16381,0,0,0-62.2461-62.2461,7.99975,7.99975,0,0,0-4.13086,15.457Zm-8.28418,30.917a40.06626,40.06626,0,0,1,28.28711,28.28711,7.99975,7.99975,0,0,0,15.457-4.13086,56.10589,56.10589,0,0,0-39.61328-39.61328,7.99975,7.99975,0,0,0-4.13086,15.457Z" />{' '}
			</g>
		</Channel>
	);
};

export default VoiceChannel;
