import styled from 'styled-components';

const ListHeader = styled.div`
	font-size: 13px;
	line-height: 40px;
	text-transform: uppercase;
	padding: 0 16px;
	color: #888;
	border-bottom: 1px solid #eee;
`;

export default ListHeader;
