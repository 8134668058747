const green = {
	50: '',
	100: '',
	200: '',
	300: '',
	400: '',
	500: '',
	600: '',
	700: '',
	800: '',
	900: ''
};

export default green;
