import React from 'react';

import { LeadsFilterContext } from '../contexts.js';

const withLeadFilter = Component => {
	const LeadFilterComponent = props => (
		<LeadsFilterContext.Consumer>
			{filterData => {
				if (!filterData) return <Component />;

				let newProps = {
					...props,
					...filterData
				};

				return <Component {...newProps} />;
			}}
		</LeadsFilterContext.Consumer>
	);

	return LeadFilterComponent;
};

export default withLeadFilter;
