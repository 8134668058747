import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

const ProtectedRoute = ({ path, isAuthenticated, ...props }) => {
	if (isAuthenticated) {
		return <Route path={path} {...props} />;
	} else {
		return <Redirect to="/" />;
	}
};

ProtectedRoute.propTypes = {
	path: PropTypes.string.isRequired,
	isAuthenticated: PropTypes.bool.isRequired
};

export default ProtectedRoute;
