import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import {
	MUTATE_ASSIGN_CUSTOMER_TO_AGENT,
	MUTATE_ASSIGN_CUSTOMER_TO_TEAM,
	MUTATE_ADD_AGENT_TO_TEAM,
	MUTATE_ADD_AGENT_TO_BROKERAGE,
	MUTATE_ADD_TEAM_TO_BROKERAGE
} from '../mutations.js';
import {
	QUERY_AGENT_CUSTOMERS,
	QUERY_TEAM_CUSTOMERS,
	QUERY_BROKERAGE_CUSTOMERS
} from '../queries.js';
import { withUser } from '../providers';
import UrlHandler from '../components/UrlHandler.jsx';

/** GraphQL Container **/
const withMutateAssignCustomerAgent = graphql(MUTATE_ASSIGN_CUSTOMER_TO_AGENT, {
	props: ({
		mutate,
		ownProps: { agentId, teamId, brokerageId, isTeamLead, isBroker }
	}) => ({
		assignCustomerToAgent(customerId) {
			let refetchQueries = [];

			if (isBroker) {
				refetchQueries.push({
					query: QUERY_BROKERAGE_CUSTOMERS,
					variables: { id: brokerageId }
				});
			} else if (isTeamLead) {
				refetchQueries.push({
					query: QUERY_TEAM_CUSTOMERS,
					variables: { id: teamId }
				});
			} else {
				refetchQueries.push({
					query: QUERY_AGENT_CUSTOMERS,
					variables: { id: agentId }
				});
			}

			return mutate({
				variables: { agentId, customerId },
				refetchQueries
			});
		}
	})
});

const withMutateAssignCustomerTeam = graphql(MUTATE_ASSIGN_CUSTOMER_TO_TEAM, {
	props: ({
		mutate,
		ownProps: { agentId, teamId, brokerageId, isTeamLead, isBroker }
	}) => ({
		assignCustomerToTeam(customerId) {
			let refetchQueries = [];

			if (isBroker) {
				refetchQueries.push({
					query: QUERY_BROKERAGE_CUSTOMERS,
					variables: { id: brokerageId }
				});
			} else if (isTeamLead) {
				refetchQueries.push({
					query: QUERY_TEAM_CUSTOMERS,
					variables: { id: teamId }
				});
			} else {
				refetchQueries.push({
					query: QUERY_AGENT_CUSTOMERS,
					variables: { id: agentId }
				});
			}

			return mutate({
				variables: { teamId, customerId },
				refetchQueries
			});
		}
	})
});

const withMutateAddAgentToTeam = graphql(MUTATE_ADD_AGENT_TO_TEAM, {
	props: ({ mutate }) => ({
		addAgentToTeam(teamId, agentId, accessToken) {
			return mutate({
				variables: {
					teamId,
					agentId,
					accessToken
				}
			});
		}
	})
});

const withMutateAddAgentToBrokerage = graphql(MUTATE_ADD_AGENT_TO_BROKERAGE, {
	props: ({ mutate }) => ({
		addAgentToBrokerage(brokerageId, agentId, accessToken) {
			return mutate({
				variables: {
					brokerageId,
					agentId,
					accessToken
				}
			});
		}
	})
});

const withMutateAddTeamToBrokerage = graphql(MUTATE_ADD_TEAM_TO_BROKERAGE, {
	props: ({ mutate }) => ({
		addTeamToBrokerage(brokerageId, teamId, accessToken) {
			return mutate({
				variables: {
					brokerageId,
					teamId,
					accessToken
				}
			});
		}
	})
});

const withMainLayoutActions = compose(
	withMutateAssignCustomerAgent,
	withMutateAssignCustomerTeam,
	withMutateAddAgentToTeam,
	withMutateAddAgentToBrokerage,
	withMutateAddTeamToBrokerage
);

export default compose(withRouter, withUser, withMainLayoutActions)(UrlHandler);
