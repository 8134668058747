import React from 'react';

import { ActingUserContext } from '../contexts';

const withActingUser = Component => {
	const UserComponent = props => (
		<ActingUserContext.Consumer>
			{userContext => {
				if (!userContext) return <Component />;
				const { actingUser, updateActingUser } = userContext;

				let newProps = {
					...props,
					actingUser,
					updateActingUser
				};

				return <Component {...newProps} />;
			}}
		</ActingUserContext.Consumer>
	);

	return UserComponent;
};

export default withActingUser;
