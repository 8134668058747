import React from 'react';
import PropTypes from 'prop-types';

import StyledRadioGroup from './StyledRadioGroup.jsx';

function RadioGroup(props) {
	const {
		label,
		name,
		required,
		children,
		selected,
		readOnly,
		onChange,
		onSubmit,
		size,
		...other
	} = props;

	const renderLabel = () => {
		if (readOnly) {
			return <span>{label}</span>;
		} else {
			return (
				<span>
					{label}
					<b>{required ? ' *' : ''}</b>
				</span>
			);
		}
	};

	return (
		<StyledRadioGroup size={size} {...other}>
			{renderLabel()}
			{React.Children.map(children, child =>
				React.cloneElement(child, {
					name,
					size,
					selectedOption: selected,
					onChange: readOnly ? () => null : onChange,
					onSubmit: readOnly ? null : onSubmit,
					readOnly
				})
			)}
		</StyledRadioGroup>
	);
}

RadioGroup.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	selected: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	size: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};

RadioGroup.defaultProps = {
	required: false,
	readOnly: false,
	selected: '',
	size: '',
	onSubmit: null
};

export default RadioGroup;
