import styled, { css } from 'styled-components';

const value = ({ value, size, theme: { palette } }) => {
	const valueSize = () => {
		if (size === 'sm') {
			return css`
				box-shadow: 0 0 4px -2px ${palette.primary.default};

				& > span {
					box-shadow: 0 0 4px -2px ${palette.primary.default};
				}

				&:after {
					margin-left: -17px;
				}
			`;
		} else {
			return css`
				box-shadow: 0 0 6px -3px ${palette.primary.default};

				& > span {
					box-shadow: 0 0 6px -3px ${palette.primary.default};
				}

				&:after {
					margin-left: -21px;
				}
			`;
		}
	};

	if (value) {
		return css`
			& > span {
				background-color: ${palette.primary.default};
			}

			&:after {
				left: 100%;
			}

			${valueSize};
		`;
	}
};

const disabled = ({ disabled, theme: { palette } }) => {
	if (disabled) {
		return css`
			box-shadow: none;
			cursor: not-allowed;

			& > span {
				opacity: 0.4;
				box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);
			}

			&:after {
				cursor: not-allowed;
			}
		`;
	}
};

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			min-width: 34px;
			max-height: 18px;
			line-height: 18px;

			& > span {
				height: 18px;
			}

			&:after {
				top: 2px;
				left: 3px;
				width: 14px;
				height: 14px;
			}
		`;
	} else {
		return css`
			min-width: 44px;
			max-height: 24px;
			line-height: 20px;

			& > span {
				height: 24px;
			}

			&:after {
				top: 3px;
				left: 4px;
				width: 18px;
				height: 18px;
			}
		`;
	}
};

const StyledToggle = styled.button`
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	background-color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	list-style: none;
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.36s;
	outline: none;
	border: none;

	& > span {
		display: inherit;
		transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		width: 100%;
		border-radius: 12px;
		font-size: 12px;
		background-color: ${({ theme: { palette } }) => palette.grey.l1};
	}

	&:after {
		content: ' ';
		position: absolute;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
		border-radius: 50%;
		cursor: pointer;
		transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}

	${size};
	${value};
	${disabled};
`;

export default StyledToggle;
