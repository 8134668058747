import React from 'react';
import PropTypes from 'prop-types';

import { VoiceCallItem } from './VoiceCallItem.jsx';

const VoiceCallSummaryWidget = ({
	totalCalls,
	callsThisInterval,
	callSuccessCount,
	callPendingCount,
	updateCallDispositionFilters
}) => {
	return (
		<div className="stages-widget">
			<div className="stages-header">
				<h1 className="title">Calling</h1>
			</div>
			<div className="stages-list">
				<VoiceCallItem
					label="Total Calls"
					total={totalCalls}
					interval={callsThisInterval}
				/>
				<VoiceCallItem
					label="Success"
					total={callSuccessCount}
					isLead={true}
					callDispositions={['Connected', 'Appointment Set']}
					updateCallDispositionFilters={updateCallDispositionFilters}
				/>
				<VoiceCallItem
					label="Pending"
					total={callPendingCount}
					isLead={true}
					callDispositions={['Call Back Later', 'Reconnect Later']}
					updateCallDispositionFilters={updateCallDispositionFilters}
				/>
			</div>
		</div>
	);
};

VoiceCallSummaryWidget.propTypes = {
	totalCalls: PropTypes.number.isRequired,
	callsThisInterval: PropTypes.number.isRequired,
	callSuccessCount: PropTypes.number.isRequired,
	callPendingCount: PropTypes.number.isRequired,
	updateCallDispositionFilters: PropTypes.func.isRequired
};

export default VoiceCallSummaryWidget;
