import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ChevronRight } from 'modules/blink/icons';
import StageItem from './StageItem.jsx';

class StagesWidget extends PureComponent {
	constructor(props) {
		super(props);

		this.onItemClick = this.onItemClick.bind(this);
	}

	static propTypes = {
		respondedCount: PropTypes.number,
		notRespondedCount: PropTypes.number,
		interestedCount: PropTypes.number,
		notInterestedCount: PropTypes.number,
		needsFollowUpCount: PropTypes.number,
		updateStageFilters: PropTypes.func.isRequired
	};

	static defaultProps = {
		respondedCount: 0,
		notRespondedCount: 0,
		interestedCount: 0,
		notInterestedCount: 0,
		needsFollowUpCount: 0
	};

	onItemClick(stage) {
		const { updateStageFilters } = this.props;

		updateStageFilters([stage]);
	}

	render() {
		const {
			respondedCount,
			notRespondedCount,
			interestedCount,
			notInterestedCount,
			needsFollowUpCount
		} = this.props;

		return (
			<div className="stages-widget">
				<div className="stages-header">
					<h1 className="title">Stages</h1>
					<Link to="/inbox" className="link-btn">
						<span className="label">See all leads</span>
						<ChevronRight
							color="#2a354f"
							width="16px"
							height="16px"
						/>
					</Link>
				</div>
				<div className="stages-list">
					<StageItem
						type="responded"
						label="Responded"
						value={respondedCount}
						onClick={this.onItemClick}
					/>
					<StageItem
						type="not_responded"
						label="Unresponsive"
						value={notRespondedCount}
						onClick={this.onItemClick}
					/>
					<StageItem
						type="interested"
						label="Interested"
						value={interestedCount}
						onClick={this.onItemClick}
					/>
					<StageItem
						type="not_interested"
						label="Not Interested"
						value={notInterestedCount}
						onClick={this.onItemClick}
					/>
					<StageItem
						type="needs_follow_up"
						label="Agent Follow-Up"
						value={needsFollowUpCount}
						onClick={this.onItemClick}
					/>
				</div>
			</div>
		);
	}
}

export default StagesWidget;
