import styled, { css, keyframes } from 'styled-components';

const color = ({ kind, theme: { palette } }) => {
	switch (kind) {
		case 'primary':
			return css`
				border-color: ${palette.primary.default};
			`;
		case 'secondary':
			return css`
				border-color: ${palette.secondary.default};
			`;
		case 'danger':
			return css`
				border-color: ${palette.red.default};
			`;
		default:
			return css`
				border-color: ${palette.common.white};
			`;
	}
};

const ghost = ({ ghost, theme: { palette } }) => {
	if (ghost) {
		return css`
			border-left-color: transparent;
		`;
	} else {
		return css`
			border-left-color: ${palette.common.white};
		`;
	}
};

const tint = ({ tint, theme: { palette } }) => {
	if (tint) {
		return css`
			border-left-color: ${palette.grey[100]};
		`;
	}
};

const size = ({ size }) => {
	switch (size) {
		case 'xs':
			return css`
				border: 2px solid;
				width: 15px;
				height: 15px;
			`;
		case 'sm':
			return css`
				border: 3px solid;
				width: 25px;
				height: 25px;
			`;
		case 'md':
			return css`
				border: 4px solid;
				width: 30px;
				height: 30px;
			`;
		case 'lg':
			return css`
				border: 5px solid;
				width: 50px;
				height: 50px;
			`;
		case 'xl':
			return css`
				border: 6px solid;
				width: 75px;
				height: 75px;
			`;
		default:
			return css`
				border: 4px solid;
				width: 30px;
				height: 30px;
			`;
	}
};

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
`;

const StyledSpinner = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	& > span {
		transform: translateZ(0);
		animation: ${spin} 1.1s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
		border-radius: 50%;

		${size};
		${color};
		${ghost};
		${tint};
	}
`;

export default StyledSpinner;
