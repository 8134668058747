import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Menu from '../Menu';
import Spinner from '../Spinner';
import StyledAutoComplete from './StyledAutoComplete.jsx';

function AutoComplete(props) {
	const { onSelect, options, size, selected, loading } = props;

	const [searchedValue, changeSearchedValue] = useState('');
	let ref = useRef();

	const handleInputKeys = e => {
		if (e.keyCode === 40) {
			if (
				ref &&
				ref.current &&
				ref.current.lastChild &&
				ref.current.lastChild.firstChild
			) {
				ref.current.lastChild.firstChild.focus();
			}
		}
	};

	const getOptions = () => {
		return options.filter(item => {
			return (
				item.label.toLowerCase().indexOf(searchedValue.toLowerCase()) >
				-1
			);
		});
	};

	const renderMenu = () => {
		let options = getOptions();

		if (loading) {
			return (
				<div className="select-loading">
					<Spinner size="xs" kind="primary" ghost={true} />
				</div>
			);
		} else if (Array.isArray(options) && options.length === 0) {
			return <div className="select-placeholder">No results</div>;
		} else {
			return (
				<Menu
					size={size}
					items={options}
					selected={selected}
					onSelectItem={onSelect}
					onKeyDown={e => e.preventDefault()}
				/>
			);
		}
	};

	return (
		<StyledAutoComplete ref={ref}>
			<div className="select-input">
				<input
					value={searchedValue}
					tabIndex={0}
					onChange={e => changeSearchedValue(e.currentTarget.value)}
					onKeyDown={handleInputKeys}
					autoFocus
				/>
			</div>
			{renderMenu()}
		</StyledAutoComplete>
	);
}

AutoComplete.propTypes = {
	size: PropTypes.string,
	loading: PropTypes.bool,
	selected: PropTypes.string,
	options: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired
};

AutoComplete.defaultProps = {
	size: '',
	selected: '',
	loading: false
};

export default AutoComplete;
