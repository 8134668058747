import React from 'react';

import { MultiConversationsContext } from '../contexts';

const withMultiConversations = Component => {
	const MultiConversationsComponent = props => (
		<MultiConversationsContext.Consumer>
			{multiConversationsContext => {
				if (!multiConversationsContext) return <Component />;
				const { allowMultiConversations } = multiConversationsContext;

				let newProps = {
					...props,
					allowMultiConversations
				};

				return <Component {...newProps} />;
			}}
		</MultiConversationsContext.Consumer>
	);

	return MultiConversationsComponent;
};

export default withMultiConversations;
