import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import moment from 'moment';

import { Button, Input, Select, AutoComplete } from 'modules/blink/components';
import Radio, { RadioGroup } from 'modules/blink/components/Radio';
import { comparisonOperators } from '../globals';

function ConditionForm(props) {
	const {
		slot: defaultSlot,
		operator: defaultOperator,
		value: defaultValue,
		getSlot,
		onSubmit,
		options
	} = props;

	const [slot, changeSlot] = useState(defaultSlot);
	const [operator, changeOperator] = useState(defaultOperator);
	const [value, changeValue] = useState(defaultValue);
	const [selected, changeSelected] = useState(null);

	const { type, label, options: slotOptions, required } = getSlot(
		slot,
		value
	);

	const submit = () => {
		if (!selected || !slot || !operator) return;

		let newValue;
		let newOperator = operator;
		if (Array.isArray(value)) {
			newValue = value.filter(
				(el, index, self) =>
					(typeof el === 'number' || !!el) &&
					self.findIndex(t => t === el) === index
			);

			if (newValue.length === 0) {
				if (type === 'numeric') {
					newValue = [0];
				} else {
					newValue = null;
					newOperator = comparisonOperators.EQ;
				}
			}
		} else if (value === '' && type === 'numeric') {
			newValue = 0;
		} else {
			newValue = value;
		}

		onSubmit({ slot, operator: newOperator, value: newValue });
	};

	const updateItem = item => {
		const { operator, value } = item;

		changeOperator(operator);
		changeValue(value);
	};

	const updateItemValue = (newValue, index) => {
		let values;
		if (Array.isArray(value)) {
			values = value.slice(0);
		} else {
			values = [];
		}

		changeValue(
			values.map((value, itemIndex) => {
				if (itemIndex === index) {
					return newValue;
				} else {
					return value;
				}
			})
		);
	};

	const addItemValue = newValue => {
		let values;
		if (Array.isArray(value)) {
			values = value.slice(0);
		} else {
			values = [];
		}

		changeValue([...values, newValue]);
	};

	const getOperatorOptions = () => {
		const {
			EQ,
			NE,
			IN,
			NIN,
			GT,
			GTE,
			LT,
			LTE,
			INC,
			CI_INC
		} = comparisonOperators;

		switch (type) {
			case 'datetime':
				return [
					{
						operator: LT,
						label: 'before',
						inputType: 'datetime',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: GT,
						label: 'after',
						inputType: 'datetime',
						value: Array.isArray(value) ? value[0] : value
					}
				];
			case 'string':
				return [
					{
						operator: EQ,
						label: 'is',
						inputType: 'string',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: INC,
						label: 'includes (case sensitive)',
						inputType: 'string',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: CI_INC,
						label: 'includes (case in-sensitive)',
						inputType: 'string',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: NE,
						label: 'is not',
						inputType: 'string',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: IN,
						label: 'is in',
						inputType: 'array-string',
						value: Array.isArray(value) ? value : [value]
					},
					{
						operator: NIN,
						label: 'is not in',
						inputType: 'array-string',
						value: Array.isArray(value) ? value : [value]
					},
					{
						operator: EQ,
						label: 'is unknown',
						value: null
					},
					{
						operator: NE,
						label: 'has any value',
						value: null
					}
				];
			case 'numeric':
				return [
					{
						operator: EQ,
						label: 'is',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: NE,
						label: 'is not',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: IN,
						label: 'is in',
						inputType: 'array-number',
						value: Array.isArray(value) ? value : [value]
					},
					{
						operator: NIN,
						label: 'is not in',
						inputType: 'array-number',
						value: Array.isArray(value) ? value : [value]
					},
					{
						operator: GT,
						label: 'is greater than',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: GTE,
						label: 'is greater than or equal to',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: LT,
						label: 'is less than',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: LTE,
						label: 'is less than or equal to',
						inputType: 'number',
						value: Array.isArray(value) ? value[0] : value
					},
					{
						operator: EQ,
						label: 'is unknown',
						value: null
					},
					{
						operator: NE,
						label: 'has any value',
						value: null
					}
				];
			case 'boolean':
				return [
					{
						operator: EQ,
						label: 'is true',
						value: true
					},
					{
						operator: EQ,
						label: 'is false',
						value: false
					},
					{
						operator: EQ,
						label: 'is unknown',
						value: null
					},
					{
						operator: NE,
						label: 'has any value',
						value: null
					}
				];
			case 'fixed':
				if (required) {
					return [
						{
							operator: EQ,
							label: 'is',
							inputType: 'fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value[0] : value
						},
						{
							operator: NE,
							label: 'is not',
							inputType: 'fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value[0] : value
						}
					];
				} else {
					return [
						{
							operator: EQ,
							label: 'is',
							inputType: 'fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value[0] : value
						},
						{
							operator: NE,
							label: 'is not',
							inputType: 'fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value[0] : value
						},
						{
							operator: IN,
							label: 'is in',
							inputType: 'array-fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value : [value]
						},
						{
							operator: NIN,
							label: 'is not in',
							inputType: 'array-fixed',
							options: slotOptions,
							value: Array.isArray(value) ? value : [value]
						},
						{
							operator: EQ,
							label: 'is unknown',
							value: null
						},
						{
							operator: NE,
							label: 'has any value',
							value: null
						}
					];
				}
			default:
				return [];
		}
	};

	const renderOptionValueForm = (inputType, options, value) => {
		switch (inputType) {
			case 'string':
				return (
					<Input
						autoFocus={true}
						size="sm"
						value={typeof value === 'string' ? value : ''}
						onChange={e => changeValue(e.currentTarget.value)}
					/>
				);
			case 'number':
				return (
					<Input
						autoFocus={true}
						type="number"
						size="sm"
						min={0}
						value={value || 0}
						onChange={e =>
							changeValue(parseInt(e.currentTarget.value))
						}
						onKeyDown={e => {
							if (e.keyCode !== 13) {
								e.stopPropagation();
							}
						}}
					/>
				);
			case 'fixed':
				return (
					<Select
						selected={options.find(
							option => option.value === value
						)}
						size="sm"
						options={options}
						placeholder={`Select ${label}`}
						onChange={changeValue}
					/>
				);
			case 'datetime':
				return (
					<form noValidate>
						<TextField
							id="date"
							type="date"
							defaultValue={
								value
									? moment(value * 1000).format('YYYY-MM-DD')
									: ''
							}
							onChange={e => {
								changeValue(
									moment(e.currentTarget.value).valueOf() /
										1000
								);
							}}
						/>
					</form>
				);
			case 'array-string':
				return (
					<div className="option-value-multi-form">
						{value.map((itemValue, index) => (
							<Input
								key={index}
								size="sm"
								autoFocus={index === 0}
								value={itemValue || ''}
								onChange={e =>
									updateItemValue(
										e.currentTarget.value,
										index
									)
								}
							/>
						))}
						<Button
							kind="primary"
							ghost={true}
							size="sm"
							onClick={() => addItemValue('')}
							className="add-value-button"
						>
							Add value
						</Button>
					</div>
				);
			case 'array-number':
				return (
					<div className="option-value-multi-form">
						{value.map((itemValue, index) => (
							<Input
								key={index}
								autoFocus={index === 0}
								type="number"
								size="sm"
								min={0}
								value={itemValue || 0}
								onChange={e =>
									updateItemValue(
										parseInt(e.currentTarget.value),
										index
									)
								}
								onKeyDown={e => {
									if (e.keyCode !== 13) {
										e.stopPropagation();
									}
								}}
							/>
						))}
						<Button
							kind="primary"
							ghost={true}
							size="sm"
							onClick={() => addItemValue(0)}
							className="add-value-button"
						>
							Add value
						</Button>
					</div>
				);
			case 'array-fixed':
				return (
					<div className="option-value-multi-form">
						{value.map((itemValue, index) => (
							<Select
								key={index}
								selected={options.find(
									option => option.value === itemValue
								)}
								size="sm"
								options={options}
								placeholder={`Select ${label}`}
								onChange={value =>
									updateItemValue(value, index)
								}
							/>
						))}
						<Button
							kind="primary"
							ghost={true}
							size="sm"
							onClick={() => addItemValue(null)}
							className="add-value-button"
						>
							Add value
						</Button>
					</div>
				);
			default:
				return null;
		}
	};

	const renderOperatorOption = (item, index) => {
		const {
			operator: itemOperator,
			label,
			inputType,
			options,
			value: itemValue
		} = item;

		let autoFocus =
			!selected &&
			((!operator && index === 0) ||
				(operator === itemOperator && value === itemValue) ||
				(operator === itemOperator &&
					Array.isArray(value) &&
					Array.isArray(itemValue)));

		return (
			<Radio
				key={index}
				label={label}
				value={`${index}`}
				autoFocus={autoFocus}
			>
				{`${index}` === selected &&
					renderOptionValueForm(inputType, options, value)}
			</Radio>
		);
	};

	if (slot) {
		let items = getOperatorOptions();

		return (
			<StyledConditionForm>
				<RadioGroup
					size="sm"
					className="operator-form"
					label={label}
					name="operator-form"
					selected={selected}
					onChange={value => {
						changeSelected(value);
						updateItem(items[parseInt(value)]);
					}}
					onSubmit={submit}
				>
					{getOperatorOptions().map(renderOperatorOption)}
				</RadioGroup>
				<div className="operator-form-footer">
					<Button
						kind="primary"
						ghost={true}
						block={true}
						onClick={submit}
					>
						{!defaultOperator ? 'Add' : 'Update'}
					</Button>
				</div>
			</StyledConditionForm>
		);
	} else {
		return (
			<AutoComplete onSelect={changeSlot} options={options} size="sm" />
		);
	}
}

ConditionForm.propTypes = {
	slot: PropTypes.string,
	operator: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.number,
		PropTypes.bool
	]),
	options: PropTypes.array,
	getSlot: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};

ConditionForm.defaultProps = {
	slot: '',
	operator: '',
	value: '',
	options: []
};

const StyledConditionForm = styled.div`
	min-width: 200px;
	height: auto;
	top: 100%;
	left: 0;
	background-color: #fff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;

	& > .operator-form {
		padding: 12px;

		.option-value-multi-form {
			display: flex;
			flex-direction: column;

			& > *:not(:last-child) {
				margin-bottom: 4px;
			}

			.add-value-button {
				align-self: flex-end;
				margin-top: 4px;
			}
		}
	}

	& > .operator-form-footer {
		border-top: 1px solid #eee;
		margin-top: 8px;
	}
`;

export default ConditionForm;
