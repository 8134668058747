import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InitialCircle extends Component {
	constructor(props) {
		super(props);
		this.colors = [
			'#F44336',
			'#673AB7',
			'#9C27B0',
			'#3F51B5',
			'#2196F3',
			'#009688',
			'#4CAF50',
			'#FF9800',
			'#FF5722'
		];
	}

	static propTypes = {
		name: PropTypes.string,
		avatar: PropTypes.string,
		color: PropTypes.string,
		children: PropTypes.element,
		id: PropTypes.string,
		onClick: PropTypes.func
	};

	static defaultProps = {
		name: '',
		avatar: '',
		color: '',
		id: '',
		children: null,
		onClick: () => null
	};

	renderContent() {
		const { name, avatar, children } = this.props;

		let initials = '';
		if (avatar) {
			return <img alt="avatar" className="avatar-img" src={avatar} />;
		} else if (name) {
			// eslint-disable-next-line no-useless-escape
			let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
			if (name.includes('@')) {
				initials = '@';
			} else if (phoneReg.test(name)) {
				initials = '#';
			} else {
				initials = name.split(' ');
				if (initials.length < 2) {
					initials = initials[0].charAt(0).toUpperCase();
				} else {
					initials =
						initials[0].charAt(0).toUpperCase() +
						initials[1].charAt(0).toUpperCase();
				}
			}

			return <span className="initials">{initials}</span>;
		} else if (children) {
			return children;
		}

		return null;
	}

	render() {
		const { id, color, onClick } = this.props;

		let backgroundColor = color
			? color
			: this.colors[id.charCodeAt(id.length - 1) % 9];

		return (
			<div
				className="initial-circle"
				style={{ backgroundColor: backgroundColor }}
				onClick={onClick}
			>
				{this.renderContent()}
			</div>
		);
	}
}

export default InitialCircle;
