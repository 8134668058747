import React from 'react';

import SimpleSvg from './SimpleSvg';

const ArrowDown = props => (
	<SimpleSvg
		d="M5.707,10.707l5.586,5.586c0.391,0.391,1.024,0.391,1.414,0l5.586-5.586C18.923,10.077,18.477,9,17.586,9H6.414 C5.523,9,5.077,10.077,5.707,10.707z"
		{...props}
	/>
);

export default ArrowDown;
