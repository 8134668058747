import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useEntityQuery } from 'modules/core/helpers';
import { Spinner } from 'modules/core/components';

import RoiItem from './RoiItem.jsx';
import RoiInput from './RoiInput.jsx';

import { getNumberWithCommas } from '../helpers';
import {
	QUERY_AGENT_ROI,
	QUERY_TEAM_ROI,
	QUERY_BROKERAGE_ROI
} from '../queries';

function RoiWidget({ user }) {
	const [
		appointmentSetToCloseRatio,
		setAppointmentSetToCloseRatio
	] = useState();

	const [averageSellingPrice, setAverageSellingPrice] = useState();

	const [averageCommissionRate, setAverageComissionRate] = useState();

	const [hasChanges, setHasChanges] = useState(false);

	const [queryVariables, setQueryVariables] = useState({
		id: user.brokerageId,
		appointmentSetToCloseRatio: null,
		averageSellingPrice: null,
		averageCommissionRate: null
	});

	const [roiData, setRoiData] = useState();

	const { loading: isLoading } = useEntityQuery(
		QUERY_AGENT_ROI,
		QUERY_TEAM_ROI,
		QUERY_BROKERAGE_ROI,
		user,
		{
			variables: queryVariables,
			onCompleted: data => {
				setAppointmentSetToCloseRatio(
					getNumberWithCommas(
						data.brokerage.structurelyRoi.appointmentSetToCloseRatio
					)
				);
				setAverageSellingPrice(
					getNumberWithCommas(
						data.brokerage.structurelyRoi.averageSellingPrice
					)
				);
				setAverageComissionRate(
					getNumberWithCommas(
						data.brokerage.structurelyRoi.averageCommissionRate
					)
				);
				setRoiData(data.brokerage.structurelyRoi);
				setQueryVariables({
					...queryVariables,
					isBrokerage: data.brokerage.structurelyRoi.isBrokerage,
					appointmentSetToCloseRatio:
						data.brokerage.structurelyRoi
							.appointmentSetToCloseRatio,
					averageSellingPrice: parseFloat(
						data.brokerage.structurelyRoi.averageSellingPrice
							.toString()
							.replace(/[^0-9.]+/g, '')
					),
					averageCommissionRate:
						data.brokerage.structurelyRoi.averageCommissionRate
				});
			}
		}
	);

	const updateQueryVariables = useCallback(() => {
		setQueryVariables({
			...queryVariables,
			appointmentSetToCloseRatio: parseFloat(appointmentSetToCloseRatio),
			averageSellingPrice: parseFloat(
				averageSellingPrice.replace(/[^0-9.]+/g, '')
			),
			averageCommissionRate: parseFloat(averageCommissionRate)
		});
	}, [
		queryVariables,
		appointmentSetToCloseRatio,
		averageSellingPrice,
		averageCommissionRate
	]);

	if (
		!roiData ||
		!roiData.roi ||
		roiData.roi <= 0 ||
		!roiData.pipelineInfluencedRevenue ||
		roiData.pipelineInfluencedRevenue <= 0
	) {
		return null;
	}

	return (
		<div className="roi-widget">
			<div className="section-header roi-header">
				<h1 className="title">Your ROI</h1>
			</div>
			<div className="section-content paper">
				<p className="roi-summary">
					<span className="roi-calculation">
						{getNumberWithCommas(roiData.roi)}x ROI
					</span>
					($
					{getNumberWithCommas(
						roiData.comissionInfluencedRevenue
					)}{' '}
					commissions influenced / $
					{getNumberWithCommas(roiData.structurelyPayments)} all time
					Structurely Payments)
					{isLoading && <Spinner xs />}
				</p>
				<div className="roi-content">
					<div className="roi-items">
						<RoiItem
							label="Leads Processed"
							value={roiData.totalLeadsProcessed}
						/>
						<RoiItem
							label="Appointments Set"
							value={roiData.totalAppointmentsSet}
						/>
						<RoiItem
							label="Closed Won Deals"
							value={roiData.totalClosedWon}
						/>
						<RoiItem
							label="Pipeline Influenced"
							isCurrency={true}
							isLoading={isLoading}
							value={roiData.pipelineInfluencedRevenue}
						/>
						<RoiItem
							label="Commissions Influenced"
							isCurrency={true}
							isLoading={isLoading}
							value={roiData.comissionInfluencedRevenue}
						/>
					</div>
					<div className="roi-inputs">
						<RoiInput
							label="Your appointment set to close ratio"
							value={appointmentSetToCloseRatio}
							isReadOnly={!queryVariables.isBrokerage}
							onChange={e => {
								setAppointmentSetToCloseRatio(
									e.currentTarget.value
								);
								setHasChanges(true);
							}}
						/>
						<RoiInput
							label="Your average selling price"
							value={averageSellingPrice}
							isReadOnly={!queryVariables.isBrokerage}
							isCurrency={true}
							onChange={e => {
								setAverageSellingPrice(e.currentTarget.value);
								setHasChanges(true);
							}}
						/>
						<RoiInput
							label="Your average commission cut"
							value={averageCommissionRate}
							isReadOnly={!queryVariables.isBrokerage}
							onChange={e => {
								setAverageComissionRate(e.currentTarget.value);
								setHasChanges(true);
							}}
						/>
						{queryVariables.isBrokerage && (
							<p className="roi-calculate">
								<button
									className={`btn submit-btn ${
										hasChanges ? 'success-btn' : 'disabled'
									}`}
									onClick={e => updateQueryVariables()}
								>
									Calculate ROI
								</button>
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

RoiWidget.propTypes = {
	user: PropTypes.object.isRequired
};

export default RoiWidget;
