import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import { xorWith, isEqual, isEmpty } from 'lodash';

class InfiniteList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			doneFetching: false
		};

		this.isRowLoaded = this.isRowLoaded.bind(this);
		this.loadMoreRows = this.loadMoreRows.bind(this);
	}

	static propTypes = {
		className: PropTypes.string,
		containerHeight: PropTypes.number,
		containerWidth: PropTypes.number,
		fetchMoreOffset: PropTypes.number,
		isDoneFetching: PropTypes.bool,
		list: PropTypes.array,
		totalItemCount: PropTypes.number,
		onFetchMore: PropTypes.func,
		itemHeight: PropTypes.number.isRequired,
		itemCount: PropTypes.number.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		noItemsRenderer: PropTypes.func.isRequired
	};

	static defaultProps = {
		className: '',
		containerHeight: 0,
		containerWidth: 0,
		fetchMoreOffset: 15,
		totalItemCount: 999999,
		isDoneFetching: false,
		onFetchMore: () => null,
		list: []
	};

	componentDidUpdate(prevProps) {
		const { list } = this.props;

		if (!this.isArrayEqual(list, prevProps)) {
			this.list.forceUpdateGrid();
		}
	}

	isArrayEqual(arr1, arr2) {
		return isEmpty(xorWith(arr1, arr2, isEqual));
	}

	loadMoreRows() {
		const { onFetchMore, isDoneFetching } = this.props;

		if (
			!isDoneFetching &&
			onFetchMore &&
			typeof onFetchMore == 'function'
		) {
			onFetchMore();
		}
	}

	isRowLoaded({ index }) {
		const { list } = this.props;

		return !!list[index];
	}

	render() {
		const {
			className,
			itemHeight,
			itemCount,
			totalItemCount,
			itemRenderer,
			noItemsRenderer,
			containerHeight,
			containerWidth,
			fetchMoreOffset
		} = this.props;

		return (
			<InfiniteLoader
				isRowLoaded={this.isRowLoaded}
				loadMoreRows={this.loadMoreRows}
				threshold={fetchMoreOffset}
				rowCount={totalItemCount}
			>
				{({ onRowsRendered, registerChild }) => (
					<AutoSizer
						disableHeight={!!containerHeight}
						disableWidth={!!containerWidth}
					>
						{({ height, width }) => (
							<List
								ref={node => {
									this.list = node;
									registerChild(node);
								}}
								onRowsRendered={onRowsRendered}
								className={`infinite-list ${className}`}
								height={containerHeight || height}
								width={containerWidth || width}
								rowCount={itemCount}
								rowHeight={itemHeight}
								rowRenderer={itemRenderer}
								noRowsRenderer={noItemsRenderer}
							/>
						)}
					</AutoSizer>
				)}
			</InfiniteLoader>
		);
	}
}

export default InfiniteList;
