import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ label, status, onToggle }) => (
	<span className={'toggle' + (status ? ' active' : '')}>
		{label ? <span className="label">{label}</span> : null}
		<span onClick={() => onToggle(!status)} className="toggle-well">
			<span className="toggle-handle">
				<span className="handle-groove" />
				<span className="handle-groove" />
			</span>
		</span>
	</span>
);

Toggle.defaultProps = {
	label: ''
};

Toggle.propTypes = {
	label: PropTypes.string,
	status: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired
};

export default Toggle;
