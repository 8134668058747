import React from 'react';
import PropTypes from 'prop-types';

import StyledEvent from './StyledEvent.jsx';

function Event(props) {
	const { kind, renderIcon, forceShowTail, children, ...other } = props;

	const renderHead = () => {
		if (!renderIcon || typeof renderIcon !== 'function') return null;

		return <div className="event-head">{renderIcon()}</div>;
	};

	return (
		<StyledEvent kind={kind} forceShowTail={forceShowTail} {...other}>
			<div className="event-tail" />
			{renderHead()}
			<div className="event-content">{children}</div>
		</StyledEvent>
	);
}

Event.propTypes = {
	kind: PropTypes.oneOf(['action', 'primary', 'secondary']),
	renderIcon: PropTypes.func,
	forceShowTail: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	])
};

Event.defaultProps = {
	kind: 'secondary',
	forceShowTail: false,
	renderIcon: null,
	children: null
};

export default Event;
