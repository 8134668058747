const defaults = {
	fontFamily: "'Heebo', sans-serif",
	fontSizes: [12, 14, 16, 20, 24, 32, 40, 56, 72, 96],
	fontSize: 14,
	fontWeights: { light: 300, regular: 400, medium: 500, bold: 700 },
	htmlFontSize: 16
};

function createTypography(typography) {
	const {
		fontFamily = defaults.fontFamily,
		fontSize = defaults.fontSize,
		fontSizes = defaults.fontSizes,
		fontWeights = defaults.fontWeights,
		htmlFontSize = defaults.htmlFontSize,
		allVariants,
		...other
	} = typography;

	const coef = fontSize / 14;
	const pxToRem = size => `${(size / htmlFontSize).toFixed(6) * coef}rem`;
	const buildVariant = (fontWeight, size, lineHeight, letterSpacing) => ({
		fontFamily,
		fontWeight,
		fontSize: pxToRem(size),
		lineHeight,
		letterSpacing: pxToRem(letterSpacing),
		...allVariants
	});

	const remFontSizes = fontSizes.map(size => pxToRem(size));

	let fontWeightLight = fontWeights[0];
	let fontWeightRegular = fontWeights[1];
	let fontWeightMedium = fontWeights[2];

	const variants = {
		h1: buildVariant(fontWeightLight, 96, 1, -1.5),
		h2: buildVariant(fontWeightLight, 60, 1, -0.5),
		h3: buildVariant(fontWeightRegular, 48, 1.04, 0),
		h4: buildVariant(fontWeightRegular, 34, 1.17, 0.25),
		h5: buildVariant(fontWeightRegular, 24, 1.33, 0),
		h6: buildVariant(fontWeightMedium, 20, 1.6, 0.15),
		subtitle1: buildVariant(fontWeightRegular, 16, 1.75, 0.15),
		subtitle2: buildVariant(fontWeightMedium, 14, 1.57, 0.1),
		body1: buildVariant(fontWeightRegular, 16, 1.5, 0.15),
		body2: buildVariant(fontWeightRegular, 14, 1.43, 0.15),
		button: buildVariant(fontWeightMedium, 14, 1.75, 0.4),
		caption: buildVariant(fontWeightRegular, 12, 1.66, 0.4)
	};

	return {
		fontFamily,
		fontSizes: remFontSizes,
		fontWeights,
		...variants,
		htmlFontSize,
		pxToRem,
		...other
	};
}

export default createTypography;
