import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { LeadsFilterContext } from '../contexts.js';

class LeadFiltersProvider extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			before: undefined,
			after: undefined,
			assignedAgentId: null,
			showFilterDropdown: false
		};

		this.changeFilterCondition = this.changeFilterCondition.bind(this);
		this.updateBefore = this.updateBefore.bind(this);
		this.updateAfter = this.updateAfter.bind(this);
		this.updateAssignedAgentId = this.updateAssignedAgentId.bind(this);
		this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this);
	}

	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]).isRequired
	};

	toggleFilterDropdown(showFilterDropdown) {
		this.setState({ showFilterDropdown });
	}

	changeFilterCondition(filterCondition) {
		this.setState({ filterCondition });
	}

	updateBefore(before) {
		this.setState({ before });
	}

	updateAfter(after) {
		this.setState({ after });
	}

	updateAssignedAgentId(assignedAgentId, fetch) {
		this.setState({ assignedAgentId }, fetch);
	}

	render() {
		const {
			filterCondition,
			before,
			after,
			assignedAgentId,
			showFilterDropdown
		} = this.state;

		return (
			<LeadsFilterContext.Provider
				value={{
					filterCondition,
					before,
					after,
					assignedAgentId,
					showFilterDropdown,
					changeFilterCondition: this.changeFilterCondition,
					updateBefore: this.updateBefore,
					updateAfter: this.updateAfter,
					updateAssignedAgentId: this.updateAssignedAgentId,
					toggleFilterDropdown: this.toggleFilterDropdown
				}}
			>
				{this.props.children}
			</LeadsFilterContext.Provider>
		);
	}
}

export default withRouter(LeadFiltersProvider);
