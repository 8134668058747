import styled, { css } from 'styled-components';

const value = ({ checked, theme: { palette } }) => {
	if (checked) {
		return css`
			& > span.radio-checkmark {
				background-color: ${palette.primary.default};
				box-shadow: 0 0 6px -3px ${palette.primary.default};
			}

			& > span.radio-label {
				font-weight: 500;
			}
		`;
	} else {
		return css`
			& > span.radio-checkmark {
				background-color: #9e9e9e;

				&:after {
					box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);
				}
			}
		`;
	}
};

const readOnly = ({ readOnly, checked, theme: { palette } }) => {
	if (readOnly) {
		if (checked) {
			return css`
				cursor: initial;
				& > input {
					cursor: initial;
				}

				& > span.radio-checkmark {
					background-color: ${palette.primary.l1};
				}
			`;
		} else {
			return css`
				cursor: initial;
				& > input {
					cursor: initial;
				}

				& > span.radio-checkmark {
					background-color: ${palette.grey.l3};

					&:after {
						background-color: ${palette.grey.l3};
					}
				}
			`;
		}
	}
};

const size = ({ size, checked: value }) => {
	if (size === 'sm') {
		const valueSize = () => {
			if (value) {
				return css`
					& > span.radio-checkmark:after {
						top: 3px;
						left: 3px;
						width: 8px;
						height: 8px;
					}
				`;
			} else {
				return css`
					& > span.radio-checkmark:after {
						top: 1px;
						left: 1px;
						width: 12px;
						height: 12px;
					}
				`;
			}
		};

		return css`
			margin-bottom: 12px;
			padding-left: 20px;
			min-height: 16px;

			& > span.radio-checkmark {
				top: 2px;
				left: 6px;
				height: 14px;
				width: 14px;
			}

			& > span.radio-label {
				font-size: 14px;
			}

			& > div.radio-content {
				width: calc(100% - 28px);
			}

			${valueSize};
		`;
	} else {
		const valueSize = () => {
			if (value) {
				return css`
					& > span.radio-checkmark:after {
						top: 4px;
						left: 4px;
						width: 8px;
						height: 8px;
					}
				`;
			} else {
				return css`
					& > span.radio-checkmark:after {
						top: 1px;
						left: 1px;
						width: 14px;
						height: 14px;
					}
				`;
			}
		};

		return css`
			margin-bottom: 16px;
			padding-left: 24px;
			min-height: 20px;

			& > span.radio-checkmark {
				top: 2px;
				left: 6px;
				height: 16px;
				width: 16px;
			}

			& > span.radio-label {
				margin-left: 8px;
				font-size: 16px;
			}

			& > div.radio-content {
				margin-left: 8px;
				width: calc(100% - 32px);
			}

			${valueSize};
		`;
	}
};

const StyledRadio = styled.label`
	display: flex;
	position: relative;
	min-height: 20px;
	cursor: pointer;
	align-self: flex-start;
	width: 100%;
	outline: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	&:last-child {
		margin-bottom: 0;
	}

	& > input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	& > span.radio-checkmark {
		position: absolute;
		border-radius: 50%;

		&:after {
			content: '';
			position: absolute;
			border-radius: 50%;
			background-color: white;
		}
	}

	& > span.radio-label {
		line-height: 1.25;
		margin-left: 8px;
	}

	& > div.radio-content {
		margin-left: 8px;
	}

	&:hover {
		& > span.radio-checkmark {
			background-color: ${({ theme: { palette } }) =>
				palette.primary.default};
		}
	}

	${value};
	${readOnly};
	${size};
`;

export default StyledRadio;
