import React from 'react';
import ReactDOM from 'react-dom';

import 'modules/core/styles.scss';

import { getUser } from './storage';
import client from './client';
import App from './modules/core/main/App.jsx';
import TagManager from 'react-gtm-module';

const { agentId, authJwt } = getUser();

TagManager.initialize({
	gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
});

if (agentId && authJwt) {
	ReactDOM.render(
		React.createElement(App, {
			client,
			user: getUser()
		}),
		document.getElementById('root')
	);
} else {
	window.location =
		process.env.REACT_APP_ACCOUNTS_URL +
		'?r=' +
		encodeURIComponent(window.location.href);
}
