import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';

import {
	SelectedUser,
	AddUser,
	Close as CloseIcon,
	Search as SearchIcon,
	ChevronRight
} from 'modules/blink/icons';
import { Tooltip } from 'modules/blink/components';

import Channels from '../components/Channels';
import Spinner from '../components/Spinner';

import { getFormattedPhoneNumber, useEntityQuery } from '../helpers';
import { withUser } from '../providers';
import {
	QUERY_AGENT_CUSTOMERS,
	QUERY_TEAM_CUSTOMERS,
	QUERY_BROKERAGE_CUSTOMERS
} from '../queries';

const CUSTOMER_QUERY_LIMIT = 10;

function SearchBar(props) {
	const { history, user } = props;

	const [showResults, toggleShowResults] = useState(false);
	const [active, toggleActive] = useState(false);
	const [search, changeSearch] = useState('');
	const [selectedResult, changeSelectedResult] = useState('');
	let searchInputRef = useRef();

	const {
		data: customersData,
		loading: loadingCustomersData
	} = useEntityQuery(
		QUERY_AGENT_CUSTOMERS,
		QUERY_TEAM_CUSTOMERS,
		QUERY_BROKERAGE_CUSTOMERS,
		user,
		{
			skip: !showResults,
			variables: {
				search,
				limit: CUSTOMER_QUERY_LIMIT
			}
		}
	);

	const onFocusSearchBar = () => {
		toggleActive(true);
	};

	const onBlurSearchBar = () => {
		toggleShowResults(false);

		if (!search) toggleActive(false);
	};

	const onSelectResult = (title, customerId) => {
		history.push(`/inbox/${customerId}`);

		toggleShowResults(false);
		changeSearch();
		changeSelectedResult(title);
	};

	const onChangeSearch = e => {
		changeSearch(e.currentTarget.value);
		changeSelectedResult('');
	};

	const onClearSearch = () => {
		changeSearch('');
		changeSelectedResult('');
		toggleShowResults(false);
		toggleActive(false);

		if (searchInputRef?.current?.blur) searchInputRef.current.blur();
	};

	const onFocusSearchInput = () => {
		toggleActive(true);

		if (search) {
			toggleShowResults(true);
		}
	};

	const onViewMore = () => {
		let leadsSearch = search;

		onClearSearch();

		if (leadsSearch) {
			history.push({
				pathname: '/leads',
				search: `?search=${leadsSearch}`
			});
		} else {
			history.push('/leads');
		}
	};

	const renderAssignedAgent = assignedAgent => {
		if (assignedAgent) {
			return (
				<Tooltip
					className="tooltip"
					direction="right"
					horizontalBuffer="-20px"
					label={'Assigned to ' + assignedAgent.name}
				>
					<SelectedUser className="icon assigned" />
				</Tooltip>
			);
		} else {
			return (
				<Tooltip
					className="tooltip"
					direction="right"
					horizontalBuffer="-20px"
					label="Not assigned"
				>
					<AddUser className="icon" />
				</Tooltip>
			);
		}
	};

	const renderResult = (customer, index) => {
		let phone = getFormattedPhoneNumber(customer.phone);

		let title =
			customer.name ||
			phone ||
			customer.email ||
			(customer.conversation ? customer.conversation.givenName : '');

		return (
			<div
				key={index}
				className="option customer-item"
				onClick={() => onSelectResult(title, customer.id)}
				onMouseDown={e => e.preventDefault()}
			>
				<div className="row flex-grow">
					<div className="row flex-grow">
						<span className="customer-title">{title}</span>
						{renderAssignedAgent(customer.assignedAgent)}
					</div>
					<Channels
						channels={customer.sources}
						phoneStatus={customer.phoneStatus}
					/>
				</div>
			</div>
		);
	};

	const renderSpinner = () => {
		return (
			<div style={{ height: '50px' }}>
				<Spinner sm />
			</div>
		);
	};

	const renderSeeMore = customersCount => {
		if (customersCount < CUSTOMER_QUERY_LIMIT) return null;

		return (
			<div
				className="option customer-item view-more-item"
				onClick={onViewMore}
				onMouseDown={e => e.preventDefault()}
			>
				<span className="view-more-prompt">
					View {customersCount - CUSTOMER_QUERY_LIMIT} More Leads
					<ChevronRight />
				</span>
			</div>
		);
	};

	const renderResults = () => {
		if (!showResults) return null;
		let customers = customersData?.customers || [];
		let customersCount = customersData?.totalCustomersCount || 0;

		let leadsList = null;
		if (loadingCustomersData) {
			leadsList = (
				<div className="option customer-item option-empty">
					<span className="customer-title">{renderSpinner()}</span>
				</div>
			);
		} else if (customers.length > 0) {
			let customerItems = customers.map(renderResult);

			leadsList = (
				<div className="leads-list scroll-shadow">
					{customerItems}
					{renderSeeMore(customersCount)}
				</div>
			);
		} else {
			leadsList = (
				<div className="option customer-item option-empty">
					<span className="customer-title">No results</span>
				</div>
			);
		}

		return (
			<div className="dropdown-content">
				<ul className="options-list">
					<li className="options-group">
						<span className="group-title">Search results</span>
						{leadsList}
					</li>
				</ul>
			</div>
		);
	};

	const renderCancel = () => {
		if (!active) return null;

		return (
			<CloseIcon
				className="icon cancel-icon"
				onMouseDown={e => e.preventDefault()}
				onClick={onClearSearch}
			/>
		);
	};

	useEffect(() => {
		if (active && search) {
			toggleShowResults(true);
		}
	}, [active, search]);

	return (
		<StyledSearchBar
			active={active}
			onFocus={onFocusSearchBar}
			onBlur={onBlurSearchBar}
		>
			<SearchIcon className="icon search-icon" />
			<div className="search-input-wrapper">
				<input
					ref={searchInputRef}
					onChange={onChangeSearch}
					value={search || selectedResult}
					onFocus={onFocusSearchInput}
					placeholder="Search contact name, email or phone number"
					spellCheck={false}
				/>
				<div className="dropdown search-dropdown" tabIndex="0">
					{renderResults()}
				</div>
			</div>
			{renderCancel()}
		</StyledSearchBar>
	);
}

const StyledSearchBar = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: 0 20px;
	max-width: 615px;
	margin-right: auto;
	transition: 0.15s ease-out all;

	.cancel-icon,
	.search-icon {
		margin-right: 20px;
		fill: #fff;
		font-size: 16px;
	}

	.search-input-wrapper {
		display: flex;
		flex-grow: 1;
		position: relative;

		input {
			background: transparent;
			outline: none;
			width: 100%;
			border: none;
			color: #fff;
			font-size: 16px;

			&::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: #fff;
				opacity: 0.5;
			}
			&::-moz-placeholder {
				/* Firefox 19+ */
				color: #fff;
			}
			&:-ms-input-placeholder {
				/* IE 10+ */
				color: #fff;
				opacity: 0.5;
			}
			&:-moz-placeholder {
				/* Firefox 18- */
				color: #fff;
			}
		}
	}

	.search-dropdown > .dropdown-content {
		top: 24px;
		right: -26px;
		width: 600px;
	}

	.customer-item {
		&.view-more-item {
			display: flex;
			justify-content: center;

			.view-more-prompt {
				display: flex;
				align-items: center;
			}
		}

		.customer-title {
			margin-right: 10px;
			color: #555;
		}

		.tooltip {
			.icon {
				fill: #888;
				font-size: 16px;

				&.assigned {
					fill: #1abc9c;
				}
			}
		}
	}

	${({ active }) => {
		if (active) {
			return css`
				background-color: white;
				margin-left: 20px;
				border-radius: 4px;
				color: #2a354f;
				padding: 5px 10px;
				box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
					0 3px 6px 0 rgba(0, 0, 0, 0.08);
				border: 1px solid #eee;

				& > .icon {
					fill: initial;
				}

				& > .icon.search-icon {
					margin-right: 10px;
				}

				& > .icon.cancel-icon {
					margin-right: 0;
					cursor: pointer;
				}

				.search-input-wrapper > input {
					color: #444;

					&::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						color: initial;
					}
					&::-moz-placeholder {
						/* Firefox 19+ */
						color: initial;
					}
					&:-ms-input-placeholder {
						/* IE 10+ */
						color: initial;
					}
					&:-moz-placeholder {
						/* Firefox 18- */
						color: initial;
					}
				}
			`;
		}
	}}
`;

SearchBar.propTypes = {
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(withUser(SearchBar));
