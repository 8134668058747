import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from './MenuItem.jsx';
import StyledMenu from './StyledMenu.jsx';

function Menu(props) {
	const {
		items,
		selected,
		onSelectItem,
		renderItem: renderCustomItem,
		size,
		...other
	} = props;

	const renderItem = (item, index) => {
		const { value, label } = item;

		return (
			<MenuItem
				key={index}
				active={selected === value}
				onSelect={onSelectItem}
				label={label}
				value={value}
				size={size}
				{...other}
			/>
		);
	};

	return <StyledMenu>{items && items.map(renderItem)}</StyledMenu>;
}

Menu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.bool
			]),
			label: PropTypes.string
		})
	),
	size: PropTypes.string,
	selected: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.bool
	]),
	onSelectItem: PropTypes.func,
	renderItem: PropTypes.func
};

Menu.defaultProps = {
	items: [],
	size: '',
	selected: null,
	onSelectItem: null,
	renderItem: null
};

export default Menu;
