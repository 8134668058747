import React from 'react';

import Channel from './Channel.jsx';

const FormChannel = props => {

	return (
		<Channel {...props}>
			<title>Form channel</title>
			<path
				fill="#a259ff"
				d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"
			/>
			<path
				fill="#F5F5F5"
				d="M159.1,106.1c-9.2,0-16.7,7.5-16.7,16.7v267.7c0,9.2,7.5,16.7,16.7,16.7h192.4c9.2,0,16.7-7.5,16.7-16.7v-207l-80.7-77.5H159.1z"
			/>
			<path
				fill="#E1E1E1"
				d="M368.3,183.6h-63.9c-9.2,0-16.7-7.5-16.7-16.7v-60.8L368.3,183.6z"
			/>
			<path
				fill="#C9C9C8"
				d="M344.7 206.5c0 2.9-2.3 5.2-5.2 5.2H172.4c-2.9 0-5.2-2.3-5.2-5.2l0 0c0-2.9 2.3-5.2 5.2-5.2h167.1C342.4 201.2 344.7 203.6 344.7 206.5L344.7 206.5zM344.7 233.9c0 2.9-2.3 5.2-5.2 5.2H172.4c-2.9 0-5.2-2.3-5.2-5.2l0 0c0-2.9 2.3-5.2 5.2-5.2h167.1C342.4 228.7 344.7 231 344.7 233.9L344.7 233.9zM344.7 260c0 2.9-2.3 5.2-5.2 5.2H172.4c-2.9 0-5.2-2.3-5.2-5.2l0 0c0-2.9 2.3-5.2 5.2-5.2h167.1C342.4 254.8 344.7 257.1 344.7 260L344.7 260zM344.7 287.4c0 2.9-2.3 5.2-5.2 5.2H172.4c-2.9 0-5.2-2.3-5.2-5.2l0 0c0-2.9 2.3-5.2 5.2-5.2h167.1C342.4 282.2 344.7 284.5 344.7 287.4L344.7 287.4zM259.9 309.6h-87.5c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2h87.5c2.9 0 5.2-2.3 5.2-5.2S262.8 309.6 259.9 309.6z"
			/>
			<g>
				<path
					fill="#0EA24A"
					d="M291.8,382.7c0-33,26.7-59.7,59.7-59.7c33,0,59.7,26.7,59.7,59.7c0,33-26.7,59.7-59.7,59.7C318.5,442.5,291.8,415.7,291.8,382.7z"
				/>
			</g>
		</Channel>
	);
};

export default FormChannel;
