import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import styled, { css } from 'styled-components';
import moment from 'moment';

import {
	Plus as PlusIcon,
	Close as CloseIcon,
	Add as AddIcon
} from 'modules/blink/icons';
import { comparisonOperators, logicalOperators } from '../globals';
import ConditionForm from './ConditionForm';

function ConditionItem(props) {
	const {
		type,
		slot,
		readOnly,
		value,
		operator,
		indices,
		addCondition,
		removeCondition,
		updateSlot,
		updateOperator,
		getSlot,
		options
	} = props;

	const [showPopover, togglePopover] = useState(false);
	const formRef = useRef();

	const { label, required, options: slotOptions, type: slotType } = getSlot(
		slot
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClick, false);

		return () => {
			document.removeEventListener('mousedown', handleClick, false);
		};
	}, []);

	const isTimestamp = value => {
		if (typeof value !== 'number') return false;

		return value > 1400000000;
	};

	const handleClick = e => {
		if (formRef && formRef.current && formRef.current.contains(e.target))
			return;

		togglePopover(false);
	};

	let root =
		!['slot', 'add'].includes(type) && (!indices || indices.length === 1);

	const update = item => {
		if (!item || readOnly) return;

		if (type === 'slot') {
			updateSlot(item, indices);
		} else if (type === 'operator') {
			updateOperator(item, indices);
		} else {
			addCondition(item, indices);
		}

		togglePopover(false);
	};

	const getOperatorText = (operator, value) => {
		const {
			EQ,
			NE,
			GT,
			GTE,
			LT,
			LTE,
			IN,
			NIN,
			INC,
			CI_INC
		} = comparisonOperators;
		const { AND, OR, NOR } = logicalOperators;

		let overrideLabel = '';
		if (required && value === null && Array.isArray(slotOptions)) {
			let overrideOption = slotOptions.find(
				option => option.value === value
			);

			if (overrideOption && overrideOption.label) {
				overrideLabel = overrideOption.label;
			}
		}

		switch (operator) {
			case EQ:
				if (required && value === null) {
					return `is ${overrideLabel}`;
				} else if (value === true) {
					return 'is true';
				} else if (value === false) {
					return 'is false';
				} else if (value || value === 0) {
					return 'is';
				} else {
					return 'is unknown';
				}
			case NE:
				if (value === true) {
					return 'is not true';
				} else if (value === false) {
					return 'is not false';
				} else if (value || value === 0) {
					return 'is not';
				} else {
					return 'has any value';
				}
			case GT:
				if (isTimestamp(value)) {
					return 'after';
				} else {
					return 'is greater than';
				}
			case GTE:
				return 'is greater than or equal to';
			case LT:
				if (isTimestamp(value)) {
					return 'before';
				} else {
					return 'is less than';
				}
			case LTE:
				return 'is less than or equal to';
			case INC:
				return 'includes (case sensitive)';
			case CI_INC:
				return 'includes (case in-sensitive)';
			case IN:
				return 'is';
			case NIN:
				return 'is not';
			case AND:
				return 'and';
			case OR:
				return 'or';
			case NOR:
				return 'nor';
			default:
				return '';
		}
	};

	const getValueText = (slot, value) => {
		if (!value && value !== 0) return '';

		const { options } = getSlot(slot);

		const getSlotValueText = value => {
			if (options) {
				let option = options.find(option => option.value === value);

				if (option && option.label) {
					return option.label;
				}
			}

			return value;
		};

		if (isTimestamp(value)) {
			return moment(value * 1000).format('MMMM Do, YYYY');
		} else if (typeof value === 'string') {
			return getSlotValueText(value);
		} else if (Array.isArray(value)) {
			if (value.length === 0) {
				return '';
			} else if (value.length === 1) {
				return getSlotValueText(value[0]);
			} else {
				let slotLabels = value.map(slotValue => {
					return getSlotValueText(slotValue);
				});

				let firstSlots = slotLabels.slice(0, slotLabels.length - 1);
				let lastSlot = slotLabels[slotLabels.length - 1];

				return (
					<Fragment>
						{firstSlots.join(', ')} <b>or</b> {lastSlot}
					</Fragment>
				);
			}
		}

		return value;
	};

	const renderRemove = () => {
		if (readOnly) return null;

		return (
			<div
				className="remove-condition"
				onClick={e => {
					e.stopPropagation();
					removeCondition(indices);
				}}
			>
				<CloseIcon />
			</div>
		);
	};

	const renderAdd = () => {
		return <PlusIcon />;
	};

	const renderAddCondition = () => {
		return (
			<p className="add-condition-label">
				<AddIcon />
				<span>Add criteria</span>
			</p>
		);
	};

	const renderLabel = () => {
		return <h4>{label}</h4>;
	};

	const renderOperator = () => {
		return <p>{getOperatorText(operator, value)}</p>;
	};

	const renderValue = () => {
		return (
			<p>
				&nbsp;
				{getOperatorText(operator, value)} {getValueText(slot, value)}
			</p>
		);
	};

	const renderSlotItem = () => {
		if (slotType === 'built-in') {
			return (
				<div
					className="built-in-condition"
					onClick={e => e.stopPropagation()}
				>
					{renderLabel()}
				</div>
			);
		}

		return (
			<div
				className="condition-item"
				onClick={e => {
					e.stopPropagation();
					togglePopover(true);
				}}
			>
				{renderLabel()}
				{renderValue()}
				{renderRemove()}
			</div>
		);
	};

	const renderOperatorItem = () => {
		return (
			<div
				className="condition-item"
				onClick={e => {
					e.stopPropagation();

					let newOperator = logicalOperators.OR;
					if (!operator || operator === logicalOperators.OR) {
						newOperator = logicalOperators.AND;
					}

					update(newOperator, indices);
				}}
			>
				{renderOperator()}
			</div>
		);
	};

	const renderAddItem = () => {
		return (
			<div
				className="condition-item"
				onClick={e => {
					e.stopPropagation();
					togglePopover(true);
				}}
			>
				{renderAdd()}
			</div>
		);
	};

	const renderAddConditionItem = () => {
		return (
			<div
				className="condition-item"
				onClick={e => {
					e.stopPropagation();
					togglePopover(true);
				}}
			>
				{renderAddCondition()}
			</div>
		);
	};

	const renderPopover = () => {
		if (!showPopover) return null;

		return (
			<ConditionForm
				slot={slot}
				operator={operator}
				value={value}
				getSlot={getSlot}
				onSubmit={update}
				options={options}
			/>
		);
	};

	const renderItem = () => {
		if (type === 'slot') {
			return renderSlotItem();
		} else if (type === 'operator') {
			return renderOperatorItem();
		} else if (type === 'add') {
			return renderAddItem();
		} else if (type === 'add-condition') {
			return renderAddConditionItem();
		} else {
			return null;
		}
	};

	const renderContent = () => {
		if (showPopover && !readOnly) {
			return (
				<Manager>
					<Reference>
						{({ ref }) => (
							<div ref={ref} className="condition-item-wrapper">
								{renderItem()}
							</div>
						)}
					</Reference>
					<Popper placement="auto-start">
						{({ ref, style, placement }) => (
							<div
								ref={ref}
								style={{ ...style, zIndex: 5 }}
								data-placement={placement}
							>
								{renderPopover()}
							</div>
						)}
					</Popper>
				</Manager>
			);
		} else {
			return <div className="condition-item-wrapper">{renderItem()}</div>;
		}
	};

	return (
		<StyledConditionItem
			type={type}
			slot={slot}
			root={root}
			ref={formRef}
			readOnly={readOnly}
		>
			{renderContent()}
		</StyledConditionItem>
	);
}

ConditionItem.propTypes = {
	type: PropTypes.string,
	slot: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.array,
		PropTypes.bool
	]),
	options: PropTypes.array,
	readOnly: PropTypes.bool,
	operator: PropTypes.string,
	indices: PropTypes.array,
	getSlot: PropTypes.func.isRequired,
	addCondition: PropTypes.func.isRequired,
	removeCondition: PropTypes.func.isRequired,
	updateSlot: PropTypes.func.isRequired,
	updateOperator: PropTypes.func.isRequired
};

ConditionItem.defaultProps = {
	type: '',
	slot: '',
	value: '',
	operator: '',
	readOnly: false,
	indices: null,
	options: []
};

const StyledConditionItem = styled.div`
    margin-bottom: 4px;
    outline: none;

	.built-in-condition {
		cursor: default;
		display: flex;
		align-items: center;
		float: left;
		height: 38px;
		position: relative;
		background-color: #ECF5FD;
    	padding: 0 12px;	

		h4 {
			font-size: 14px;
    		font-weight: 500;
		}
	}

    &:before {
        content: '';
        border-top: 19px solid transparent;
        border-bottom: 19px solid transparent;
        z-index: -1;
        position: relative;
        border-right: 8px solid
            ${({ theme: { palette } }) => palette.cloud.default};
        margin-left: -8px;
        float: left;
    }

    &:after {
        content: '';
        border-top: 19px solid transparent;
        border-bottom: 19px solid transparent;
        z-index: -1;
        position: relative;
        border-left: 8px solid ${({ theme: { palette } }) => palette.cloud.l1};
        margin-right: -10px;
        float: right;
    }

    &:first-child {
        &:before {
            display: none;
        }
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    &:not(:last-child) {
        border-right: 1px solid #fff;
    }

    & > div.condition-item-wrapper > div.condition-item {
        display: flex;
        align-items: center;
        background-color: ${({ root, theme: { palette } }) =>
			root ? '#fff' : palette.cloud.default};
        padding: 0 12px;
        float: left;
        cursor: pointer;
        height: 38px;
        position: relative;

        & > h4 {
            font-size: 14px;
            font-weight: 500;
        }

        p {
            font-weight: normal;
            font-size: 14px;

            & > b {
                font-weight: 500;
            }
        }

        svg {
            font-size: 14px;
        }

        & > p.add-condition-label {
            display: flex;
            align-items: center;
            color: #308685;

            & > svg {
                font-size: 18px;
                fill: #308685;
            }

            svg + span {
                margin-left: 4px;
            }
        }

        &:hover {
            background-color: ${({ root, theme: { palette } }) =>
				root ? palette.cloud.l2 : palette.cloud.d1};
            
            &:after {
                display: block;
            }
        }
    }

    ${({ root }) => {
		if (root) {
			return css`
				&:after,
				&:before {
					display: none;
				}
			`;
		}
	}}

    ${({ type }) => {
		if (type === 'add') {
			return css`
				&:after {
					display: none;
				}
			`;
		}
	}}

	/* Slot */
	${({ slot, theme: { palette } }) => {
		if (slot) {
			return css`
				& > div.condition-item-wrapper > div.condition-item {
					& > .remove-condition {
						display: none;
						position: absolute;
						top: 0;
						right: 0;
						background-color: ${palette.cloud.default};
						height: 38px;
						width: 38px;
						align-items: center;
						justify-content: center;
						border-left: 1px solid #fff;
						box-sizing: border-box;

						&:hover {
							background-color: ${palette.red[50]};
						}

						& > svg {
							font-size: 14px;
						}
					}

					&:after {
						display: none;
						background: linear-gradient(
							to right,
							rgba(235, 235, 235, 0) 90%,
							#ecf5fd 100%
						);
						content: '';
						width: calc(100% - 38px);
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						pointer-events: none;
					}

					&:hover {
						& > .remove-condition {
							display: flex;
						}
					}
				}
			`;
		}
	}}

	/* Read only */
    ${({ readOnly, root, theme: { palette } }) => {
		if (readOnly) {
			return css`
				&:before {
					border-right-color: ${palette.grey.l3};
				}

				& > div.condition-item-wrapper > div.condition-item {
					cursor: initial;
					background-color: ${root ? '#fff' : '#eee'};

					&:hover {
						background-color: ${root ? '#fff' : '#eee'};

						& > .remove-condition {
							display: none;
						}

						&:after {
							content: none;
						}
					}
				}
			`;
		}
	}}
`;

export default ConditionItem;
