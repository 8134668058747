import React from 'react';
import withCreateLeadContext from '../providers/withCreateLead';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Fab, Button, Tooltip } from '@material-ui/core';

export const UploadLeadsButton = withCreateLeadContext(
	({ showUploadLeads, largeButton }) => {
		if (largeButton) {
			return (
				<Button
					color="primary"
					variant="contained"
					style={{ marginLeft: '1rem' }}
					onClick={showUploadLeads}
				>
					<span style={{ marginRight: '0.5rem' }}>Upload Leads</span>
					<CloudUploadIcon />
				</Button>
			);
		}

		return (
			<Tooltip title="Upload Leads" aria-label="upload-leads">
				<Fab
					color="default"
					size="small"
					variant="extended"
					style={{ marginLeft: '0.5rem' }}
					onClick={showUploadLeads}
				>
					<CloudUploadIcon />
				</Fab>
			</Tooltip>
		);
	}
);
