import { Component } from 'react';
import PropTypes from 'prop-types';
import url from 'url';

import { showPopover, showError, parseToken } from '../helpers';

class UrlHandler extends Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		assignCustomerToAgent: PropTypes.func.isRequired,
		assignCustomerToTeam: PropTypes.func.isRequired,
		addAgentToTeam: PropTypes.func.isRequired,
		addAgentToBrokerage: PropTypes.func.isRequired,
		addTeamToBrokerage: PropTypes.func.isRequired
	};

	componentDidMount() {
		this.handleUrlQueryVariables();
	}

	componentDidUpdate() {
		this.handleUrlQueryVariables();
	}

	shouldComponentUpdate() {
		const { location } = this.props;
		const { assignLead, assignType, accessToken } = url.parse(
			location.search,
			['query']
		).query;

		return !!((assignLead && assignType) || accessToken);
	}

	handleUrlQueryVariables() {
		const { location, history } = this.props;
		const { assignLead, assignType, accessToken } = url.parse(
			location.search,
			['query']
		).query;

		if (assignLead && assignType) {
			this.handleAssignLead(assignLead, assignType);

			history.push(location.pathname);
		} else if (accessToken) {
			this.handleInviteExisting(accessToken);

			history.push(location.pathname);
		}
	}

	handleInviteExisting(accessToken) {
		const {
			addAgentToTeam,
			addAgentToBrokerage,
			addTeamToBrokerage
		} = this.props;
		const { teamID, brokerageID, expires, id, inviteType } = parseToken(
			accessToken
		);

		if (expires < Date.now()) {
			if (inviteType === 'agent') {
				if (teamID) {
					addAgentToTeam(teamID, id, accessToken)
						.then(result => {
							if (
								result &&
								result.data &&
								result.data.addAgentToTeam &&
								result.data.addAgentToTeam.status
							) {
								showPopover(
									'You were successfully added to the team',
									'success'
								);
							} else {
								showPopover(
									'Invite failed to complete',
									'error'
								);
							}
						})
						.catch(e => {
							showError(e);
						});
				} else if (brokerageID) {
					addAgentToBrokerage(brokerageID, id, accessToken)
						.then(result => {
							if (
								result &&
								result.data &&
								result.data.addAgentToBrokerage &&
								result.data.addAgentToBrokerage.status
							) {
								showPopover(
									'You were successfully added to the brokerage',
									'success'
								);
							} else {
								showPopover(
									'Invite failed to complete',
									'error'
								);
							}
						})
						.catch(e => {
							showError(e);
						});
				}
			} else if (inviteType === 'team') {
				if (brokerageID) {
					addTeamToBrokerage(brokerageID, id, accessToken)
						.then(result => {
							if (
								result &&
								result.data &&
								result.data.addTeamToBrokerage &&
								result.data.addTeamToBrokerage.status
							) {
								showPopover(
									'Your team was successfully added to the brokerage',
									'success'
								);
							} else {
								showPopover(
									'Invite failed to complete',
									'error'
								);
							}
						})
						.catch(e => {
							showError(e);
						});
				}
			}
		} else {
			showPopover(
				'The invite you were sent has expired. You will need to be sent a new invite.',
				'error'
			);
		}
	}

	handleAssignLead(customerId, assignType) {
		let promise = null;

		if (assignType === 'agent') {
			promise = this.props.assignCustomerToAgent(customerId);
		} else if (assignType === 'team') {
			promise = this.props.assignCustomerToTeam(customerId);
		}

		if (promise) {
			promise.then(response => {
				let data =
					response.data.assignCustomerToAgent ||
					response.data.assignCustomerToTeam;

				if (data && data.status) {
					let msgIntro =
						assignType === 'agent' ? 'You have ' : 'Your team has ';

					showPopover(
						msgIntro + 'successfully been assigned the new lead!',
						'success'
					);
				} else {
					showPopover(
						'Assigning the lead failed, it may already be ' +
							`taken by another ${assignType}`,
						'error'
					);
				}
			});

			promise.catch(error => {
				error.graphQLErrors.forEach(error => {
					showPopover(error.message, 'error');
				});
			});
		}
	}

	render() {
		return null;
	}
}

export default UrlHandler;
