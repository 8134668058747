import React from 'react';

import SimpleSvg from './SimpleSvg';

const Team = props => (
	<SimpleSvg
		d="M 8 5 A 3 3 0 0 0 5 8 A 3 3 0 0 0 8 11 A 3 3 0 0 0 11 8 A 3 3 0 0 0 8 5 z M 16 5 A 3 3 0 0 0 13 8 A 3 3 0 0 0 16 11 A 3 3 0 0 0 19 8 A 3 3 0 0 0 16 5 z M 8 13 C 5 13 1 14.464 1 16.5 L 1 18 C 1 18.552 1.448 19 2 19 L 14 19 C 14.552 19 15 18.552 15 18 L 15 16.5 C 15 14.464 11 13 8 13 z M 16 13 C 15.683 13 15.353484 13.019781 15.021484 13.050781 C 16.203484 13.915781 17 15.059 17 16.5 L 17 19 L 22 19 C 22.552 19 23 18.552 23 18 L 23 16.5 C 23 14.464 19 13 16 13 z"
		{...props}
	/>
);

export default Team;
