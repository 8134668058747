import React from 'react';

import SimpleSvg from './SimpleSvg';

const CreditCard = props => (
	<SimpleSvg
		d="M 4 4 C 2.895 4 2 4.895 2 6 C 2 6.552 2.448 7 3 7 L 21 7 C 21.552 7 22 6.552 22 6 C 22 4.895 21.105 4 20 4 L 4 4 z M 3 10 C 2.448 10 2 10.448 2 11 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 11 C 22 10.448 21.552 10 21 10 L 3 10 z M 15 13 C 15.366 13 15.705 13.106344 16 13.277344 C 16.295 13.106344 16.634 13 17 13 C 18.105 13 19 13.895 19 15 C 19 16.105 18.105 17 17 17 C 16.634 17 16.295 16.893656 16 16.722656 C 15.705 16.893656 15.366 17 15 17 C 13.895 17 13 16.105 13 15 C 13 13.895 13.895 13 15 13 z M 16 16.722656 A 1 1.723 0 0 0 17 15 A 1 1.723 0 0 0 16 13.277344 A 1 1.723 0 0 0 15 15 A 1 1.723 0 0 0 16 16.722656 z"
		{...props}
	/>
);

export default CreditCard;
