import React from 'react';

import PropTypes from 'prop-types';

function RoiInput({ label, value, isReadOnly, isCurrency, onChange, onBlur }) {
	const renderInput = () => {
		if (isReadOnly) {
			return (
				<p className="info-label">
					{isCurrency && '$'}
					{value}
					{!isCurrency && '%'}
				</p>
			);
		}

		return (
			<>
				<input
					className={`roi-textinput ${
						isCurrency ? 'is-currency' : ''
					}`}
					value={value}
					onBlur={onBlur}
					onChange={onChange}
				/>
				<span className="roi-input-symbol">
					{isCurrency ? '$' : '%'}
				</span>
			</>
		);
	};

	return (
		<div className="roi-input">
			<h4 className="info-title">{label}</h4>
			{renderInput()}
		</div>
	);
}

RoiInput.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	isReadOnly: PropTypes.bool,
	isCurrency: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired
};

RoiInput.defaultProps = {
	isReadOnly: false,
	isCurrency: false
};

export default RoiInput;
