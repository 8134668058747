import React from 'react';

import SimpleSvg from './SimpleSvg';

const AddFile = props => (
	<SimpleSvg
		d="M 14 2 L 6 2 C 4.898438 2 4 2.898438 4 4 L 4 20 C 4 21.101562 4.898438 22 6 22 L 18 22 C 19.101562 22 20 21.101562 20 20 L 20 8 Z M 15 14 L 13 14 L 13 16 C 13 16.550781 12.550781 17 12 17 C 11.449219 17 11 16.550781 11 16 L 11 14 L 9 14 C 8.449219 14 8 13.550781 8 13 C 8 12.449219 8.449219 12 9 12 L 11 12 L 11 10 C 11 9.449219 11.449219 9 12 9 C 12.550781 9 13 9.449219 13 10 L 13 12 L 15 12 C 15.550781 12 16 12.449219 16 13 C 16 13.550781 15.550781 14 15 14 Z M 15 14 "
		{...props}
	/>
);

export default AddFile;
