import styled from 'styled-components';

const StyledTable = styled.div`
	position: relative;
	color: ${({ theme: { palette } }) => palette.grey[900]};

	table {
		width: 100%;
		border-spacing: 0;
		background-color: #fff;
		position: relative;
		border-radius: 4px 4px 0 0;

		thead {
			border-radius: 4px 4px 0 0;

			tr {
				border-radius: 4px 4px 0 0;
				height: 40px;

				th {
					z-index: 1;
					top: 0;
					position: sticky;
					text-align: left;
					font-size: 11px;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					font-weight: 500;
					background-color: #fff;
					border-bottom: 1px solid
						${({ theme: { palette } }) => palette.grey[100]};
					box-sizing: border-box;

					&:first-of-type {
						padding-left: 20px;
						border-radius: 4px 0 0 0;
					}

					&:last-of-type {
						padding-right: 20px;
						border-radius: 0 4px 0 0;
					}

					&.action-cell {
						width: 60px;
					}

					.sortable-header {
						display: flex;
						align-items: center;
						cursor: pointer;

						&:hover > span {
							text-decoration: underline;
						}

						& > svg {
							font-size: 16px;
							margin-left: 4px;
							margin-top: -3px;
						}
					}
				}
			}
		}

		tbody {
			tr {
				height: 40px;
				cursor: pointer;

				&:hover {
					background-color: ${({ theme: { palette } }) =>
						palette.blue[50]};
				}

				&:last-of-type {
					& > td {
						border: none;
					}
				}

				td {
					font-size: 12px;
					border-bottom: 1px solid
						${({ theme: { palette } }) => palette.grey[50]};
					box-sizing: border-box;
					word-wrap: break-word;

					&:first-of-type {
						padding-left: 20px;
					}

					&:last-of-type {
						padding-right: 20px;
					}

					&.action-cell {
						width: 60px;
					}

					& > p {
						font-size: 12px;
						min-width: 50px;
						max-width: ${({ maxCellWidth }) => `${maxCellWidth}px`};
					}

					& > .email {
						color: ${({ theme: { palette } }) => palette.blue[500]};

						&:hover {
							text-decoration: underline;
						}
					}

					& > .channel-list {
						.channel-item {
							&:not(:last-child) {
								margin-right: 4px;
							}
						}
					}
				}
			}
		}
	}

	.loading-container {
		position: absolute;
		top: 52px;
		left: 50%;
		border-radius: 50%;
		background-color: #fff;
		padding: 8px;
		box-shadow: ${({ theme: { shadows } }) => shadows[1]};
	}

	.placeholder-container {
		position: absolute;
		top: 52px;
		left: 50%;
		background-color: #fff;
		padding: 8px;
		box-shadow: ${({ theme: { shadows } }) => shadows[1]};
	}

	.table-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		bottom: 0;
		height: 40px;
		background-color: #fff;
		padding: 0 20px;
		font-size: 12px;
		border-radius: 0 0 4px 4px;
		font-weight: 500;
		border-top: 1px solid ${({ theme: { palette } }) => palette.grey[100]};

		.table-footer-item {
			display: flex;
			align-items: center;

			.info-label {
				color: ${({ theme: { palette } }) => palette.grey[500]};
			}

			.page-size-container {
				display: flex;
				align-items: center;
				margin-left: 4px;
				padding: 2px 4px;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background-color: ${({ theme: { palette } }) =>
						palette.grey[50]};
				}

				&:active,
				&:focus {
					background-color: ${({ theme: { palette } }) =>
						palette.grey[100]};
				}

				.page-size-handler {
					display: flex;
					align-items: center;

					.page-size {
						min-width: 16px;
						height: 16px;
						text-align: center;
					}

					& > svg {
						font-size: 16px;
					}
				}
			}

			.pagination {
				display: flex;
				align-items: center;
				margin-right: 8px;

				.pagination-arrow-left,
				.pagination-arrow-right {
					display: flex;
					align-items: center;
					cursor: pointer;
					border-radius: 4px;

					&:hover {
						background-color: ${({ theme: { palette } }) =>
							palette.grey[50]};
					}

					&:active,
					&:focus {
						background-color: ${({ theme: { palette } }) =>
							palette.grey[100]};
					}

					&.disabled {
						background-color: #fff;
						cursor: not-allowed;

						& > svg {
							fill: ${({ theme: { palette } }) =>
								palette.grey[100]};
						}
					}

					& > svg {
						font-size: 18px;
					}
				}

				.pagination-arrow-left {
					margin-right: 4px;
				}
			}
		}
	}
`;

export default StyledTable;
