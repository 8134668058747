import React from 'react';

import SimpleSvg from './SimpleSvg';

const Bathtub = props => (
	<SimpleSvg
		d="M 11 2 C 9.9069372 2 9 2.9069372 9 4 L 9 9 L 2 9 C 1.447 9 1 9.447 1 10 C 1 10.553 1.447 11 2 11 L 2.1171875 11 L 2.5703125 14.619141 C 2.7405039 15.981217 3.4638955 17.146793 4.484375 17.9375 L 4.0292969 19.757812 C 3.8722969 20.388812 4.35 21 5 21 C 5.459 21 5.85775 20.687188 5.96875 20.242188 L 6.3203125 18.832031 C 6.7109161 18.930341 7.1128502 19 7.53125 19 L 16.46875 19 C 16.887316 19 17.289014 18.930341 17.679688 18.832031 L 18.03125 20.242188 C 18.14225 20.687188 18.541 21 19 21 C 19.65 21 20.127703 20.388812 19.970703 19.757812 L 19.515625 17.939453 C 20.536909 17.148739 21.259401 15.981976 21.429688 14.619141 L 21.884766 11 L 22 11 C 22.553 11 23 10.553 23 10 C 23 9.447 22.553 9 22 9 L 11 9 L 11 4 L 14 4 L 14 5 C 13.448 5 13 5.448 13 6 C 13 6.552 13.448 7 14 7 L 14.832031 7 A 1.0001 1.0001 0 0 0 15.158203 7 L 16 7 C 16.552 7 17 6.552 17 6 C 17 5.448 16.552 5 16 5 L 16 4 C 16 2.9069372 15.093063 2 14 2 L 11 2 z"
		{...props}
	/>
);

export default Bathtub;
