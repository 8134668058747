import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedCurrency } from '../helpers';

import { Spinner } from 'modules/core/components';

function RoiItem({ label, value, isLoading, isCurrency }) {
	const formatValue = val => {
		if (isCurrency) {
			return getFormattedCurrency(val);
		}
		return val;
	};

	return (
		<div className="roi-item">
			<h4 className="info-title">{label}</h4>
			<p className="info-label">
				<span>{formatValue(value)}</span>
				{isLoading && <Spinner xs />}
			</p>
		</div>
	);
}

RoiItem.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
	isCurrency: PropTypes.bool
};

RoiItem.defaultProps = {
	isLoading: false,
	isCurrency: false
};

export default RoiItem;
