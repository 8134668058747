import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Search as SearchIcon } from 'modules/blink/icons';

function TableSearch(props) {
	const { placeholder, value, onChange } = props;

	return (
		<StyledTableSearch>
			<input
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
			<span className="search-icon-wrapper">
				<SearchIcon />
			</span>
		</StyledTableSearch>
	);
}

TableSearch.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired
};

TableSearch.defaultProps = {
	placeholder: 'Search',
	value: ''
};

const StyledTableSearch = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	box-shadow: ${({ theme: { shadows } }) => shadows[1]};
	border-radius: 4px;

	input {
		outline: none;
		height: 36px;
		padding: 0 8px 0 36px;
		border-radius: 4px;
		width: 100%;
		color: #102a43;
		font-size: 14px;
		border: 1px solid #fff;
		box-sizing: border-box;

		&:focus {
			border-color: #0967d2;
		}

		&::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: #102a43;
			opacity: 0.5;
		}

		&::-moz-placeholder {
			/* Firefox 19+ */
			color: #102a43;
			opacity: 0.5;
		}

		&:-ms-input-placeholder {
			/* IE 10+ */
			color: #102a43;
			opacity: 0.5;
		}

		&:-moz-placeholder {
			/* Firefox 18- */
			color: #102a43;
			opacity: 0.5;
		}
	}

	.search-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		background-color: transparent;
		border-radius: 4px;
		position: absolute;
		left: 0;
		top: 0;

		svg {
			font-size: 18px;
			fill: #102a43;
		}
	}
`;

export default TableSearch;
