import React from 'react';

import SimpleSvg from './SimpleSvg';

const AddUser = props => (
	<SimpleSvg
		d="M 12 3 A 4 4 0 0 0 8 7 A 4 4 0 0 0 12 11 A 4 4 0 0 0 16 7 A 4 4 0 0 0 12 3 z M 18 12 C 14.7 12 12 14.7 12 18 C 12 21.3 14.7 24 18 24 C 21.3 24 24 21.3 24 18 C 24 14.7 21.3 12 18 12 z M 11.050781 14.046875 C 7.8907812 14.308875 3 15.796 3 18.5 L 3 21 L 10.587891 21 C 10.211891 20.073 10 19.062 10 18 C 10 16.56 10.384781 15.213875 11.050781 14.046875 z M 18 15 C 18.552 15 19 15.448 19 16 L 19 17 L 20 17 C 20.552 17 21 17.448 21 18 C 21 18.552 20.552 19 20 19 L 19 19 L 19 20 C 19 20.552 18.552 21 18 21 C 17.448 21 17 20.552 17 20 L 17 19 L 16 19 C 15.448 19 15 18.552 15 18 C 15 17.448 15.448 17 16 17 L 17 17 L 17 16 C 17 15.448 17.448 15 18 15 z"
		{...props}
	/>
);

export default AddUser;
