import React from 'react';

import { FeatureFlagContext } from '../contexts';

const withFeatureFlags = Component => {
	const FeatureFlagsComponent = props => (
		<FeatureFlagContext.Consumer>
			{featureFlags => {
				return <Component {...props} {...featureFlags} />;
			}}
		</FeatureFlagContext.Consumer>
	);

	return FeatureFlagsComponent;
};

export default withFeatureFlags;
