import React from 'react';
import PropTypes from 'prop-types';

import StyledCheckbox from './StyledCheckbox.jsx';

function Checkbox(props) {
	const { name, label, value, checked, readOnly, size, ...other } = props;

	return (
		<StyledCheckbox checked={checked} readOnly={readOnly} size={size}>
			<span className="checkbox-wrapper">
				<input
					type="checkbox"
					name={name}
					value={value}
					checked={checked}
					{...other}
				/>
				<span className="checkbox-checkmark" />
			</span>
			<span className="checkbox-label">{label}</span>
		</StyledCheckbox>
	);
}

Checkbox.propTypes = {
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	name: PropTypes.string,
	checked: PropTypes.bool,
	label: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'md'])
};

Checkbox.defaultProps = {
	value: '',
	readOnly: false,
	checked: false,
	name: 'checkbox-group',
	label: '',
	size: 'md'
};

export default Checkbox;
