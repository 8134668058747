import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import StyledMenuItem from './StyledMenuItem.jsx';

function MenuItem(props) {
	const { value, label, onSelect, active, size, children, ...other } = props;

	let ref = useRef();

	const handleKeys = e => {
		switch (e.keyCode) {
			case 13:
				if (onSelect) {
					onSelect(value);
				}
				break;
			case 38:
				if (ref && ref.current && ref.current.previousSibling) {
					if (ref.current.previousSibling.tabIndex === 0) {
						ref.current.previousSibling.focus();
					}
				} else if (
					ref &&
					ref.current &&
					ref.current.parentNode &&
					ref.current.parentNode.previousSibling &&
					ref.current.parentNode.previousSibling.firstChild
				) {
					ref.current.parentNode.previousSibling.firstChild.focus();
				}
				break;
			case 40:
				if (ref && ref.current && ref.current.nextSibling) {
					if (ref.current.nextSibling.tabIndex === 0) {
						ref.current.nextSibling.focus();
					}
				}
				break;
			default:
				break;
		}
	};

	const renderContent = () => {
		if (children) {
			return children;
		} else if (label) {
			return <Text noWrap={true}>{label}</Text>;
		} else {
			return null;
		}
	};

	return (
		<StyledMenuItem
			ref={ref}
			tabIndex={0}
			onClick={e => {
				e.stopPropagation();
				onSelect(value);
			}}
			onKeyDown={handleKeys}
			value={value}
			active={active}
			title={label}
			size={size}
			{...other}
		>
			{renderContent()}
		</StyledMenuItem>
	);
}

MenuItem.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool
	]),
	label: PropTypes.string,
	active: PropTypes.bool,
	size: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

MenuItem.defaultProps = {
	value: '',
	label: '',
	size: '',
	active: false,
	children: null
};

export default MenuItem;
