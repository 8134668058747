import React from 'react';
import PropTypes from 'prop-types';

import { RoleContext } from '../contexts';

const withRole = Component => {
	const getActingRole = (role, user) => {
		const {
			agentId,
			teamId,
			brokerageId,
			isTeamLead,
			isBroker,
			isZendesk
		} = user;

		if (isZendesk) return 'agent';

		if (agentId && role !== 'agency' && !isTeamLead && !isBroker) {
			return 'agent';
		} else if (teamId && isTeamLead && role === 'agency') {
			return 'team';
		} else if (brokerageId && !teamId && isBroker && role === 'agency') {
			return 'brokerage';
		}

		return 'agent';
	};

	const RoleComponent = props => (
		<RoleContext.Consumer>
			{roleData => {
				if (!roleData) return <Component />;
				const { role, updateRole } = roleData;

				let newProps = {
					...props,
					role,
					updateRole,
					actingRole: getActingRole(role, props.user)
				};

				return <Component {...newProps} />;
			}}
		</RoleContext.Consumer>
	);

	RoleComponent.propTypes = {
		user: PropTypes.object.isRequired
	};

	return RoleComponent;
};

export default withRole;
