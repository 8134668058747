import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Refresh as RefreshIcon } from 'modules/blink/icons';

import Button from './Button';

function RefreshButton(props) {
	const { onRefresh } = props;

	return (
		<StyledRefreshButton>
			<Button size="sm" type="tertiary" onClick={onRefresh}>
				<RefreshIcon />
			</Button>
		</StyledRefreshButton>
	);
}

RefreshButton.propTypes = {
	onRefresh: PropTypes.func.isRequired
};

const StyledRefreshButton = styled.div`
	& > button > svg {
		fill: ${({ theme: { palette } }) => palette.grey[600]};
	}

	&:hover,
	&:focus,
	&:active {
		& > button > svg {
			fill: ${({ theme: { palette } }) => palette.grey[900]};
		}
	}
`;

export default RefreshButton;
