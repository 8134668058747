import styled, { css } from 'styled-components';

const kind = ({ kind, theme: { palette } }) => {
	switch (kind) {
		case 'action':
			return css`
				padding-bottom: 84px;

				.event-head {
					cursor: pointer;
					border-color: 2px solid ${palette.primary.default};
					background-color: ${palette.primary.l3};
					color: ${palette.primary.default};

					& > svg {
						fill: ${palette.primary.default};
					}

					&:hover {
						border-color: ${palette.primary.default};
					}
				}
			`;
		case 'secondary':
			return css``;
		case 'primary':
			return css`
				.event-head {
					border-color: ${palette.grey.l2};
					background-color: ${palette.grey.l2};
					color: ${palette.grey[800]};

					& > svg {
						color: ${palette.grey[800]};
					}
				}
			`;
		default:
			return css``;
	}
};

const showTail = ({ forceShowTail }) => {
	if (forceShowTail) {
		return css`
			&:last-of-type {
				.event-tail {
					display: block;
				}
			}
		`;
	}
};

const StyledEvent = styled.li`
	position: relative;
	margin: 0;
	padding-bottom: 48px;
	outline: none;

	&:first-of-type {
		margin-top: 16px;
	}

	&:last-of-type {
		padding-bottom: 0;
		margin-bottom: 16px;

		.event-tail {
			display: none;
		}
	}

	.event-tail {
		position: absolute;
		top: 0;
		left: 17px;
		height: 100%;
		border-left: 2px solid ${({ theme: { palette } }) => palette.grey.l2};
	}

	.event-head {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		box-sizing: border-box;
		border: 2px solid transparent;
		border-radius: 50%;
		width: 36px;
		height: 36px;

		& > svg {
			font-size: 22px;
		}
	}

	.event-content {
		position: relative;
		margin: 0 0 0 48px;
	}

	${kind};
	${showTail};
`;

export default StyledEvent;
