import React from 'react';
import PropTypes from 'prop-types';

import StyledToggleWrapper from './StyledToggleWrapper.jsx';
import StyledToggle from './StyledToggle.jsx';

function Toggle(props) {
	const { onClick, label, ...other } = props;

	if (label) {
		return (
			<StyledToggleWrapper>
				<StyledToggle onClick={onClick} {...other}>
					<span />
				</StyledToggle>
				<span className="toggle-label">{label}</span>
			</StyledToggleWrapper>
		);
	} else {
		return (
			<StyledToggle onClick={onClick} {...other}>
				<span />
			</StyledToggle>
		);
	}
}

Toggle.propTypes = {
	value: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

Toggle.defaultProps = {
	value: false,
	size: 'md',
	label: undefined
};

export default Toggle;
