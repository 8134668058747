import React from 'react';

import SimpleSvg from './SimpleSvg';

const ArrowUp = props => (
	<SimpleSvg
		d="M5.713,13.293l5.586-5.586c0.391-0.391,1.024-0.391,1.414,0l5.586,5.586c0.63,0.63,0.184,1.707-0.707,1.707H6.42 C5.529,15,5.083,13.923,5.713,13.293z"
		{...props}
	/>
);

export default ArrowUp;
