import createBreakpoints from './createBreakpoints';
import createPalette from './createPalette';
import createSpacing from './createSpacing';
import createTypography from './createTypography';
import shadows from './shadows';
import shape from './shape';
import transitions from './transitions';

function createBlinkTheme(options = {}) {
	const {
		breakpoints: breakpointsInput = {},
		palette: paletteInput = {},
		shadows: shadowsInput,
		spacing: spacingInput,
		typography: typographyInput = {},
		shape: shapeInput,
		transitions: transitionsInput,
		...other
	} = options;

	const breakpoints = createBreakpoints(breakpointsInput);
	const palette = createPalette(paletteInput);
	const spacing = createSpacing(spacingInput);
	const typography = createTypography(typographyInput);

	const blinkTheme = {
		breakpoints,
		palette,
		shadows: shadowsInput || shadows,
		typography,
		spacing,
		shape: shapeInput || shape,
		transitions: transitionsInput || transitions,
		...other
	};

	return blinkTheme;
}

export default createBlinkTheme;
