import styled from 'styled-components';
import ReactModal from 'react-modal';

const StyledModal = styled(ReactModal)`
	min-width: 480px;
	background-color: #fff;
	outline: none;
	border-radius: ${({ theme: { shape } }) => shape.borderRadius};

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid
			${({ theme: { palette } }) => palette.grey[200]};
		min-height: 56px;
		padding: 0 24px;

		h3 {
			font-size: 24px;
		}
	}
`;

export default StyledModal;
