import React from 'react';

import SimpleSvg from './SimpleSvg';

const Trash = props => (
	<SimpleSvg
		d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.1 5.9 22 7 22 L 17 22 C 18.1 22 19 21.1 19 20 L 19 7 L 5 7 z M 9 9 C 9.552 9 10 9.448 10 10 L 10 19 C 10 19.552 9.552 20 9 20 C 8.448 20 8 19.552 8 19 L 8 10 C 8 9.448 8.448 9 9 9 z M 15 9 C 15.552 9 16 9.448 16 10 L 16 19 C 16 19.552 15.552 20 15 20 C 14.448 20 14 19.552 14 19 L 14 10 C 14 9.448 14.448 9 15 9 z"
		{...props}
	/>
);

export default Trash;
