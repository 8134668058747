import React from 'react';

import { VoiceChannelsContext } from '../contexts';

const withVoiceChannels = Component => {
	const VoiceChannelsComponent = props => (
		<VoiceChannelsContext.Consumer>
			{context => {
				if (!context) {
					return <Component {...props} />;
				}

				const newProps = {
					...props,
					...context
				};

				return <Component {...newProps} />;
			}}
		</VoiceChannelsContext.Consumer>
	);

	return VoiceChannelsComponent;
};

export default withVoiceChannels;
