import React from 'react';

const Play = () => (
	<svg fill="#ffffff" viewBox="-48.46 -48.46 581.49 581.49" stroke="#ffffff">
		<g strokeWidth="0" />
		<g strokeLinecap="round" strokeLinejoin="round" />
		<g>
			<g>
				<g>
					<path d="M360.342,216.266L219.373,113.882c-9.783-7.106-22.723-8.121-33.498-2.63c-10.771,5.49-17.556,16.559-17.556,28.65V344.67 c0,12.092,6.784,23.158,17.556,28.646c4.61,2.348,9.611,3.506,14.6,3.506c6.666,0,13.301-2.07,18.898-6.138l140.969-102.383 c8.33-6.047,13.256-15.719,13.256-26.018C373.598,231.988,368.672,222.312,360.342,216.266z" />{' '}
					<path d="M242.285,0C108.688,0,0.004,108.689,0.004,242.283c0,133.592,108.686,242.283,242.281,242.283 c133.594,0,242.278-108.691,242.278-242.283C484.562,108.689,375.881,0,242.285,0z M242.285,425.027 c-100.764,0-182.744-81.979-182.744-182.744c0-100.766,81.98-182.742,182.744-182.742s182.745,81.976,182.745,182.742 C425.029,343.049,343.049,425.027,242.285,425.027z" />{' '}
				</g>
			</g>
		</g>
	</svg>
);

export default Play;
