import React from 'react';

import SimpleSvg from './SimpleSvg';

const Plus = props => (
	<SimpleSvg
		d="M 20 11 L 13 11 L 13 4 C 13 3.449219 12.550781 3 12 3 C 11.449219 3 11 3.449219 11 4 L 11 11 L 4 11 C 3.449219 11 3 11.449219 3 12 C 3 12.550781 3.449219 13 4 13 L 11 13 L 11 20 C 11 20.550781 11.449219 21 12 21 C 12.550781 21 13 20.550781 13 20 L 13 13 L 20 13 C 20.550781 13 21 12.550781 21 12 C 21 11.449219 20.550781 11 20 11 Z M 20 11 "
		{...props}
	/>
);

export default Plus;
