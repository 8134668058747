import React from 'react';

import SimpleSvg from './SimpleSvg';

const Close = props => (
	<SimpleSvg
		d="M 4.992188 3.992188 C 4.582031 3.992188 4.21875 4.238281 4.0625 4.613281 C 3.910156 4.992188 4 5.421875 4.292969 5.707031 L 10.585938 12 L 4.292969 18.292969 C 4.03125 18.542969 3.925781 18.917969 4.019531 19.265625 C 4.109375 19.617188 4.382812 19.890625 4.734375 19.980469 C 5.082031 20.074219 5.457031 19.96875 5.707031 19.707031 L 12 13.414062 L 18.292969 19.707031 C 18.542969 19.96875 18.917969 20.074219 19.265625 19.980469 C 19.617188 19.890625 19.890625 19.617188 19.980469 19.265625 C 20.074219 18.917969 19.96875 18.542969 19.707031 18.292969 L 13.414062 12 L 19.707031 5.707031 C 20.003906 5.417969 20.089844 4.980469 19.929688 4.601562 C 19.769531 4.21875 19.394531 3.976562 18.980469 3.992188 C 18.71875 3.996094 18.472656 4.105469 18.292969 4.292969 L 12 10.585938 L 5.707031 4.292969 C 5.519531 4.097656 5.261719 3.992188 4.992188 3.992188 Z M 4.992188 3.992188 "
		{...props}
	/>
);

export default Close;
