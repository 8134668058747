import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ChevronRight } from 'modules/blink/icons';

class StageItem extends PureComponent {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	static propTypes = {
		type: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.number,
		onClick: PropTypes.func.isRequired
	};

	static defaultProps = {
		type: '',
		label: '',
		value: 0
	};

	onClick() {
		const { onClick, type } = this.props;

		onClick(type);
	}

	render() {
		const { label, value } = this.props;

		let unit = 'leads';
		if (value === 1) {
			unit = 'lead';
		}

		return (
			<div className="stage-item paper">
				<h4 className="info-title">{label}</h4>
				<div className="info">
					<Link
						to="/inbox"
						onClick={this.onClick}
						className="link-btn"
					>
						<span className="info-label">
							{value} {unit}
						</span>
						<ChevronRight
							color="#2a354f"
							width="18px"
							height="18px"
						/>
					</Link>
				</div>
			</div>
		);
	}
}

export default StageItem;
