import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useWindowSize } from 'modules/core/helpers';

import Popover from '../Popover';
import Menu from '../Menu';
import Text from '../Text';
import Spinner from '../Spinner';
import Checkbox from '../Checkbox';

import {
	ArrowDown,
	ArrowUp,
	ChevronDown,
	ChevronLeft,
	ChevronRight
} from 'modules/blink/icons';

import StyledTable from './StyledTable';

function Table(props) {
	const {
		headers,
		items,
		itemCount,
		onItemClick,
		onItemSelect,
		placeholder,
		pageSize,
		changePageSize,
		page,
		sort,
		noWrap,
		changeSort,
		changePage,
		selectAll,
		toggleSelectAll,
		loading,
		fetching
	} = props;
	const [showPageSizePopover, togglePageSizePopover] = useState(false);
	const windowSize = useWindowSize();

	let startIndex = 0;
	let endIndex = 0;
	if (changePage && typeof changePage === 'function') {
		startIndex = page * pageSize;
		endIndex = (page + 1) * pageSize;

		if (endIndex > itemCount) {
			endIndex = itemCount;
		}
	} else {
		endIndex = items.length;
	}

	const getMaxCellWidth = () => {
		if (!Array.isArray(headers)) return 0;
		const { width } = windowSize;

		return (width - 180) / headers.length;
	};

	const renderSortableHeader = (label, value) => {
		if (sort && sort.includes(value)) {
			if (sort.startsWith('+')) {
				return (
					<div
						className="sortable-header"
						onClick={() => changeSort(`-${value}`)}
					>
						<span>{label}</span>
						<ArrowUp />
					</div>
				);
			} else if (sort.startsWith('-')) {
				return (
					<div
						className="sortable-header"
						onClick={() => changeSort(`+${value}`)}
					>
						<span>{label}</span>
						<ArrowDown />
					</div>
				);
			}
		}

		return (
			<div
				className="sortable-header"
				onClick={() => changeSort(`+${value}`)}
			>
				<span>{label}</span>
			</div>
		);
	};

	const renderPlaceholder = () => <p>&mdash;</p>;

	const renderSelectCell = data => {
		if (!data || typeof onItemSelect !== 'function') return null;
		const { isSelected, id } = data;

		return (
			<td className="action-cell" onClick={e => e.stopPropagation()}>
				<Checkbox
					onChange={() => onItemSelect(id)}
					checked={isSelected}
					size="sm"
				/>
			</td>
		);
	};

	const renderCells = cells => {
		if (!Array.isArray(cells) || !Array.isArray(headers)) return null;

		return headers.map((header, index) => {
			const { value } = header;

			let cell = cells.find(c => c.value === value);
			let content = null;
			if (cell) {
				const { renderCell, label, data } = cell;

				if (renderCell && typeof renderCell === 'function') {
					content = renderCell(data);
				} else if (label) {
					content = (
						<Text noWrap={noWrap} title={label}>
							{label}
						</Text>
					);
				}
			}

			return <td key={index}>{content || renderPlaceholder()}</td>;
		});
	};

	const renderRows = () => {
		return items.slice(startIndex, endIndex).map((item, index) => {
			const { data, cells } = item;

			return (
				<tr key={index} onClick={() => onItemClick(data && data.id)}>
					{renderSelectCell(data)}
					{renderCells(cells)}
				</tr>
			);
		});
	};

	const renderPagination = () => {
		const renderPrev = () => {
			if (page === 0) {
				return (
					<span className="pagination-arrow-left disabled">
						<ChevronLeft />
					</span>
				);
			} else {
				return (
					<span
						className="pagination-arrow-left"
						onClick={() => changePage(page - 1)}
					>
						<ChevronLeft />
					</span>
				);
			}
		};

		const renderNext = () => {
			if (endIndex === itemCount || fetching || loading) {
				return (
					<span className="pagination-arrow-right disabled">
						<ChevronRight />
					</span>
				);
			} else {
				return (
					<span
						className="pagination-arrow-right"
						onClick={() => changePage(page + 1)}
					>
						<ChevronRight />
					</span>
				);
			}
		};

		return (
			<div className="pagination">
				{renderPrev()}
				{renderNext()}
			</div>
		);
	};

	const renderFooter = () => {
		if (
			itemCount <= pageSize ||
			typeof changePage !== 'function' ||
			typeof changePageSize !== 'function'
		)
			return null;

		return (
			<div className="table-footer">
				<div className="table-footer-item">
					<span className="info-label">Rows per page</span>
					<div className="page-size-container">
						<Popover
							showPopover={showPageSizePopover}
							togglePopover={togglePageSizePopover}
							renderHandler={() => (
								<div className="page-size-handler">
									<span className="page-size">
										{pageSize}
									</span>
									<ChevronDown />
								</div>
							)}
							renderPopover={() => (
								<Menu
									selected={pageSize}
									onSelectItem={value => {
										changePageSize(value);
										changePage(
											Math.floor(startIndex / value)
										);
										togglePageSizePopover(false);
									}}
									items={[
										{
											value: 10,
											label: '10'
										},
										{
											value: 25,
											label: '25'
										},
										{
											value: 50,
											label: '50'
										},
										{
											value: 100,
											label: '100'
										}
									].filter(el => el.value <= itemCount)}
								/>
							)}
						/>
					</div>
				</div>
				<div className="table-footer-item">
					{renderPagination()}
					<span className="info-label">
						{startIndex}-{endIndex} of {itemCount}
					</span>
				</div>
			</div>
		);
	};

	const renderLoading = () => {
		if (!loading && !fetching) return null;

		return (
			<div className="loading-container">
				<Spinner kind="primary" tint={true} />
			</div>
		);
	};

	const renderDataPlaceholder = () => {
		if (loading || items.length > 0) return null;

		return (
			<div className="placeholder-container">
				<h4>{placeholder}</h4>
			</div>
		);
	};

	const renderPlaceholderRows = () => {
		if (items.length > 0 && endIndex <= items.length) return null;

		return (
			<Fragment>
				<tr key="placeholder-0" />
				<tr key="placeholder-1" />
			</Fragment>
		);
	};

	const renderSelectAll = () => {
		if (typeof toggleSelectAll !== 'function') return null;

		return (
			<th className="action-cell">
				<Checkbox
					checked={selectAll}
					size="sm"
					onChange={e => {
						e.stopPropagation();
						toggleSelectAll(!selectAll);
					}}
				/>
			</th>
		);
	};

	const renderHeaders = () => {
		if (!Array.isArray(headers)) return null;

		return headers.map((header, index) => {
			const { value, label, sortable, width } = header;

			if (sortable && typeof changeSort === 'function') {
				return (
					<th
						key={index}
						style={{ width: width ? width : 'initial' }}
					>
						{renderSortableHeader(label, value)}
					</th>
				);
			} else {
				return (
					<th
						key={index}
						style={{ width: width ? width : 'initial' }}
					>
						{label}
					</th>
				);
			}
		});
	};

	return (
		<StyledTable maxCellWidth={getMaxCellWidth()}>
			<table>
				<thead>
					<tr>
						{renderSelectAll()}
						{renderHeaders()}
					</tr>
				</thead>
				<tbody>
					{renderPlaceholderRows()}
					{renderRows()}
				</tbody>
			</table>
			{renderFooter()}
			{renderLoading()}
			{renderDataPlaceholder()}
		</StyledTable>
	);
}

Table.propTypes = {
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
			sortable: PropTypes.bool
		})
	),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.shape({
				isSelected: PropTypes.bool,
				id: PropTypes.string
			}),
			cells: PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.string,
					renderCell: PropTypes.func,
					label: PropTypes.string
				})
			)
		})
	),
	onItemClick: PropTypes.func,
	onItemSelect: PropTypes.func,
	placeholder: PropTypes.string,
	itemCount: PropTypes.number,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	sort: PropTypes.string,
	loading: PropTypes.bool,
	fetching: PropTypes.bool,
	selectAll: PropTypes.bool,
	noWrap: PropTypes.bool,
	toggleSelectAll: PropTypes.func,
	changeSort: PropTypes.func,
	changePageSize: PropTypes.func,
	changePage: PropTypes.func
};

Table.defaultProps = {
	items: [],
	itemCount: 0,
	pageSize: 25,
	page: 0,
	sort: '',
	loading: false,
	fetching: false,
	selectAll: false,
	headers: [],
	toggleSelectAll: null,
	changeSort: null,
	changePageSize: null,
	changePage: null,
	onItemClick: () => null,
	onItemSelect: null,
	noWrap: true,
	placeholder: ''
};

export default Table;
