import styled from 'styled-components';

const Svg = styled.svg.attrs({
	xmlns: 'http://www.w3.org/2000/svg',
	focusable: false,
	viewBox: '0 0 24 24',
	ariaHidden: true,
	role: 'presentation'
})`
	width: 1em;
	height: 1em;
	display: inline-block;
	font-size: 24px;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	user-select: none;
	flex-shrink: 0;
`;

export default Svg;
