import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
	value,
	onChange,
	placeholder,
	label,
	type,
	updated,
	tabIndex,
	boxInput,
	disabled
}) => (
	<div className={'input-wrapper' + (updated ? ' updated' : '')}>
		{label ? <span className="label">{label}</span> : null}
		<input
			className={'input' + (boxInput ? ' box-input' : '')}
			placeholder={placeholder}
			type={type}
			value={value}
			disabled={disabled}
			tabIndex={tabIndex}
			onChange={onChange}
		/>
	</div>
);

Input.defaultProps = {
	value: '',
	placeholder: '',
	label: '',
	type: 'text',
	updated: false,
	boxInput: false,
	disabled: false,
	tabIndex: '0'
};

Input.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	tabIndex: PropTypes.string,
	placeholder: PropTypes.string,
	boxInput: PropTypes.bool,
	updated: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};

export default Input;
