import React from 'react';

import SimpleSvg from './SimpleSvg';

const Analytics = props => (
	<SimpleSvg
		d="M 20 2 A 2 2 0 0 0 18 4 A 2 2 0 0 0 18.230469 4.9316406 L 16.519531 7.0703125 A 2 2 0 0 0 16 7 A 2 2 0 0 0 14.71875 7.4667969 L 11.994141 6.1035156 A 2 2 0 0 0 12 6 A 2 2 0 0 0 10 4 A 2 2 0 0 0 8 6 A 2 2 0 0 0 8.0039062 6.1035156 L 5.28125 7.4648438 A 2 2 0 0 0 4 7 A 2 2 0 0 0 2 9 A 2 2 0 0 0 4 11 A 2 2 0 0 0 6 9 A 2 2 0 0 0 5.9960938 8.8964844 L 8.71875 7.5351562 A 2 2 0 0 0 10 8 A 2 2 0 0 0 11.28125 7.5351562 L 14.005859 8.8964844 A 2 2 0 0 0 14 9 A 2 2 0 0 0 16 11 A 2 2 0 0 0 18 9 A 2 2 0 0 0 17.769531 8.0703125 L 19.480469 5.9316406 A 2 2 0 0 0 20 6 A 2 2 0 0 0 22 4 A 2 2 0 0 0 20 2 z M 20 13 A 2 2 0 0 0 18 15 A 2 2 0 0 0 18.203125 15.875 L 16.496094 18.064453 A 2 2 0 0 0 16 18 A 2 2 0 0 0 15.068359 18.230469 L 12.931641 16.519531 A 2 2 0 0 0 13 16 A 2 2 0 0 0 11 14 A 2 2 0 0 0 9.0214844 15.732422 L 5.5410156 16.726562 A 2 2 0 0 0 4 16 A 2 2 0 0 0 2 18 A 2 2 0 0 0 4 20 A 2 2 0 0 0 5.9785156 18.267578 L 9.4589844 17.273438 A 2 2 0 0 0 11 18 A 2 2 0 0 0 11.929688 17.769531 L 14.068359 19.480469 A 2 2 0 0 0 14 20 A 2 2 0 0 0 16 22 A 2 2 0 0 0 18 20 A 2 2 0 0 0 17.757812 19.046875 L 19.421875 16.914062 A 2 2 0 0 0 20 17 A 2 2 0 0 0 22 15 A 2 2 0 0 0 20 13 z"
		{...props}
		fill="#8DABC4"
	/>
);

export default Analytics;
