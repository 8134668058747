import styled from 'styled-components';

const StyledTabs = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eee;
	box-sizing: border-box;
	height: 40px;
`;

export default StyledTabs;
