import React from 'react';

import SimpleSvg from './SimpleSvg';

const Filter = props => (
	<SimpleSvg
		d="M 4 3 C 3.448 3 3 3.448 3 4 C 3 4.552 3.448 5 4 5 L 20 5 C 20.552 5 21 4.552 21 4 C 21 3.448 20.552 3 20 3 L 4 3 z M 5 7 L 10 13 L 10 20 C 10 20.552 10.448 21 11 21 L 13 21 C 13.552 21 14 20.552 14 20 L 14 13 L 19 7 L 5 7 z"
		{...props}
	/>
);

export default Filter;
