import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Error as ErrorIcon } from 'modules/blink/icons';
import StyledTextarea from './StyledTextarea.jsx';

function Textarea(props) {
	const {
		label,
		error,
		showError: forceShowError,
		help,
		value,
		onChange,
		required,
		validator,
		...other
	} = props;

	const [showError, toggleError] = useState(false);

	useEffect(() => {
		if (value && showError) {
			toggleError(false);
		}
	}, [value, showError]);

	const onBlur = e => {
		if (!error) return;

		toggleError(!validator(value));
	};

	const renderError = () => (
		<div className="textarea-error">
			<ErrorIcon />
			<span>{error}</span>
		</div>
	);

	const renderHelp = () => (
		<div className="textarea-help">
			<span>{help}</span>
		</div>
	);

	return (
		<StyledTextarea error={forceShowError || showError}>
			<span>
				{label}
				<b>{required ? ' *' : ''}</b>
			</span>
			<textarea
				value={value}
				onBlur={onBlur}
				onChange={onChange}
				{...other}
			/>
			{(forceShowError || showError) && renderError()}
			{help && renderHelp()}
		</StyledTextarea>
	);
}

Textarea.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.string,
	showError: PropTypes.bool,
	help: PropTypes.string,
	required: PropTypes.bool,
	validator: PropTypes.func,
	onChange: PropTypes.func.isRequired
};

Textarea.defaultProps = {
	value: '',
	label: '',
	error: '',
	showError: false,
	help: '',
	required: false,
	validator: value => !!value
};

export default Textarea;
