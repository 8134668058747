import React from 'react';
import withCreateLeadContext from '../providers/withCreateLead';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import { Fab, Button, Tooltip } from '@material-ui/core';

export const CreateLeadButton = withCreateLeadContext(
	({ showCreateLead, leadCount, largeButton }) => {
		if (largeButton) {
			return (
				<Button
					color="primary"
					variant="contained"
					style={{ textTransform: 'none', maxHeight: 36 }}
					onClick={showCreateLead}
				>
					{!leadCount && (
						<NewReleasesIcon
							style={{
								marginRight: '0.25rem',
								lineHeight: 'normal'
							}}
						/>
					)}
					Create Lead{' '}
					{!!leadCount && (
						<GroupAddIcon
							style={{
								marginLeft: '0.25rem',
								lineHeight: 'normal'
							}}
						/>
					)}
				</Button>
			);
		}

		return (
			<Tooltip title="Create Lead" aria-label="add-lead">
				<Fab
					color="default"
					size="small"
					variant="extended"
					style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
					onClick={showCreateLead}
				>
					<GroupAddIcon />
				</Fab>
			</Tooltip>
		);
	}
);
