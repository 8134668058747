import React from 'react';

import SimpleSvg from './SimpleSvg';

const Plane = props => (
	<SimpleSvg
		d="M 12 3 A 1 1 0 0 0 11.123047 3.5214844 L 3.1542969 17.464844 L 3.15625 17.466797 A 1 1 0 0 0 3 18 A 1 1 0 0 0 4 19 L 10 19 L 12 8 L 14 19 L 20 19 A 1 1 0 0 0 21 18 A 1 1 0 0 0 20.845703 17.464844 L 12.890625 3.546875 A 1 1 0 0 0 12 3 z M 14 19 L 10 19 L 11.353516 21.605469 C 11.625516 22.128469 12.373531 22.128469 12.644531 21.605469 L 14 19 z"
		{...props}
	/>
);

export default Plane;
