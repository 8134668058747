import React from 'react';

import SimpleSvg from './SimpleSvg';

const ChevronRight = props => (
	<SimpleSvg
		d="M9.29 15.88L13.17 12 9.29 8.12a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z"
		{...props}
	/>
);

export default ChevronRight;
