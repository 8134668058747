import styled, { css } from 'styled-components';

const noWrap = ({ noWrap }) => {
	if (noWrap) {
		return css`
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`;
	}
};

const Text = styled.p`
	${noWrap};
`;

export default Text;
