import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { OkCircle, ChevronRight } from 'modules/blink/icons';

const SetupItem = ({ isCompleted, info, path, history }) => {
	return (
		<li
			className={`setup-item ${isCompleted ? 'completed' : ''}`}
			onClick={() => history.push(path)}
		>
			<div className="checkbox-wrapper">
				<OkCircle className="icon" />
			</div>
			<div className="info-wrapper">
				<span className="info">{info}</span>
			</div>
			<div className="action-wrapper">
				<ChevronRight className="icon" />
			</div>
		</li>
	);
};

SetupItem.propTypes = {
	isCompleted: PropTypes.bool,
	info: PropTypes.string,
	path: PropTypes.string,
	history: PropTypes.object.isRequired
};

SetupItem.defaultProps = {
	isCompleted: false,
	info: '',
	path: ''
};

export default withRouter(SetupItem);
