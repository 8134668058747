import { ApolloClient } from '@apollo/client/core';
import { HttpLink, ApolloLink, concat } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';

import { getUser } from './storage';

const { authJwt } = getUser();

/** APOLLO CLIENT */
const hasSubscriptionOperation = ({ query: { definitions } }) => {
	return definitions.some(
		({ kind, operation }) =>
			kind === 'OperationDefinition' && operation === 'subscription'
	);
};

const webSocketLink = new WebSocketLink({
	uri: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
	options: {
		reconnect: true,
		connectionParams: {
			authJwt
		}
	}
});

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
	const responseHeaders = operation?.getContext()?.response?.headers;
	const attributes = {
		operationName: operation?.operationName,
		contentType: responseHeaders?.get('Content-Type'),
		datalayerVersion: responseHeaders?.get(
			'X-Structurely-Datalayer-Version'
		),
		requestId: responseHeaders?.get('X-Request-ID')
	};

	if (graphQLErrors)
		graphQLErrors.forEach(error => {
			if (error?.code === 'A3001') {
				document.cookie = `structurely=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${
					process.env.REACT_APP_COOKIE_DOMAIN
				};`;
				window.location = `${
					process.env.REACT_APP_ACCOUNTS_URL
				}?r=${encodeURIComponent(window.location.href)}`;
				return;
			}

			if (window.newrelic) {
				window.newrelic.noticeError(error, attributes);
			}
		});

	if (networkError) {
		if (window.newrelic) {
			window.newrelic.noticeError(networkError, attributes);
		}

		if (
			networkError instanceof TypeError &&
			networkError.message === 'Failed to fetch'
		) {
			console.error(`Issue with CORS ${networkError}`);
		}
	}
});

const link = errorLink.concat(
	ApolloLink.split(hasSubscriptionOperation, webSocketLink, httpLink)
);

const authMiddleware = new ApolloLink((operation, forward) => {
	if (authJwt) {
		operation.setContext({
			headers: {
				Authorization: `Bearer ${authJwt}`
			}
		});
	}

	return forward(operation);
});

const possibleTypes = {
	ScriptFlowType: ['DefaultScriptType', 'CustomScriptType'],
	ScriptDripCampaignType: ['DefaultDripCampaignType']
};

const customerRefKeyArgs = {
	keyArgs: args =>
		args !== null
			? Object.keys(args)
					.filter(arg => args[arg] !== undefined && arg !== 'limit')
					.sort()
			: []
};

const customerFieldsPolicies = {
	customers: customerRefKeyArgs,
	totalCustomersCount: customerRefKeyArgs
};

const typePolicies = {
	AgentType: {
		fields: customerFieldsPolicies
	},
	CustomerType: {
		fields: {
			conversation: {
				merge: true
			}
		}
	},
	BrokerageType: {
		fields: customerFieldsPolicies
	},
	TeamType: {
		fields: customerFieldsPolicies
	}
};

const cache = new InMemoryCache({
	addTypename: true,
	possibleTypes,
	typePolicies
});

const client = new ApolloClient({
	link: concat(authMiddleware, link),
	cache
});

export const closeClient = () => {
	client.resetStore();
	webSocketLink.close();
};

export default client;
