import * as colors from '../colors';

export const light = {
	text: {
		primary: '#1A202C',
		secondary: '#718096',
		disabled: '',
		hint: '',
		icon: ''
	},
	divider: '',
	background: {
		paper: '',
		default: ''
	},
	actions: {
		active: '',
		hover: '',
		hoverOpacity: 0.08,
		selected: '',
		disabled: '',
		disabledBackground: ''
	}
};

export const dark = {
	text: {
		primary: '',
		secondary: '',
		disabled: '',
		hint: '',
		icon: ''
	},
	divider: '',
	background: {
		paper: '',
		default: ''
	},
	actions: {
		active: '',
		hover: '',
		hoverOpacity: 0.1,
		selected: '',
		disabled: '',
		disabledBackground: ''
	}
};

function createPalette(palette) {
	const {
		primary = colors.blue,
		secondary = colors.teal,
		danger = colors.red,
		type = 'light',
		contrastThreshold = 3,
		tonalOffset = 0.2,
		...other
	} = palette;

	const types = { dark, light };

	const paletteOutput = {
		common: colors.common,
		type,
		primary,
		secondary,
		danger,
		grey: colors.grey,
		brand: colors.brand,
		contrastThreshold,
		tonalOffset,
		...colors,
		...types[type],
		...other
	};

	return paletteOutput;
}

export default createPalette;
