import React from 'react';

import SimpleSvg from './SimpleSvg';

const EditFile = props => (
	<SimpleSvg
		d="M 6 2 C 4.9 2 4 2.9 4 4 L 4 20 C 4 21.1 4.9 22 6 22 L 17.171875 22 L 13.585938 18.414062 C 13.210938 18.039062 13 17.53 13 17 L 13 15 C 13 13.895 13.895 13 15 13 L 17 13 C 17.53 13 18.039062 13.210937 18.414062 13.585938 L 20 15.171875 L 20 8.828125 C 20 8.298125 19.789062 7.7890625 19.414062 7.4140625 L 14.585938 2.5859375 C 14.210937 2.2109375 13.701875 2 13.171875 2 L 6 2 z M 15.5 15 C 15.224 15 15 15.224 15 15.5 L 15 16.585938 C 15 16.850938 15.104969 17.105969 15.292969 17.292969 L 20.146484 22.146484 L 22.146484 20.146484 L 17.292969 15.292969 C 17.104969 15.104969 16.850938 15 16.585938 15 L 15.5 15 z M 22.853516 20.853516 L 20.853516 22.853516 L 21.585938 23.585938 C 22.137937 24.137937 23.033937 24.137938 23.585938 23.585938 C 24.137938 23.033938 24.137938 22.137938 23.585938 21.585938 L 22.853516 20.853516 z"
		{...props}
	/>
);

export default EditFile;
