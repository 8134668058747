import { gql } from '@apollo/client';

import { FRAGMENT_CUSTOMER_DATA } from './fragments';

const AGENT_CUSTOMER_SUBSCRIPTION = gql`
	subscription onAgentCustomerUpdates($id: ID!) {
		agentCustomerUpdates(id: $id) {
			type
			customer {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

const TEAM_CUSTOMER_SUBSCRIPTION = gql`
	subscription onTeamCustomerUpdates($id: ID!) {
		teamCustomerUpdates(id: $id) {
			type
			agentId
			customer {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

const BROKERAGE_CUSTOMER_SUBSCRIPTION = gql`
	subscription onBrokerageCustomerUpdates($id: ID!) {
		brokerageCustomerUpdates(id: $id) {
			type
			teamId
			agentId
			customer {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

const AGENT_UNREAD_COUNT_SUBSCRIPTION = gql`
	subscription onAgentUnreadCountUpdates($agentId: ID!) {
		agentUnreadCountUpdates(id: $agentId) {
			agentId
			teamId
			customerId
			unreadCount
		}
	}
`;

export {
	AGENT_CUSTOMER_SUBSCRIPTION,
	TEAM_CUSTOMER_SUBSCRIPTION,
	BROKERAGE_CUSTOMER_SUBSCRIPTION,
	AGENT_UNREAD_COUNT_SUBSCRIPTION
};
