import React from 'react';
import PropTypes from 'prop-types';

import Channel from './Channel.jsx';

const EmailChannel = ({ deliveryStatus, ...props }) => {
	let channelBackground = '#0acf83';
	if (deliveryStatus === 'DeliveryFailed') {
		channelBackground = 'red';
	} else if (deliveryStatus === 'DeliveredWithWarnings') {
		channelBackground = 'darkorange';
	}

	return (
		<Channel {...props}>
			<title>Email channel</title>
			<path
				fill={channelBackground}
				d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"
			/>
			<path
				fill="#AAB6B3"
				d="M90.4,176l0,153.4c0,8,6.5,14.5,14.5,14.5h294c8,0,14.5-6.5,14.5-14.5L413,175.7l-160.1,88L90.4,176"
			/>
			<path
				fill="#E5E5E5"
				d="M90.4,189.3l162.5,94.1l160.1-94l0.3,152.2c0,8-6.5,14.5-14.5,14.5h-294c-8,0-14.5-6.5-14.5-14.5L90.4,189.3z"
			/>
			<path
				fill="#fff"
				d="M252.9 283.4l160.1-94 .3 152.2c0 8-6.5 14.5-14.5 14.5L252.9 283.4zM90.4 189.3l162.5 94.1-148.1 72.7c-8 0-14.5-6.5-14.5-14.5L90.4 189.3z"
			/>
			<path
				fill="#fff"
				d="M413.3,180.4c0-8-6.5-14.5-14.5-14.5h-294c-8,0-14.5,6.5-14.5,14.5l162.6,86.9L413.3,180.4z"
			/>
		</Channel>
	);
};

EmailChannel.propTypes = {
	deliveryStatus: PropTypes.string
};

EmailChannel.defaultProps = {
	deliveryStatus: undefined
};

export default EmailChannel;
