import React from 'react';
import PropTypes from 'prop-types';
import ReactIntercom from 'react-intercom';
import { useQuery } from '@apollo/client';

import { withUser } from '../providers';
import {
	QUERY_AGENT_INTERCOM_INFO,
	QUERY_TEAM_INTEGRATIONS,
	QUERY_BROKERAGE_INTEGRATIONS
} from '../queries';

function Intercom(props) {
	const { agentId, teamId, brokerageId, isBroker, isTeamLead } = props;
	const { data: agentData, loading: loadingAgentData } = useQuery(
		QUERY_AGENT_INTERCOM_INFO,
		{
			variables: { agentId },
			skip: !agentId
		}
	);
	const { data: teamData, loading: loadingTeamData } = useQuery(
		QUERY_TEAM_INTEGRATIONS,
		{
			variables: { teamId },
			skip: !teamId
		}
	);
	const { data: brokerageData, loading: loadingBrokerageData } = useQuery(
		QUERY_BROKERAGE_INTEGRATIONS,
		{
			variables: { brokerageId },
			skip: !brokerageId
		}
	);

	const getEntityData = (data, entity) => {
		if (!data || !data[entity]) return {};
		const { id, name, integrations } = data[entity];

		let twilioInstalled;
		let forwardingEmail;
		if (integrations) {
			const { twilio, email } = integrations;

			twilioInstalled = twilio ? twilio.phoneNumber : undefined;
			forwardingEmail = email ? email.forwardingEmail : undefined;
		}

		return {
			id,
			name,
			twilioInstalled,
			forwardingEmail
		};
	};

	const getCompanyData = () => {
		let id;
		let name;
		let twilioInstalled;
		let forwardingEmail;
		let teamName;

		let brokerage = getEntityData(brokerageData, 'brokerage');
		let team = getEntityData(teamData, 'team');

		if (brokerageId && !teamId) {
			id = brokerageId;
			name = brokerage.name;

			if (isBroker) {
				twilioInstalled = brokerage.twilioInstalled;
				forwardingEmail = brokerage.forwardingEmail;
			}
		} else if (teamId && !brokerageId) {
			id = teamId;
			name = team.name;

			if (isTeamLead) {
				twilioInstalled = team.twilioInstalled;
				forwardingEmail = team.forwardingEmail;
			}
		} else if (teamId && brokerageId) {
			id = brokerageId;
			name = brokerage.name;
			teamName = team.name;

			if (isBroker) {
				twilioInstalled = brokerage.twilioInstalled;
				forwardingEmail = brokerage.forwardingEmail;
			}
		}

		return {
			id,
			name,
			twilio_installed: twilioInstalled,
			forwarding_email: forwardingEmail,
			team_name: teamName
		};
	};

	const getUserData = () => {
		if (!agentData || !agentData.agent) return {};
		const {
			name,
			phone,
			email,
			hasHolmesApp,
			allowMailgun,
			isZendesk,
			integrations
		} = agentData.agent;

		let hasTwilio;
		let hasWebchat;
		let forwardingEmail;
		if (integrations) {
			const { twilio, webchat, email } = integrations;

			hasTwilio = twilio && twilio.phoneNumber;
			hasWebchat = webchat && webchat.appId;
			forwardingEmail = email && email.forwardingEmail;
		}

		return {
			user_id: agentId,
			email,
			name,
			phone,
			app_installed: hasHolmesApp,
			twilio_installed: hasTwilio,
			livechat_installed: hasWebchat,
			forwarding_email: forwardingEmail,
			zendesk: isZendesk,
			email_ai_enabled: allowMailgun,
			company: brokerageId || teamId ? getCompanyData() : null
		};
	};

	if (loadingAgentData || loadingTeamData || loadingBrokerageData)
		return null;

	return (
		<ReactIntercom
			appID={process.env.REACT_APP_INTERCOM_APP_ID}
			{...getUserData()}
		/>
	);
}

Intercom.propTypes = {
	agentId: PropTypes.string,
	teamId: PropTypes.string,
	brokerageId: PropTypes.string,
	isBroker: PropTypes.bool,
	isTeamLead: PropTypes.bool
};

Intercom.defaultProps = {
	agentId: '',
	teamId: '',
	brokerageId: '',
	isBroker: false,
	isTeamLead: false
};

export default withUser(Intercom);
