import styled from 'styled-components';

const ListItemText = styled.div`
	line-height: 24px;
	font-size: 16px;
	color: #444;
	flex: 1 1 auto;
	padding-right: 16px;
	min-width: 0;
`;

export default ListItemText;
