import React from 'react';

import { MailgunContext } from '../contexts';

const withMailgun = Component => {
	const MailgunComponent = props => (
		<MailgunContext.Consumer>
			{mailgunContext => {
				if (!mailgunContext) return <Component />;
				const { allowMailgun } = mailgunContext;

				let newProps = {
					...props,
					allowMailgun
				};

				return <Component {...newProps} />;
			}}
		</MailgunContext.Consumer>
	);

	return MailgunComponent;
};

export default withMailgun;
