import React from 'react';

import SimpleSvg from './SimpleSvg';

const Phone = props => (
	<SimpleSvg
		d="M19.22,15.25l-2.52-0.29c-0.61-0.07-1.21,0.14-1.64,0.57l-1.85,1.85c-1.419-0.72-2.706-1.659-3.818-2.772 C8.279,13.496,7.34,12.209,6.62,10.79l1.85-1.85C8.9,8.51,9.11,7.91,9.04,7.3L8.75,4.78C8.63,3.77,7.78,3.01,6.76,3.01H5.03 c-1.13,0-2.07,0.94-2,2.07c0.265,4.269,2.104,8.108,4.943,10.947c2.839,2.839,6.678,4.678,10.947,4.943c1.13,0.07,2.07-0.87,2.07-2 v-1.73C20.99,16.22,20.23,15.37,19.22,15.25z"
		{...props}
	/>
);

export default Phone;
