const cloud = {
	l2: '#F5FAFF',
	l1: '#EEF6FD',
	default: '#ECF5FD',
	d1: '#E6F3FE',
	50: '#FDFEFF',
	100: '#FDFEFF',
	200: '#FBFDFF',
	300: '#F9FCFE',
	400: '#F7FBFE',
	500: '#F4F9FE',
	600: '#F2F8FE',
	700: '#F0F7FD',
	800: '#EEF6FD',
	900: '#ECF5FD'
};

export default cloud;
