import styled, { css } from 'styled-components';

const color = ({ kind, theme: { palette } }) => {
	switch (kind) {
		case 'primary':
			return css`
				color: ${palette.primary.d2};
				border-color: ${palette.primary.d2};
			`;
		case 'secondary':
			return css`
				color: #285e61;
				background-color: #e6fffa;
				border-color: #81e6d9;
			`;
		default:
			return css`
				color: ${palette.grey[900]};
				border-color: ${palette.grey[900]};
			`;
	}
};

const Label = styled.span`
	background-color: inherit;
	border: 1px solid;
	border-radius: 12px;
	padding: 2px 10px;
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	font-size: ${({ theme }) => theme.typography.fontSizes[0]};
	letter-spacing: ${({ theme }) => theme.typography.pxToRem(0.25)};
	text-align: center;
	margin-right: ${({ theme: { spacing } }) => spacing(1)};

	&:last-of-type {
		margin-right: 0;
	}

	${color};
`;

export default Label;
