import styled, { css } from 'styled-components';

const error = ({ error, theme: { palette } }) => {
	if (error) {
		return css`
			& > .select-handler > div.select-current-value,
			& > .select-handler > div.select-current-value:focus,
			& > .select-handler > div.select-current-value:active {
				border-color: ${palette.red[500]};
				box-shadow: 0 0 6px -3px ${palette.red[500]};
			}
		`;
	}
};

const readOnly = ({ readOnly }) => {
	if (readOnly) {
		return css`
			& > .select-handler > div.select-current-value,
			& > .select-handler > div.select-current-value:focus,
			& > .select-handler > div.select-current-value:active {
				cursor: initial;
				border-color: transparent;
				box-shadow: none;
				background-color: #eee;

				& > svg {
					display: none;
				}
			}
		`;
	}
};

const size = ({ size }) => {
	if (size === 'sm') {
		return css`
			min-width: 200px;

			& > span {
				font-size: 14px;
				margin-bottom: 0;
			}

			.input-error {
				font-size: 12px;

				& > svg {
					font-size: 12px;
				}
			}

			.select-current-value {
				height: 30px;

				& > p {
					font-size: 14px;
				}
			}

			.select-handler > .label {
				font-size: 14px;
			}
		`;
	}
};

const isOpen = ({ isOpen, theme: { palette } }) => {
	if (isOpen) {
		return css`
			.select-current-value {
				border-color: ${palette.primary.default};
				box-shadow: 0 0 6px -3px ${palette.primary.default};

				& > svg {
					transform: rotate(180deg);
				}
			}
		`;
	}
};

const StyledSelect = styled.label`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 280px;
	cursor: pointer;
	position: relative;

	.select-handler > .label {
		font-size: 16px;
		margin-bottom: 4px;

		b {
			color: ${({ theme: { palette } }) => palette.red[800]};
		}
	}

	.select-menu {
		width: 100%;
	}

	.select-current-value {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 4px;
		border: 1px solid ${({ theme: { palette } }) => palette.grey[400]};
		color: ${({ theme: { palette } }) => palette.text.primary};
		height: 40px;
		box-sizing: border-box;
		outline: none;
		position: relative;

		& > p {
			font-size: 16px;
			padding: 0 10px;
		}

		& > svg {
			padding: 0 10px;
			transition: all 150ms ease-in-out;
		}

		& + div {
			margin-top: 4px;
		}
	}

	.input-error {
		display: flex;
		align-items: center;
		color: ${({ theme: { palette } }) => palette.red[800]};
		font-size: 14px;
		font-weight: 500;

		& > svg {
			font-size: 16px;
			margin-bottom: 1px;
			fill: ${({ theme: { palette } }) => palette.red[800]};
		}

		& > span {
			margin-left: 4px;
		}
	}

	.input-help {
		font-size: 14px;
		color: ${({ theme: { palette } }) => palette.text.secondary};
	}

	${error};
	${readOnly};
	${size};
	${isOpen};
`;

export default StyledSelect;
