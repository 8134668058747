import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
	DirectChannel,
	FormChannel,
	TextChannel,
	EmailChannel,
	Error
} from 'modules/blink/icons';

function Channels({ channels, phoneStatus }) {
	const channelItems = channels
		.map((channel, index) => {
			let component = null;

			switch (channel) {
				case 'mailgun':
					component = <EmailChannel className="channel-icon" />;
					break;
				case 'web':
					component = <DirectChannel className="channel-icon" />;
					break;
				case 'twilio':
					if (!phoneStatus.isValid) {
						return null;
					}
					component = <TextChannel className="channel-icon" />;
					break;
				case 'api':
					component = <FormChannel className="channel-icon" />;
					break;
				default:
					return null;
			}

			return (
				<li key={index} className="channel-item">
					{component}
				</li>
			);
		})
		.filter(c => c);

	if (!phoneStatus.isValid) {
		channelItems.push(
			<li key={channelItems.length+1} className="channel-item">
				<Error
					className="channel-icon channel-warning"
					title={phoneStatus.description}
					height="18px"
					width="18px"
				/>
			</li>
		);
	}

	return (
		<StyledChannels>
			<ul className="channel-list">{channelItems}</ul>
		</StyledChannels>
	);
}

Channels.propTypes = {
	channels: PropTypes.array.isRequired,
	phoneStatus: PropTypes.shape({
		isValid: PropTypes.bool.isRequired,
		description: PropTypes.string.isRequired
	}).isRequired
};

const StyledChannels = styled.div`
	.channel-list {
		display: flex;

		.channel-item {
			display: inline-flex;
			margin-right: 5px;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.channel-icon {
			height: 18px;
			width: 18px;
		}

		.channel-warning {
			fill: #DE911D;
		}
	}
`;

export default Channels;
