import React from 'react';
import PropTypes from 'prop-types';

import StyledCard from './StyledCard.jsx';

function Card(props) {
	const { title, active, renderFooter, children, ...other } = props;

	const renderHeader = () => (
		<div className="card-header">
			<h4>{title}</h4>
		</div>
	);

	const renderBody = () => <div className="card-body">{children}</div>;

	const renderCardFooter = () => (
		<div className="card-footer">{renderFooter()}</div>
	);

	return (
		<StyledCard active={active} {...other}>
			{title && renderHeader()}
			{children && renderBody()}
			{renderFooter && renderCardFooter()}
		</StyledCard>
	);
}

Card.propTypes = {
	title: PropTypes.string,
	active: PropTypes.bool,
	renderFooter: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	])
};

Card.defaultProps = {
	title: '',
	active: false,
	renderFooter: null,
	children: null
};

export default Card;
