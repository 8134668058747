const brand = {
	d2: '#5B9994',
	d1: '#61A59F',
	main: '#2a354f',
	l1: '#80C6C0',
	l2: '#8ECCC7',
	l3: '#9CD2CE'
};

export default brand;
