import React from 'react';

import SimpleSvg from './SimpleSvg';

const CheckedCheckbox = props => (
	<SimpleSvg
		d="M11.707,15.707C11.512,15.902,11.256,16,11,16s-0.512-0.098-0.707-0.293l-4-4c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0L11,13.586l9.675-9.675C20.318,3.364,19.702,3,19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14 c1.105,0,2-0.895,2-2V6.414L11.707,15.707z"
		{...props}
	/>
);

export default CheckedCheckbox;
