import React from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab.jsx';
import StyledTabs from './StyledTabs.jsx';

function Tabs(props) {
	const { value, onChange, tabs, renderTab: renderCustomTab } = props;

	const renderTab = (tab, index) => {
		if (!tab) return null;

		return (
			<Tab
				key={index}
				active={tab.value === value}
				onClick={() => onChange(tab.value)}
			>
				{renderCustomTab ? renderCustomTab(tab) : tab.label}
			</Tab>
		);
	};

	return <StyledTabs>{tabs && tabs.map(renderTab)}</StyledTabs>;
}

Tabs.propTypes = {
	renderTab: PropTypes.func,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	).isRequired
};

Tabs.defaultProps = {
	renderTab: null
};

export default Tabs;
