import React from 'react';

import SimpleSvg from './SimpleSvg';

const Refresh = props => (
	<SimpleSvg
		d="M 12 3 C 9.5110102 3 7.1735459 4.0349773 5.4980469 5.7910156 L 3.8535156 4.1464844 C 3.6585156 3.9514844 3.3414844 3.9514844 3.1464844 4.1464844 C 3.0494844 4.2444844 3 4.372 3 4.5 L 3 9 A 1 1 0 0 0 4 10 L 8.5 10 C 8.628 10 8.7565156 9.9515156 8.8535156 9.8535156 C 9.0485156 9.6585156 9.0485156 9.3414844 8.8535156 9.1464844 L 6.9140625 7.2070312 C 8.2196399 5.820863 10.049247 5 12 5 C 15.52 5 18.502453 7.6320469 18.939453 11.123047 C 19.002453 11.629047 19.432688 12 19.929688 12 C 19.970688 12 20.012687 11.998187 20.054688 11.992188 C 20.602688 11.924188 20.991828 11.424953 20.923828 10.876953 C 20.362828 6.3869531 16.526 3 12 3 z M 3.9453125 12.007812 C 3.3973125 12.075812 3.0071719 12.575047 3.0761719 13.123047 C 3.6371719 17.613047 7.474 21 12 21 C 14.48642 21 16.807752 19.987199 18.5 18.207031 L 20.146484 19.853516 C 20.341484 20.048516 20.658516 20.048516 20.853516 19.853516 C 20.950516 19.755516 21 19.628 21 19.5 L 21 15 A 1 1 0 0 0 20 14 L 15.5 14 C 15.372 14 15.243484 14.048484 15.146484 14.146484 C 14.951484 14.341484 14.951484 14.658516 15.146484 14.853516 L 17.085938 16.792969 C 15.766846 18.198726 13.949132 19 12 19 C 8.48 19 5.4975469 16.367953 5.0605469 12.876953 C 4.9925469 12.328953 4.4893125 11.936813 3.9453125 12.007812 z"
		{...props}
	/>
);

export default Refresh;
