import React from 'react';

import SimpleSvg from './SimpleSvg';

const Connect = props => (
	<SimpleSvg
		d="M 20.980469 1.9902344 A 1.0001 1.0001 0 0 0 20.292969 2.2929688 L 16.828125 5.7578125 L 15.949219 4.8789062 C 14.816219 3.7459062 12.841031 3.7449063 11.707031 4.8789062 L 10.5 6.0859375 L 9.7070312 5.2929688 A 1.0001 1.0001 0 0 0 8.9902344 4.9902344 A 1.0001 1.0001 0 0 0 8.2929688 6.7070312 L 17.292969 15.707031 A 1.0001 1.0001 0 1 0 18.707031 14.292969 L 17.914062 13.5 L 19.121094 12.292969 C 20.291094 11.122969 20.291094 9.2207812 19.121094 8.0507812 L 18.242188 7.171875 L 21.707031 3.7070312 A 1.0001 1.0001 0 0 0 20.980469 1.9902344 z M 5.9902344 7.9902344 A 1.0001 1.0001 0 0 0 5.2929688 9.7070312 L 6.0859375 10.5 L 4.8789062 11.707031 C 3.7089063 12.877031 3.7089063 14.779219 4.8789062 15.949219 L 5.7578125 16.828125 L 2.2929688 20.292969 A 1.0001 1.0001 0 1 0 3.7070312 21.707031 L 7.171875 18.242188 L 8.0507812 19.121094 C 8.6167812 19.687094 9.369875 20 10.171875 20 C 10.972875 20 11.726969 19.688094 12.292969 19.121094 L 13.5 17.914062 L 14.292969 18.707031 A 1.0001 1.0001 0 1 0 15.707031 17.292969 L 6.7070312 8.2929688 A 1.0001 1.0001 0 0 0 5.9902344 7.9902344 z"
		{...props}
	/>
);

export default Connect;
