import React from 'react';
import PropTypes from 'prop-types';

import Event from './Event.jsx';

function Timeline(props) {
	const { events, renderEvent: renderCustomEvent } = props;

	const renderEvent = (event, index) => {
		if (!event) return null;
		const { kind, renderIcon } = event;

		return (
			<Event key={index} kind={kind} renderIcon={renderIcon}>
				{renderCustomEvent ? renderCustomEvent(event, index) : null}
			</Event>
		);
	};

	return <ul>{events && events.map(renderEvent)}</ul>;
}

Timeline.propTypes = {
	renderEvent: PropTypes.func,
	events: PropTypes.array.isRequired
};

Timeline.defaultProps = {
	renderEvent: null
};

export default Timeline;
