import styled, { css, keyframes } from 'styled-components';

/** KEYFRAMES **/
const pointerFlow = (placement, distance, buffer) => {
	switch (placement) {
		case 'right':
			return keyframes`
				from {
					opacity: 0;
					right: calc(-5px - ${distance} - ${buffer});
				}
		
				to {
					opacity: 1;
				}
			`;
		case 'left':
			return keyframes`
				from {
					opacity: 0;
					left: calc(-19px - ${distance} - ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		case 'top':
			return keyframes`
				from {
					opacity: 0;
					top: calc(-8px - ${distance} - ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		case 'bottom':
			return keyframes`
				from {
					opacity: 0;
					bottom: calc(-13px - ${distance} - ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		default:
			return ``;
	}
};

const contentFlow = (placement, distance, buffer) => {
	switch (placement) {
		case 'right':
			return keyframes`
				from {
					opacity: 0;
					left: calc(100% + 12px + ${distance} + ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		case 'left':
			return keyframes`
				from {
					opacity: 0;
					right: calc(100% + 12px + ${distance} + ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		case 'top':
			return keyframes`
				from {
					opacity: 0;
					top: calc(-40px - ${distance} - ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		case 'bottom':
			return keyframes`
				from {
					opacity: 0;
					bottom: calc(-40px - ${distance} - ${buffer});
				}
			
				to {
					opacity: 1;
				}
			`;
		default:
			return ``;
	}
};

/** MIXINS **/
const tooltipMixin = ({
	placement = 'right',
	horizontalBuffer = '0px',
	verticalBuffer = '0px',
	distance = '20px'
}) => {
	switch (placement) {
		case 'right':
			return css`
				&:before {
					border-left-width: 0;
					border-right-color: #2a354f;
					right: calc(-5px - ${horizontalBuffer});
					top: calc(50% + ${verticalBuffer});
				}

				&:after {
					line-height: 1.2;
					left: calc(100% + 12px + ${horizontalBuffer});
					top: calc(50% - 15px + ${verticalBuffer});
				}

				&:hover:before {
					animation: ${() =>
							pointerFlow('right', distance, horizontalBuffer)}
						300ms ease-out forwards;
				}

				&:hover:after {
					animation: ${() =>
							contentFlow('right', distance, horizontalBuffer)}
						300ms ease-out forwards;
				}
			`;
		case 'left':
			return css`
				&:before {
					border-right-width: 0;
					border-left-color: #2a354f;
					left: calc(-19px - ${horizontalBuffer});
					top: 50%;
				}

				&:after {
					line-height: 1.2;
					right: calc(100% + 12px + ${horizontalBuffer});
					top: calc(50% - 15px);
				}

				&:hover:before {
					animation: ${() =>
							pointerFlow('left', distance, horizontalBuffer)}
						300ms ease-out forwards;
				}

				&:hover:after {
					animation: ${() =>
							contentFlow('left', distance, horizontalBuffer)}
						300ms ease-out forwards;
				}
			`;
		case 'top':
			return css`
				&:before {
					border-bottom-width: 0;
					border-top-color: #2a354f;
					left: calc(50% - 10px);
					top: calc(-8px - ${verticalBuffer});
				}

				&:after {
					height: 14px;
					width: auto;
					white-space: nowrap;
					left: calc(50% - 57px);
					top: calc(-40px - ${verticalBuffer});
				}

				&:hover:before {
					animation: ${() =>
							pointerFlow('top', distance, verticalBuffer)}
						300ms ease-out forwards;
				}

				&:hover:after {
					animation: ${() =>
							contentFlow('top', distance, verticalBuffer)}
						300ms ease-out forwards;
				}
			`;
		case 'bottom':
			return css`
				&:before {
					border-top-width: 0;
					border-bottom-color: #2a354f;
					left: calc(50% - 10px);
					bottom: -14px;
				}

				&:after {
					width: auto;
					min-height: 14px;
					max-width: 150px;
					left: calc(50% - 57px);
					bottom: calc(-40px - ${verticalBuffer});
				}

				&:hover:before {
					animation: ${() => pointerFlow('bottom', distance, '0px')}
						300ms ease-out forwards;
				}

				&:hover:after {
					animation: ${() =>
							contentFlow('bottom', distance, verticalBuffer)}
						300ms ease-out forwards;
				}
			`;
		default:
			return ``;
	}
};

const Tooltip = styled.span`
	position: relative;

	&:before,
	&:after {
		text-transform: none;
		font-size: 14px;
		line-height: 1.2;
		user-select: none;
		pointer-events: none;
		position: absolute;
		display: none;
		opacity: 0;
	}

	&:before {
		content: '';
		border: 5px solid transparent;
		z-index: 1001;
		transform: translate(0.5em, -50%);
	}

	&:after {
		width: 90px;
		min-width: 90px;
		text-align: center;
		content: attr(label);
		padding: 8px 12px;
		border-radius: 3px;
		box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.14);
		background: #2a354f;
		color: #fff;
		z-index: 1000;
	}

	&:hover:before,
	&:hover:after {
		display: block;
		opacity: 1;
	}

	${props => tooltipMixin(props)}
`;

export default Tooltip;
