import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import StyledOption from './StyledOption.jsx';

function Option(props) {
	const { value, label, onSelect, ...other } = props;

	let ref = useRef();

	const handleKeys = e => {
		switch (e.keyCode) {
			case 13:
				if (onSelect) {
					onSelect(value);
				}
				break;
			case 38:
				if (ref && ref.current && ref.current.previousSibling) {
					if (ref.current.previousSibling.tabIndex === 0) {
						ref.current.previousSibling.focus();
					}
				} else if (
					ref &&
					ref.current &&
					ref.current.parentNode &&
					ref.current.parentNode.previousSibling &&
					ref.current.parentNode.previousSibling.firstChild
				) {
					ref.current.parentNode.previousSibling.firstChild.focus();
				}
				break;
			case 40:
				if (ref && ref.current && ref.current.nextSibling) {
					if (ref.current.nextSibling.tabIndex === 0) {
						ref.current.nextSibling.focus();
					}
				}
				break;
			default:
				break;
		}
	};

	return (
		<StyledOption
			ref={ref}
			tabIndex={0}
			onClick={e => {
				e.stopPropagation();
				onSelect(value);
			}}
			onKeyDown={handleKeys}
			value={value}
			{...other}
		>
			{label}
		</StyledOption>
	);
}

Option.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired
};

export default Option;
