import { logicalOperators, comparisonOperators } from '../globals';
import {
	isComparisonOperator,
	isLogicalOperator,
	getFillCondition
} from '../helpers';

class SlotsManager {
	constructor(slots, getSlotValue) {
		this.slots = slots;
		this.getSlotValue = getSlotValue;
	}

	getSlot = slotName => {
		let value;
		let slot = this.slots.find(slot => slot.name === slotName);

		if (this.getSlotValue && typeof this.getSlotValue === 'function') {
			value = this.getSlotValue(slotName);
		}

		if (slot) {
			const {
				name,
				label,
				description,
				isMergeField,
				isQualifier,
				type,
				options,
				promptUnless,
				defaultPrompt,
				...other
			} = slot;

			let answer = '';
			if (Array.isArray(options) && value !== undefined) {
				let option = options.find(option => option.value === value);

				if (option && option.answer) {
					answer = option.answer;
				}
			}

			return {
				name,
				label: label || '',
				description: description || '',
				isMergeField,
				isQualifier,
				type: type || '',
				options: options || [],
				promptUnless: {
					...promptUnless,
					condition: promptUnless
						? JSON.stringify(promptUnless.condition) || ''
						: ''
				},
				defaultPrompt: defaultPrompt || null,
				answer,
				value,
				...other
			};
		}

		return {};
	};

	getSlotNames = () => this.slots.map(slot => slot.name);

	isSlot = slotName => {
		return this.getSlotNames(this.slots).includes(slotName);
	};

	getMergeFields = () =>
		this.slots.filter(slot => slot.isMergeField === true);

	getQualifiers = () => this.slots.filter(slot => slot.isQualifier === true);

	decodeCondition = conditionJSON => {
		if (!conditionJSON) return null;

		let condition = null;
		try {
			condition = JSON.parse(conditionJSON);
		} catch (e) {
			if (typeof conditionJSON === 'object') {
				condition = conditionJSON;
			}
		}

		if (!condition || typeof condition !== 'object') return null;

		const convertCondition = condition => {
			if (!condition || typeof condition !== 'object') return null;

			if (condition.slot && condition.operator) {
				return condition;
			}

			let conditionKeys = Object.keys(condition);

			if (conditionKeys.length > 1) {
				let items = conditionKeys.map(key =>
					convertCondition({ [key]: condition[key] })
				);

				return {
					operator: logicalOperators.AND,
					items
				};
			} else if (conditionKeys.length === 1) {
				let key = conditionKeys[0];

				if (this.isSlot(key)) {
					let slot = key;
					let slotCondition = condition[slot];

					if (
						!slotCondition ||
						typeof slotCondition === 'string' ||
						typeof slotCondition === 'boolean'
					) {
						return {
							slot,
							operator: comparisonOperators.EQ,
							value: slotCondition
						};
					} else if (Array.isArray(slotCondition)) {
						return {
							slot,
							operator: comparisonOperators.IN,
							value: slotCondition
						};
					} else if (typeof slotCondition === 'object') {
						let slotConditionKeys = Object.keys(slotCondition);

						if (
							slotConditionKeys.length === 1 &&
							isComparisonOperator(slotConditionKeys[0])
						) {
							let comparisonOperator = Object.keys(
								slotCondition
							)[0];
							let comparisonValue =
								slotCondition[comparisonOperator];

							return {
								slot,
								operator: comparisonOperator,
								value: comparisonValue
							};
						} else if (slotConditionKeys.length > 1) {
							let conditionItems = slotConditionKeys
								.filter(operator =>
									isComparisonOperator(operator)
								)
								.map(operator => ({
									slot,
									operator,
									value: slotConditionKeys[operator]
								}));

							return {
								operator: logicalOperators.AND,
								items: conditionItems
							};
						}
					}
				} else if (isLogicalOperator(key)) {
					let operator = key;
					let items = condition[operator];

					if (Array.isArray(items)) {
						return {
							operator,
							items: items.map(convertCondition)
						};
					}
				}
			}

			return null;
		};

		return convertCondition(condition);
	};

	encodeCondition = condition => {
		if (!condition) return null;

		const convertCondition = condition => {
			if (!condition) return {};

			if (
				this.isSlot(condition.slot) &&
				isComparisonOperator(condition.operator)
			) {
				return {
					[condition.slot]: { [condition.operator]: condition.value }
				};
			} else if (
				isLogicalOperator(condition.operator) &&
				Array.isArray(condition.items) &&
				condition.items.length > 0
			) {
				let items = condition.items.filter(item => !!item);

				if (items.length === 0) return {};

				items = items.map(convertCondition).filter(item => !!item);

				return {
					[condition.operator]: items
				};
			} else {
				return {};
			}
		};

		return JSON.stringify(convertCondition(condition));
	};

	isConditionMet = conditionJSON => {
		let condition = this.decodeCondition(conditionJSON);

		if (!condition) return false;

		const isConditionMet = condition => {
			if (!condition) return false;
			const { operator, items, slot, value } = condition;

			if (isLogicalOperator(operator) && Array.isArray(items)) {
				if (operator === logicalOperators.AND) {
					for (let item of items) {
						if (!isConditionMet(item)) {
							return false;
						}
					}

					return true;
				} else if (operator === logicalOperators.OR) {
					for (let item of items) {
						if (isConditionMet(item)) {
							return true;
						}
					}

					return false;
				} else {
					return false;
				}
			} else if (isComparisonOperator(operator)) {
				let slotValue = this.getSlotValue(slot);
				const {
					EQ,
					NE,
					GT,
					GTE,
					LT,
					LTE,
					IN,
					NIN
				} = comparisonOperators;

				switch (operator) {
					case EQ:
						return slotValue === value;
					case NE:
						return slotValue !== value;
					case GT:
						return slotValue > value;
					case GTE:
						return slotValue >= value;
					case LT:
						return slotValue < value;
					case LTE:
						return slotValue <= value;
					case IN:
						if (value.length === 0) {
							return Boolean(slotValue);
						} else {
							return value.includes(slotValue);
						}
					case NIN:
						return !value.includes(slotValue);
					default:
						return false;
				}
			}
		};

		return isConditionMet(condition);
	};

	getConditions = (items = [], slotName) => {
		let initialConditions = [];

		if (slotName) initialConditions.push(slotName);

		const getConditionKeys = condition => {
			if (!condition) return [];
			const { operator, items, slot } = condition;

			if (isLogicalOperator(operator) && Array.isArray(items)) {
				return items.map(item => getConditionKeys(item));
			} else if (slot && !slot.startsWith('_')) {
				return [slot];
			} else {
				return [];
			}
		};

		let conditions = [];
		if (Array.isArray(items)) {
			conditions = items.reduce((slotNames, item) => {
				const { condition, filledFromIntent } = item;

				let itemCondition = null;
				if (!condition && filledFromIntent) {
					itemCondition = getFillCondition(
						slotName,
						filledFromIntent
					);
				} else {
					itemCondition = this.decodeCondition(condition);
				}

				if (itemCondition) {
					let keys = getConditionKeys(itemCondition).flat(Infinity);

					return [...slotNames, ...keys];
				} else {
					return slotNames;
				}
			}, initialConditions);
		}

		return [...new Set(conditions)];
	};

	getTemplates = (items, slotName) => {
		if (!items) return [];
		let activeItem = null;

		if (this.getSlotValue && typeof this.getSlotValue === 'function') {
			activeItem = items.find(item => {
				const { filledFromIntent, condition } = item;

				if (!condition && filledFromIntent) {
					let fillCondition = getFillCondition(
						slotName,
						filledFromIntent
					);

					return this.isConditionMet(fillCondition);
				} else {
					return condition && this.isConditionMet(condition);
				}
			});
		}

		if (!activeItem) {
			activeItem = items.find(item => !item.condition);
		}

		if (!activeItem) return null;
		const { templates } = activeItem;

		return templates || [];
	};

	getQuestion = (prompts, slotName) => {
		const { answer } = this.getSlot(slotName, this.getSlotValue(slotName));

		if (slotName) {
			return {
				conditions: this.getConditions(prompts),
				templates: this.getTemplates(prompts),
				answer
			};
		} else {
			return {
				conditions: this.getConditions(prompts),
				templates: this.getTemplates(prompts)
			};
		}
	};

	getAnswer = (answers, slotName) => {
		if (!slotName) return null;

		return {
			conditions: this.getConditions(answers, slotName),
			templates: this.getTemplates(answers, slotName)
		};
	};

	getResponse = (responses, slotName) => {
		return {
			conditions: this.getConditions(responses, slotName),
			templates: this.getTemplates(responses, slotName)
		};
	};

	getPrePrompt = (prePrompts, slotName) => {
		return {
			conditions: this.getConditions(prePrompts, slotName),
			templates: this.getTemplates(prePrompts, slotName)
		};
	};

	getSubject = subjects => {
		return {
			conditions: this.getConditions(subjects),
			templates: this.getTemplates(subjects)
		};
	};
}

export default SlotsManager;
