import { createContext } from 'react';

const ApolloContext = createContext(null);

const UserContext = createContext({
	user: {
		agentId: '',
		teamId: '',
		brokerageId: '',
		isTeamLead: false,
		isBroker: false,
		isIndependentAgent: false
	},
	updateUser: () => null
});

const ActingUserContext = createContext({
	actingUser: null,
	updateActingUser: () => null
});

const FiltersContext = createContext({
	filters: {
		stageFilters: [],
		sourceFilters: [],
		typeFilters: [],
		callDispositionFilters: [],
		agentFilter: null,
		teamFilter: null
	},
	filterActions: {
		clearFilters: () => null,
		updateStageFilters: () => null,
		updateTypeFilters: () => null,
		updateSourceFilters: () => null,
		updateAgentFilter: () => null,
		updateTeamFilter: () => null,
		updateCallDispositionFilters: () => null
	}
});

const RoleContext = createContext({
	role: '',
	updateRole: () => null
});

const LeadsFilterContext = createContext({
	assignedAgentId: null,
	before: null,
	after: null,
	filterName: '',
	filterCondition: '',
	showFilterDropdown: false,
	changeFilterCondition: () => null,
	toggleFilterDropdown: () => null,
	updateBefore: () => null,
	updateAfter: () => null,
	updateAssignedAgentId: () => null
});

const SlotsContext = createContext({
	slots: [],
	getSlotNames: () => null,
	getMergeFields: () => null,
	getQualifiers: () => null,
	isSlot: () => null,
	getSlot: () => null,
	decodeCondition: () => null,
	encodeCondition: () => null,
	isConditionMet: () => null,
	getConditions: () => null,
	getTemplates: () => null,
	getQuestion: () => null,
	getAnswer: () => null,
	getResponse: () => null,
	getPrePrompts: () => null
});

export const FeatureFlagContext = createContext({
	allowCalendlyScheduling: false,
	allowCustomScripts: false,
	allowHardAppointmentScheduling: false,
	allowIntercom: false,
	allowMailgun: false,
	allowMultiConversations: false,
	allowMultiSmsChannels: false,
	allowPlatformDialer: false,
	allowPlatformDialerExperimentalSettings: false,
	allowVoiceChannels: false,
	allowMessagingChannels: true,
	allowAiVoiceAgents: false,
	multiConversationsCanBeActivated: false,
	isStructurelyAdmin: false,
	chatbotDomains: undefined,
	allUseCases: undefined,
	builtinTags: undefined,
	customScripts: undefined
});

const MailgunContext = createContext({
	allowMailgun: false
});

const MultiConversationsContext = createContext({
	allowMultiConversations: false
});

export const SchedulingContext = createContext({
	allowHardAppointmentScheduling: false
});

export const VoiceChannelsContext = createContext({
	allowVoiceChannels: false,
	allowAiVoiceAgents: false,
	allowPlatformDialer: false,
	allowPlatformDialerExperimentalSettings: false,
	callDispositions: undefined,
	twilioVoiceGrant: undefined,
	digitalVoiceAssistants: undefined,
	voiceProviders: undefined,
	defaultVoiceProvider: undefined,
	dialerStatus: undefined,
	setDialerStatus: () => {}
});

export const CreateLeadContext = createContext({
	createLead: false,
	uploadLeads: false,
	showCreateLead: () => {},
	hideCreateLead: () => {},
	showUploadLeads: () => {},
	hideUploadLeads: () => {}
});

export {
	UserContext,
	ActingUserContext,
	FiltersContext,
	LeadsFilterContext,
	RoleContext,
	ApolloContext,
	SlotsContext,
	MailgunContext,
	MultiConversationsContext
};
