import styled, { css } from 'styled-components';

const value = ({ selected }) => {
	if (selected) {
		return css`
			background-color: #ecf5fd;
			font-weight: 500;
		`;
	}
};

const StyledOption = styled.li`
	display: flex;
	align-items: center;
	padding: 0 10px;
	outline: none;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme: { palette } }) => palette.cloud.default};
	}

	&:focus {
		background-color: ${({ theme: { palette } }) => palette.cloud.default};
		font-weight: 500;
	}

	${value};
`;

export default StyledOption;
