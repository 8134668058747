import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ChevronRight } from 'modules/blink/icons';
import { Progress } from 'modules/core/components';
import { withFeatureFlags } from 'modules/core/providers';

const LeadProgress = ({
	leadCount,
	leadLimit,
	billingInterval,
	allowMessagingChannels
}) => (
	<>
		{allowMessagingChannels && (
			<div className="lead-progress">
				<div className="progress-header">
					<div className="header-info">
						<h1 className="title">Your Leads</h1>
						{!!leadLimit && (
							<h4 className="info">
								{`${leadCount} / ${leadLimit} Leads Engaged this ${billingInterval.toLowerCase()}`}
							</h4>
						)}
						{!leadLimit && (
							<h4 className="info">
								{`${leadCount} Leads Engaged this ${billingInterval.toLowerCase()}`}
							</h4>
						)}
					</div>
				</div>
				<div className="progress-container">
					{!!leadLimit && (
						<Progress
							height="12px"
							max={leadLimit}
							value={leadCount}
						/>
					)}
					{!leadLimit && (
						<Progress
							height="12px"
							max={leadCount}
							value={leadCount}
						/>
					)}
				</div>
				<div className="progress-actions">
					<Link to="/settings/billing" className="link-btn">
						<span className="label">Manage Your Plan</span>
						<ChevronRight
							color="#2a354f"
							width="16px"
							height="16px"
						/>
					</Link>
				</div>
			</div>
		)}
	</>
);

LeadProgress.propTypes = {
	leadCount: PropTypes.number,
	leadLimit: PropTypes.number,
	billingInterval: PropTypes.string,
	allowMessagingChannels: PropTypes.bool.isRequired
};

LeadProgress.defaultProps = {
	leadCount: 0,
	leadLimit: 0,
	billingInterval: 'month'
};

export default withFeatureFlags(LeadProgress);
