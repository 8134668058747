import styled from 'styled-components';

const StyledAutoComplete = styled.div`
	min-width: 200px;
	height: auto;
	top: 100%;
	left: 0;
	background-color: #fff;
	box-shadow: ${({ theme: { shadows } }) => shadows[2]};
	border-radius: 4px;
	overflow: hidden;

	.select-options {
		display: flex;
		flex-direction: column;
		max-height: 180px;
		overflow-y: auto;
	}

	.select-placeholder {
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 8px;
		font-size: 16px;
	}

	.select-loading {
		height: 40px;
	}

	.select-input {
		padding: 4px;

		input {
			border-radius: 4px;
			border: 1px solid ${({ theme: { palette } }) => palette.grey[400]};
			height: 30px;
			padding: 0 10px;
			font-size: 14px;
			box-sizing: border-box;
			font-family: inherit;
			width: 100%;

			&:active,
			&:focus {
				border-color: ${({ theme: { palette } }) =>
					palette.primary.default};
				box-shadow: 0 0 6px -3px ${({ theme: { palette } }) => palette.primary.default};
			}
		}
	}
`;

export default StyledAutoComplete;
