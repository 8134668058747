import React from 'react';

import SimpleSvg from './SimpleSvg';

const Appointment = props => (
	<SimpleSvg
		d="M 6.984375 1.9863281 A 1.0001 1.0001 0 0 0 6 3 L 6 4 L 5 4 C 3.9069372 4 3 4.9069372 3 6 L 3 20 C 3 21.093063 3.9069372 22 5 22 L 19 22 C 20.093063 22 21 21.093063 21 20 L 21 6 C 21 4.9069372 20.093063 4 19 4 L 18 4 L 18 3 A 1.0001 1.0001 0 0 0 16.984375 1.9863281 A 1.0001 1.0001 0 0 0 16 3 L 16 4 L 8 4 L 8 3 A 1.0001 1.0001 0 0 0 6.984375 1.9863281 z M 5 9 L 19 9 L 19 20 L 5 20 L 5 9 z M 13 13 C 12.448 13 12 13.448 12 14 L 12 17 C 12 17.552 12.448 18 13 18 L 16 18 C 16.552 18 17 17.552 17 17 L 17 14 C 17 13.448 16.552 13 16 13 L 13 13 z"
		{...props}
	/>
);

export default Appointment;
