import styled, { css } from 'styled-components';

const active = ({ active, theme: { palette } }) => {
	if (active) {
		return css`
			border-color: ${palette.primary.default};
		`;
	}
};

const StyledCard = styled.div`
	border-radius: ${({ theme: { shape } }) => shape.borderRadius};
	background-color: #fff;
	box-shadow: ${({ theme: { shadows } }) => shadows[1]};
	transition: ${({ theme: { transitions } }) =>
		transitions.create('all', {
			duration: transitions.duration.shorter
		})};
	min-width: 256px;
	min-height: 168px;
	cursor: pointer;
	box-sizing: border-box;
	border-top: 3px solid;
	border-color: transparent;

	&:hover {
		box-shadow: ${({ theme: { shadows } }) => shadows[2]};
		background-color: ${({ theme: { palette } }) => palette.grey[50]};
	}

	.card-header {
		display: flex;
		align-items: center;
		padding: 8px 24px 0;
		padding-top: 8px;
		min-height: 48px;
	}

	.card-body {
		display: flex;
		align-items: center;
		min-height: 56px;
		padding: 8px 24px 0;
		min-height: 48px;
	}

	.card-footer {
		display: flex;
		align-items: center;
		min-height: 56px;
		padding: 0 24px;
		border-top: 1px solid;
		border-color: ${({ theme: { palette } }) => palette.grey[200]};
		box-sizing: border-box;

		> div {
			flex-grow: 1;
		}
	}

	${active};
`;

export default StyledCard;
