import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

class List extends PureComponent {
	static propTypes = {
		maxHeight: PropTypes.string,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]).isRequired
	};

	static defaultProps = {
		maxHeight: ''
	};

	render() {
		const { children, maxHeight } = this.props;

		return (
			<StyledList maxHeight={maxHeight} {...this.props}>
				{children}
			</StyledList>
		);
	}
}

const StyledList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	max-height: ${props => props.maxHeight || 'auto'};
	overflow-y: auto;
`;

export default List;
