import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import StyledRadio from './StyledRadio.jsx';

function Radio(props) {
	const {
		name,
		size,
		label,
		value,
		selectedOption,
		readOnly,
		children,
		tabIndex,
		onChange,
		onSubmit,
		autoFocus,
		...other
	} = props;

	let radioRef = useRef();

	useEffect(() => {
		if (autoFocus && radioRef && radioRef.current) {
			radioRef.current.focus();
		}
		// eslint-disable-next-line
	}, []);

	let checked = selectedOption === value;

	const handleKeys = e => {
		switch (e.keyCode) {
			case 13:
				if (onSubmit) {
					onSubmit(value);
				}
				break;
			case 38:
				if (
					radioRef &&
					radioRef.current &&
					radioRef.current.previousSibling
				) {
					if (radioRef.current.previousSibling.tabIndex === 0) {
						radioRef.current.previousSibling.focus();
					}
				}
				break;
			case 40:
				if (
					radioRef &&
					radioRef.current &&
					radioRef.current.nextSibling
				) {
					if (radioRef.current.nextSibling.tabIndex === 0) {
						radioRef.current.nextSibling.focus();
					}
				}
				break;
			default:
				break;
		}
	};

	const renderContent = () => {
		return <div className="radio-content">{children}</div>;
	};

	return (
		<StyledRadio
			size={size}
			checked={checked}
			readOnly={readOnly}
			tabIndex={tabIndex}
			ref={radioRef}
			onFocus={e => {
				e.preventDefault();
				onChange(value);
			}}
			onKeyDown={handleKeys}
		>
			<input
				type="radio"
				name={name}
				value={value}
				checked={checked}
				tabIndex={-1}
				onChange={e => {
					e.preventDefault();
					onChange(value);
				}}
				{...other}
			/>
			<span className="radio-label">{label}</span>
			{children && renderContent()}
			<span className="radio-checkmark" />
		</StyledRadio>
	);
}

Radio.propTypes = {
	value: PropTypes.string,
	size: PropTypes.string,
	selectedOption: PropTypes.string,
	readOnly: PropTypes.bool,
	autoFocus: PropTypes.bool,
	name: PropTypes.string,
	tabIndex: PropTypes.number,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]),
	label: PropTypes.string.isRequired
};

Radio.defaultProps = {
	value: '',
	size: '',
	selectedOption: null,
	readOnly: false,
	autoFocus: false,
	tabIndex: 0,
	name: 'radio-group',
	children: null,
	onSubmit: null,
	onChange: () => null
};

export default Radio;
