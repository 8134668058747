import React from 'react';
import PropTypes from 'prop-types';

import StyledSpinner from './StyledSpinner.jsx';

function Spinner(props) {
	const { size, kind, ghost, tint, ...other } = props;

	return (
		<StyledSpinner
			size={size}
			kind={kind}
			ghost={ghost}
			tint={tint}
			{...other}
		>
			<span />
		</StyledSpinner>
	);
}

Spinner.propTypes = {
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'default']),
	kind: PropTypes.oneOf(['primary', 'secondary', 'danger', 'default']),
	ghost: PropTypes.bool,
	tint: PropTypes.bool
};

Spinner.defaultProps = {
	size: 'default',
	kind: 'default',
	ghost: false,
	tint: false
};

export default Spinner;
