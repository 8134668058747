import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const withCustomer = Component => {
	const CustomerComponent = props => {
		const { match } = props;

		let customerId = match && match.params ? match.params.customerId : '';
		let newProps = {
			...props,
			customerId
		};

		return <Component {...newProps} />;
	};

	CustomerComponent.propTypes = {
		match: PropTypes.object.isRequired
	};

	return withRouter(CustomerComponent);
};

export default withCustomer;
