import React from 'react';

import SimpleSvg from './SimpleSvg';

const MoneyStack = props => (
	<SimpleSvg
		d="M 3 3 C 2.448 3 2 3.448 2 4 L 2 14 C 2 14.552 2.448 15 3 15 L 21 15 C 21.552 15 22 14.552 22 14 L 22 4 C 22 3.448 21.552 3 21 3 L 3 3 z M 4.9121094 5 L 19.087891 5 A 1.5 1.5 0 0 0 20 5.9121094 L 20 12.087891 A 1.5 1.5 0 0 0 19.087891 13 L 4.9121094 13 A 1.5 1.5 0 0 0 4 12.087891 L 4 5.9121094 A 1.5 1.5 0 0 0 4.9121094 5 z M 12 6 A 3 3 0 0 0 9 9 A 3 3 0 0 0 12 12 A 3 3 0 0 0 15 9 A 3 3 0 0 0 12 6 z M 7 8 C 6.448 8 6 8.447 6 9 C 6 9.553 6.448 10 7 10 C 7.552 10 8 9.553 8 9 C 8 8.447 7.552 8 7 8 z M 17 8 C 16.448 8 16 8.447 16 9 C 16 9.553 16.448 10 17 10 C 17.552 10 18 9.553 18 9 C 18 8.447 17.552 8 17 8 z M 3 16 A 1.0001 1.0001 0 1 0 3 18 L 21 18 A 1.0001 1.0001 0 1 0 21 16 L 3 16 z M 3 19 A 1.0001 1.0001 0 1 0 3 21 L 21 21 A 1.0001 1.0001 0 1 0 21 19 L 3 19 z"
		{...props}
	/>
);

export default MoneyStack;
