import styled from 'styled-components';

const StyledToggleWrapper = styled.div`
	display: flex;
	align-items: center;

	& > .toggle-label {
		margin-left: 8px;
		font-size: 14px;
	}
`;

export default StyledToggleWrapper;
