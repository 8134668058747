import React from 'react';

import SimpleSvg from './SimpleSvg';

const Message = props => (
	<SimpleSvg
		d="M20,3H4C2.9,3,2.01,3.9,2.01,5L2.001,20.584c0,0.891,1.077,1.338,1.707,0.708L6,19h14c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M9,11c0,0.552-0.448,1-1,1s-1-0.448-1-1s0.448-1,1-1S9,10.448,9,11z M13,11c0,0.552-0.448,1-1,1s-1-0.448-1-1 s0.448-1,1-1S13,10.448,13,11z M17,11c0,0.552-0.448,1-1,1c-0.552,0-1-0.448-1-1s0.448-1,1-1C16.552,10,17,10.448,17,11z"
		{...props}
	/>
);

export default Message;
